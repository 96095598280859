
import Types from "./types";
import { testDriveService, slotPlannerService, CheckoutService, ListingServiceAUV2, B2cFinanceService } from "../../../service";

const setMaxStep = (maxStep) => ({type: Types.SET_MAX_STEP, maxStep});
const setCurrentStep = (currentStep) => ({type: Types.SET_CURRENT_STEP, currentStep});
const setSelectedSlot = (selectedSlotData) => ({type: Types.SET_SELECTED_SLOT, selectedSlotData});
const setHardRejection = (rejectionReasonInfo) => ({type: Types.SET_HARD_REJECTION, rejectionReasonInfo});
const setPersonalDetails = (personalDetails) => ({type: Types.SET_FORM_DATA, personalDetails});
const setRecheduleflag = (rescheduleFlag) => ({type: Types.SET_RECHEDULE_FLAG, rescheduleFlag});

const fetchSlotsRequest = () => ({
    type: Types.FETCH_SLOTS_REQUEST
});

const fetchSlotsSuccess = (slotsResponse) => ({
    type: Types.FETCH_SLOTS_SUCCESS,
    slotsResponse
});

const fetchSlotsFailure = () => ({
    type: Types.FETCH_SLOTS_FAILURE
});

const fetchSlots = (token, params = {}) => (dispatch) => {
    dispatch(fetchSlotsRequest());
    return new Promise((resolve, reject) => {
        slotPlannerService.fetchTestDriveSlots(token, params)
            .then(response => {
                dispatch(fetchSlotsSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(fetchSlotsFailure(error));
                reject(error);
            });
    });
};

const bookSlotRequest = () => ({
    type: Types.BOOK_SLOT_REQUEST
});

const bookSlotSuccess = (orderDetails) => ({
    type: Types.BOOK_SLOT_SUCCESS,
    orderDetails
});

const bookSlotFailure = (error) => ({
    type: Types.BOOK_SLOT_FAILURE,
    error
});

const setTestDriveVariantSuccess = (bookingSlots) => ({
    type: Types.SET_TEST_DRIVE_VARIANT_SUCCESS,
    bookingSlots
});

const setTestDriveVariantFailure = (error) => ({
    type: Types.SET_TEST_DRIVE_VARIANT_FAILURE,
    error
});

const getTestDriveVariantInit = () => ({
    type: Types.GET_TEST_DRIVE_VARIANT_INIT
});

const getTestDriveVariantSuccess = (data) => ({
    type: Types.GET_TEST_DRIVE_VARIANT_SUCCESS,
    data
});

const getTestDriveVariantFailure = (error) => ({
    type: Types.GET_TEST_DRIVE_VARIANT_FAILURE,
    error
});

const bookSlot = (token, orderId, reqObj) => (dispatch, getState) => {
    dispatch(bookSlotRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        testDriveService.bookTestDriveSlot(token || secureToken, orderId, reqObj)
            .then(response => {
                const data = response.data;
                dispatch(bookSlotSuccess(data));
                resolve(data);
            })
            .catch(error => {
                dispatch(bookSlotFailure(error));
                reject(error);
            });
    });
};

const cancelSlotInit = () => ({
    type: Types.CANCEL_SLOT_INIT
});

const cancelSlotSuccess = (data) => ({
    type: Types.CANCEL_SLOT_SUCCESS,
    data
});

const cancelSlotFailure = (error) => ({
    type: Types.CANCEL_SLOT_FAILURE,
    error
});
const cancelSlot = (orderId, payload) => (dispatch, getState) => {
    dispatch(cancelSlotInit());
    const {
        user: { secureToken }
    } = getState();
    return new Promise((resolve, reject) => {
        testDriveService.cancelTestDriveSlot(secureToken, orderId, payload)
            .then(response => {
                dispatch(cancelSlotSuccess(response.data));
                resolve(response);
            })
            .catch(error => {
                reject(error);
                dispatch(cancelSlotFailure(error));
            });
    });
};

const getOrderDetailInit = () => ({
    type: Types.GET_ORDER_DETAIL_INIT
});

const getOrderDetailSuccess = (orderDetails) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    orderDetails
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, params) => (dispatch, getState) => {
    dispatch(getOrderDetailInit());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, secureToken, params)
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
    });
};

const getOrderDetailByUserId = (appointmentId, params) => (dispatch) => {
    dispatch(getOrderDetailInit());
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummaryByUserId(appointmentId, params)
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data.order));
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
    });
};

const updateFinanceDetail = (token, orderId, reqObj) => () => {
    return new Promise((resolve, reject) => {
        testDriveService.updateFinanceOpted(token, orderId, reqObj)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const resetCancelReschedulePopupState = () => ({
    type: Types.RESET_CANCEL_RESCHEDULE_POPUP_STATE
});

const slotReschedule = () => ({
    type: Types.UPDATE_SLOT_RESCHEDULE
});

const setTestDriveVariant = (payload) => (dispatch, getState) => {
    const {
        user: { secureToken}
    } = getState();

    return new Promise((resolve, reject) => {
        ListingServiceAUV2.setTestDriveVariant(payload, secureToken)
            .then(response => {
                if (response.data) {
                    const data = response.data;
                    dispatch(setTestDriveVariantSuccess(data));
                    resolve(data);
                }
            })
            .catch(error => {
                dispatch(setTestDriveVariantFailure(error));
                reject(error);
            });
    });
};

const getTestDriveVariant = (email) => (dispatch, getState) => {
    dispatch(getTestDriveVariantInit());
    const {
        user: { secureToken}
    } = getState();

    return new Promise((resolve, reject) => {
        ListingServiceAUV2.getTestDriveVariant(`email=${email}`, secureToken)
            .then(response => {
                if (response.data) {
                    const data = response.data;
                    dispatch(getTestDriveVariantSuccess(data));
                    resolve(data);
                }
            })
            .catch(error => {
                dispatch(getTestDriveVariantFailure(error));
                reject(error);
            });
    });
};

// const getLoanCalculatorDetailsInit = () => ({
//     type: Types.GET_LOAN_CALCULATOR_DETAILS_INIT
// });

// const getLoanCalculatorDetailsSuccess = (data) => ({
//     type: Types.GET_LOAN_CALCULATOR_DETAILS_SUCCESS,
//     data
// });

// const getLoanCalculatorDetailsFailure = (error) => ({
//     type: Types.GET_LOAN_CALCULATOR_DETAILS_FAILURE,
//     error
// });

const getLoanCalculatorDetails = (orderId) => (dispatch, getState) => {
    // dispatch(getLoanCalculatorDetailsInit());

    const {
        user: { secureToken}
    } = getState();

    return new Promise((resolve, reject) => {
        B2cFinanceService.getTDLoanCalculatorDetails({orderId, token: secureToken})
            .then(response => {
                if (response.data) {
                    const data = response.data;
                    // dispatch(getLoanCalculatorDetailsSuccess(data));
                    resolve(data);
                }
            })
            .catch(error => {
                // dispatch(getLoanCalculatorDetailsFailure(error));
                reject(error);
            });
    });
};

export {
    setMaxStep,
    setCurrentStep,
    fetchSlots,
    setSelectedSlot,
    bookSlot,
    cancelSlot,
    getOrderDetail,
    setHardRejection,
    setPersonalDetails,
    setRecheduleflag,
    updateFinanceDetail,
    resetCancelReschedulePopupState,
    setTestDriveVariant,
    getTestDriveVariant,
    getOrderDetailSuccess,
    slotReschedule,
    getOrderDetailByUserId,
    getLoanCalculatorDetails
};
