export default {
    SIGNUP: "Signup_completed",
    ADD_WISHLIST: "Added_to_wishlist",
    REMOVE_WISHLIST: "Removed_from_wishlist",
    SALE_CONFIRM: "Sale_confirmed",
    SALE_PROCESS: "Sale_under_process",
    LEAD_CREATED: "Lead_created",
    CAR_DETAIL_PAGE_VIEWED: "car detail page viewed",
    WISHLIST_DONE: "wishlist done",
    BOOKING_INITIATED: "booking initiated",
    PRE_APPROVAL_FLOW_INITATED: "pre approval flow initated",
    TRADE_IN_INITIATED: "trade in initiated",
    BOOKING_CONFIRMED: "booking confirmed",
    NOTIFY_ME: "notify me",
    WHATSAPP_CONSENT_SELECTED: "whatsapp consent selected",
    PRE_APPROVAL_FLOW: "Pre_approval_flow",
    PRE_APPROVAL_RESULT: "Pre_approval_result",
    UNSUBSCRIBE_PAGE_SEEN: "unsubscribe page seen",
    UNSUBSCRIBE_BUTTON_CLICKED: "unsubscribe button clicked",
    CT_SDK_LOADED: "CT sdk loaded",
    CT_RESPONSE_RECEIVED: "CT response received",
    C2B_VALUATION_SHOWN_PRE_BI: "c2b_valuation_shown_pre_bi",
    C2B_VALUATION_ACCEPTED_PRE_BI: "c2b_valuation_accepted_pre_bi",
    C2B_INSPECTION_BOOKED: "c2b_inspection_booked",
    C2B_EMAIL_ENTERED: "c2b_email_entered",
    C2B_PRICE_UPDATE_ADOPTION: "c2b_price_update_adoption"
};

export const CLEVERTAP_EVENT_MAP = {
    CAR_DETAIL_PAGE_VIEWED: "CAR_DETAIL_PAGE_VIEWED",
    WISHLIST_DONE: "WISHLIST_DONE",
    BOOKING_INITIATED: "BOOKING_INITIATED",
    PRE_APPROVAL_FLOW_INITATED: "PRE_APPROVAL_FLOW_INITATED",
    TRADE_IN_INITIATED: "TRADE_IN_INITIATED",
    BOOKING_CONFIRMED: "BOOKING_CONFIRMED",
    NOTIFY_ME: "NOTIFY_ME",
    WHATSAPP_CONSENT_SELECTED: "WHATSAPP_CONSENT_SELECTED",
    PRE_APPROVAL_FLOW: "PRE_APPROVAL_FLOW",
    PRE_APPROVAL_RESULT: "PRE_APPROVAL_RESULT",
    UNSUBSCRIBE_PAGE_SEEN: "UNSUBSCRIBE_PAGE_SEEN",
    UNSUBSCRIBE_BUTTON_CLICKED: "UNSUBSCRIBE_BUTTON_CLICKED",
    CT_SDK_LOADED: "CT_SDK_LOADED",
    CT_RESPONSE_RECEIVED: "CT_RESPONSE_RECEIVED",
    C2B_VALUATION_SHOWN_PRE_BI: "C2B_VALUATION_SHOWN_PRE_BI",
    C2B_VALUATION_ACCEPTED_PRE_BI: "C2B_VALUATION_ACCEPTED_PRE_BI",
    C2B_INSPECTION_BOOKED: "C2B_INSPECTION_BOOKED",
    C2B_EMAIL_ENTERED: "C2B_EMAIL_ENTERED",
    C2B_PRICE_UPDATE_ADOPTION: "C2B_PRICE_UPDATE_ADOPTION"
};
