import { fastlyUrl } from "../../utils/helpers/cdn-image-url";
import { carDetailUrlAu } from "../../utils/helpers/get-detail-page-url";
import priceFormatter from "../../utils/helpers/price-formatter";

const carData = (carDetailsData, {
    // list = "",
    // position = "",
    config,
    excludedKeys = [],
    prefix = "",
    shortListed
} = {}) => {
    const {
        appointmentId,
        carId,
        egc,
        make,
        bodyType,
        year,
        model,
        variant,
        orderId,
        body,
        mainImage,
        price
    } = carDetailsData || {};
    const carName = `${make || ""} ${model || ""}`;
    const {path = ""} = mainImage || {};

    const data = {
        [`${prefix}timestamp`]: Date.now(),
        [`${prefix}appid`]: appointmentId || carId,
        [`${prefix}car_name`]: `${year} ${make} ${model}`,
        [`${prefix}make`]: make,
        [`${prefix}model`]: model,
        [`${prefix}car_type`]: bodyType || body,
        [`${prefix}image`]: fastlyUrl((config || {}).imageHost, path),
        [`${prefix}year`]: year,
        [`${prefix}variant`]: variant,
        [`${prefix}cdp_link`]: carDetailUrlAu({year, carName, carId: appointmentId || carId}).absoluteURL,
        [`${prefix}price`]: egc,
        [`${prefix}discount`]: price - egc,
        ...(orderId && {[`${prefix}orderId`]: orderId}),
        ...(shortListed && {"wishlist_status": shortListed})
        // "viewed_discount":
        // "viewed_similar_cdp_link":
    };
    excludedKeys.forEach(item => delete data[item]);
    return data;
};

export default (payload, properties = {}) => {
    return  carData(payload, properties);
};

export const cleverTapTradeinDetails = (payload) => {
    const {vehicleMetaData: {make, model, year, variant} = {}, vin, odometerReading, regNo } = payload;
    return ({
        "trade_in_make": make,
        "trade_in_model": model,
        "trade_in_year": year,
        "trade_in_km": odometerReading,
        "trade_in_timestamp": Date.now(),
        "trade_in_variant": variant,
        ...(vin && {"trade_in_vin": vin}),
        ...(regNo && {"trade_in_registration_number": regNo})
    });
};

export const c2bUserDetails = (tradeInData) => {
    const {userEmail, buildYear, fullName, vehicleMetaData, userEnteredYear, makeModelFlow, state, dsOfferedPrice, kbbValuation = {}, c2bEligible, c2bOrderId, offeredPrice, showPathName, pathname, showValuationDate, firstTradeMode, sellingType, mobile, desktop} = tradeInData;
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })
        .replace(/\//g, "-");

    return ({
        "User ID": userEmail,
        ...(fullName && {"Full Name": fullName}),
        "C2B Make": vehicleMetaData?.make,
        "C2B Model": vehicleMetaData?.model,
        "C2B Build Year": (makeModelFlow ? vehicleMetaData?.year : userEnteredYear) || buildYear,
        "C2B Car State": state,
        "C2B DS Price": dsOfferedPrice,
        ...(kbbValuation.valuation && {"C2B KBB Price": kbbValuation.valuation}),
        "C2B Eligibilty": c2bEligible,
        "C2B Platform": desktop ? "web" : "msite",
        "C2B Order ID": c2bOrderId,
        "C2B Offered Price": offeredPrice,
        ...(showValuationDate && {"C2B valuation Shown Date": formattedDate}),
        ...(showPathName && {"C2B personalised valuation page link": pathname}),
        "C2B First Trade Mode": firstTradeMode,
        ...(sellingType && {"C2B Selling Type": sellingType}),
        ...(mobile && {"Phone Number": `+614${mobile}`})
    });

};

export const c2bUserDetailsForCarValuationCalculator = (tradeInData) => {
    const {userEmail, email, buildYear, vehicleMetaData, userEnteredYear, makeModelFlow, c2bEligible, lowerLimitOfferedPrice, upperLimitOfferedPrice, mobile } = tradeInData;
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" })
        .replace(/\//g, "-");

    return ({
        "User ID": userEmail || email,
        "C2B_PriceSignup_Make": vehicleMetaData?.make,
        "C2B_PriceSignup_Model": vehicleMetaData?.model,
        "C2B_PriceSignup_BuildYear": (makeModelFlow ? vehicleMetaData?.year : userEnteredYear) || buildYear,
        "C2B_PriceSignup_Eligibility": c2bEligible,
        "C2B_PriceSignup_RangePrice": `$${priceFormatter(lowerLimitOfferedPrice)} to $${priceFormatter(upperLimitOfferedPrice)}`,
        "C2B_PriceSignup_DateOfSignup": formattedDate,
        "C2B_PriceSignup_MobileNumber": `+614${mobile}`
    });
};
