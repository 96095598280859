/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React from "react";
import styles from "./styles.css";
import Verified from "./images/verified.svg";
// import FinanceFirstCheckoutPriceBreakupModal from "../finance-first-checkout-price-breakup-modal";
import { getCarName } from "../../../utils/helpers/car-properties.ae";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import CountUp from "react-countup";
import { LOAN_PROVIDER } from "../../../constants/app-constants";

const MyBookingPostBcCarCard = ({
    paymentSummaryData,
    content,
    config,
    frequency = "week",
    showNumberAnimation = false,
    financeProvider,
    splitPaymentOpted
}) => {
    const { mainImage } = content;

    const carFullName = getCarName(content);
    const hasMainImage = mainImage && mainImage.path && config && config.imageHost ? true : false;
    const carImageUrl = hasMainImage
        ? fastlyUrl(config.imageHost, mainImage.path, imageTypes.auCarCheckoutThumbnail)
        : null;
    const isFinanceUser = financeProvider === LOAN_PROVIDER.CARS24;
    const emi = paymentSummaryData?.repayment?.emi;

    const carAmountLabel = isFinanceUser
        ? "Loan amount (incl. of fees)"
        : splitPaymentOpted
            ? "Amount due"
            : "Drive away price";

    const totalLoanAmount = paymentSummaryData?.amountDue;

    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.leftWrapper"}>
                <p styleName={"styles.carName"}>{carFullName}</p>
                <p styleName={"styles.loanAmount"}>
                    {carAmountLabel}
                </p>

                <p styleName={"styles.totalAmount"}>
                    {showNumberAnimation ? (
                        <CountUp duration={1} separator="," prefix="$" end={totalLoanAmount} />
                    ) : (
                        <span>
                            {makePriceLabelRound(totalLoanAmount)}
                        </span>
                    )}

                    {isFinanceUser && "| "}

                    {isFinanceUser && (
                        <React.Fragment>
                            {showNumberAnimation ? (
                                <p styleName={"styles.loanEmi"}>
                                    <CountUp duration={1} separator="," prefix="$" end={emi} />/{ frequency }
                                </p>
                            ) : (
                                <React.Fragment>
                                    {makePriceLabelRound(emi)}/{frequency}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </p>
            </div>
            <div styleName={"styles.imageWrapper"}>
                <img src={carImageUrl} />
                <p styleName={"styles.reserved"}>
                    <img src={Verified} />
                    Reserved
                </p>
            </div>
        </div>
    );
};

MyBookingPostBcCarCard.propTypes = {
    paymentSummaryData: PropTypes.object,
    content: PropTypes.object,
    config: PropTypes.object,
    frequency: PropTypes.string,
    financeProvider: PropTypes.string,
    showNumberAnimation: PropTypes.bool,
    splitPaymentOpted: PropTypes.bool
};

export default MyBookingPostBcCarCard;
