/* eslint-disable no-invalid-this */
/* eslint-disable consistent-this */
export default (func, delay) => {
    let debounceTimer;
    const debouncedFunction = function() {
        const context = this;
        const args = arguments;
        if (typeof window !== "undefined") {
            window.clearTimeout(debounceTimer);
            debounceTimer
            = window.setTimeout(() => func.apply(context, args), delay);
        }
    };
    // Add cancel method to the debounced function in case of unmount
    debouncedFunction.cancel = () => {
        if (typeof window !== "undefined" && debounceTimer) {
            window.clearTimeout(debounceTimer);
        }
    };
    return debouncedFunction;
};
