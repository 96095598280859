import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OrderSummaryPopup from "./component";

const mapStateToProps = ({
    carDetails: { content, config },
    myBookings: { paymentSummaryData },
    checkout: {
        order: {
            orderId,
            bookingAmount,
            bookingConfirmDate,
            modifiedDate,
            totalAmount,
            deliveryAddress,
            chargeDetails,
            paymentMethodType,
            pickUpAddress,
            financeOpted,
            deliveryMode,
            financeProvider
            // discountApplied
        },
        egcData,
        tradeinOpted,
        tradeInData,
        splitAmountDetails,
        isManualSales
    },
    deliveryInfo

}) => ({
    content,
    config,
    orderId,
    bookingAmount,
    bookingConfirmDate,
    paymentSummaryData,
    modifiedDate,
    totalAmount,
    deliveryInfo,
    deliveryMode,
    pickUpAddress,
    deliveryAddress,
    chargeDetails,
    paymentMethodType,
    financeOpted,
    egcData,
    tradeinOpted,
    tradeInData,
    splitAmountDetails,
    isManualSales,
    financeProvider
    // discountApplied
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryPopup);
