import { createReducer } from "reduxsauce";
import { MY_BOOKINGS_PAGINATION_SIZE } from "../../../constants/app-constants";
import Types from "./types";

export const INITIAL_STATE = {
    bookings: {
        cars: [],
        // pageable: {},
        totalOrders: null,
        totalPages: null,
        pageSize: MY_BOOKINGS_PAGINATION_SIZE,
        currentPage: 0
    },
    paymentSummaryData: {},
    myBookingError: null,
    myBookingsFetched: false,
    isFetching: true,
    selectedCarId: "",
    testDriveCarsData: {
        cars: [],
        // pageable: {},
        totalOrders: null,
        totalPages: null,
        pageSize: MY_BOOKINGS_PAGINATION_SIZE,
        currentPage: 0
    }
};

export const getMyBookingsInitiated = (state = INITIAL_STATE) => {
    return { ...state, isFetching: true};
};
export const getMyPaymentSummary = (state = INITIAL_STATE, {data = {}}) => {
    return {
        ...state,
        paymentSummaryData: data
    };
};

export const getMyBookingsSuccess = (state = INITIAL_STATE, { data, params }) => {
    const newCars = data.data || [];
    const {totalOrder = 0, data: testDriveCars = []} = data.testDriveCars || {};

    return {
        ...state,
        bookings: {
            cars: params.page === 0 ? newCars : [...state.bookings.cars, ...newCars],
            // pageable: data.pageable,
            totalOrders: data.totalOrder,
            totalPages: Math.ceil(data.totalOrder / MY_BOOKINGS_PAGINATION_SIZE),
            currentPage: params.page,
            pageSize: MY_BOOKINGS_PAGINATION_SIZE
        },
        testDriveCarsData: {
            cars: params.page === 0 ? testDriveCars : [...state.testDriveCarsData.cars, ...testDriveCars],
            totalOrders: totalOrder

        },
        myBookingsFetched: true,
        isFetching: false,
        myBookingError: null
    };
};

export const getMyBookingsFailure = (state = INITIAL_STATE, {error}) => {
    return { ...state, myBookingError: error, isFetching: false, myBookingsFetched: true};
};

export const updateSelectedCar = (state = INITIAL_STATE, {carId}) => {
    return { ...state, selectedCarId: carId};
};

export const updatePaymentSummaryLoading = (state = INITIAL_STATE, {loading = false}) => {
    return { ...state, paymentSummaryLoading: loading};
};
export const HANDLERS = {
    [Types.GET_MY_BOOKINGS_INITIATED]: getMyBookingsInitiated,
    [Types.GET_MY_BOOKINGS_SUCCESS]: getMyBookingsSuccess,
    [Types.GET_MY_BOOKINGS_FAILURE]: getMyBookingsFailure,
    [Types.UPDATE_SELECTED_CAR]: updateSelectedCar,
    [Types.GET_MY_PAYMENT_SUMMARY]: getMyPaymentSummary,
    [Types.PAYMENT_SUMMARY_LOADING]: updatePaymentSummaryLoading

};

export default createReducer(INITIAL_STATE, HANDLERS);
