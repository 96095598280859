import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getWarrantyPlans, updateWarrantyPlans, updateCheckoutSteps } from "../../checkout-info/actions";
import PostBcAddonsExplore from "./component";
import { getVASCart, setAddonScreenType, getCarCoverBundles, clearVASCart, removeVASItem, setCounterAnimation } from "../../add-ons/actions";
import { updateLoanQuote } from "../../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";

const mapStateToProps = ({
    carDetails: { content },
    checkout: {
        extendedWarranty: {
            warrantyPlanInfo
        },
        order,
        deliveryMode,
        tradeInData,
        egcData,
        financeFirstCheckoutEnabled
    },
    abExperiment: {
        showWarrantyComplimentary,
        showWarrantyPageShift
    },
    deliveryInfo: { deliveryData, pickupCharges },
    addOns: {
        vasCart: {
            data: vasCartData
        },
        servicingPriceConfig,
        carCoverBundles: {
            data: carCoverData
        }
    },
    deliveryInfo: {
        deliveryData: { selfPickUp }
    }
}) => ({
    content,
    warrantyPlanInfo,
    order,
    tradeInData,
    showWarrantyComplimentary,
    showWarrantyPageShift,
    deliveryData,
    egcData,
    vasCartData,
    deliveryMode,
    pickupCharges,
    servicingPriceConfig,
    carCoverData,
    financeFirstCheckoutEnabled,
    selfPickUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getWarrantyPlansConnect: getWarrantyPlans,
    updateWarrantyPlansConnect: updateWarrantyPlans,
    updateCheckoutStepsConnect: updateCheckoutSteps,
    setAddonScreenTypeConnect: setAddonScreenType,
    getVASCartConnect: getVASCart,
    getCarCoverBundleConnect: getCarCoverBundles,
    clearVASCartConnect: clearVASCart,
    updateLoanQuoteConnect: updateLoanQuote,
    setCounterAnimationConnect: setCounterAnimation,
    removeVASItemConnect: removeVASItem
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBcAddonsExplore);
