/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import Unselected from "./images/unselected.svg";
import Selected from "./images/selected.svg";
import BestValue from "./images/badge.png";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";

const PlatinumCoverMonthlyCard = ({
    type,
    coverDetails,
    isActive,
    setSelectedProductIndex,
    index,
    clearSelection,
    bottomSticker,
    hideClearButton,
    repaymentsPerWeek,
    isPostBcCard = false
}) => {
    const { title, discountedPrice, tag, products, description } = coverDetails || {};
    const isBundleSection = products?.length > 0;
    const isBestValue = tag?.toLowerCase() === "best value";

    return (
        <div styleName={`styles.outer ${isActive ? "styles.cardActive" : ""}`}
            onClick={() => {
                setSelectedProductIndex(index);
            }}>
            {isBestValue && <div styleName={"styles.bestValue"}>
                <img src={BestValue} alt="Best value sticker" />
            </div>}
            <div styleName={"styles.wrapper"}>
                <div className="media" styleName={"styles.cardContainer"}>
                    <img src={isActive ? Selected : Unselected} alt="Unselected Checkbox" />
                    <div className="media-body">
                        <p styleName={"styles.heading"}>{title}</p>
                        {
                            !isBundleSection && Array.isArray(description) ?
                                description?.length > 1 ?
                                    <ul styleName={"styles.list"}>
                                        {description.map((item) => <li>{item.value}</li>)}
                                    </ul> :
                                    <p styleName={"styles.singleDescription"}>{description[0].value}</p>
                                :
                                null
                        }

                        {
                            !isPostBcCard && <p styleName={"styles.singleDescription"}>{description}</p>
                        }

                        <ul>
                            {isBundleSection && products.map((product, listIndex) => (
                                <li key={listIndex} styleName={"styles.list"}>{product.bundleDescription}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div styleName={"styles.amountWrapper"}>
                    <p styleName={"styles.amountPerWeek"}>{type === "loan" ? <React.Fragment>
                        {makePriceLabelRound(repaymentsPerWeek)}<span>/wk</span>
                    </React.Fragment> : makePriceLabelRound(discountedPrice)}</p>
                    {isActive && !hideClearButton && <p styleName={isBundleSection ? "styles.clearSelect" : "styles.clearSelection" } onClick={(e) => clearSelection(e, index)}>Clear selection</p>}
                </div>
            </div>
            {bottomSticker && <div styleName={"styles.bottomSticker"}>
                <p styleName={"styles.savedText"}>{bottomSticker}</p>
            </div>}
        </div>
    );
};

PlatinumCoverMonthlyCard.propTypes = {
    type: PropTypes.string,
    coverDetails: PropTypes.object,
    isActive: PropTypes.bool,
    setSelectedProductIndex: PropTypes.func,
    index: PropTypes.number,
    clearSelection: PropTypes.func,
    bottomSticker: PropTypes.string,
    hideClearButton: PropTypes.bool,
    repaymentsPerWeek: PropTypes.number,
    isPostBcCard: PropTypes.bool
};

export default PlatinumCoverMonthlyCard;
