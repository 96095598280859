import PlatinumCoverExpandedSection from "./component";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addVASItem, clearVASCart, getVASItems, removeVASItem, postBcUpdateStep, setAddonScreenType, setCounterAnimation, getPostBcLandingPlans, updatePostBcStep } from "../../add-ons/actions";
import { showToast } from "../../../shared/toast-message/actions";
import { getPaymentSummary } from "../../my-bookings/actions";
// import { updateLoanQuote } from "../../../au.mobile/pre-approval-financing-flow/pre-approval-loan-wrapper/actions";

const mapStateToProps = ({
    carDetails: {
        content,
        content: {
            appointmentId,
            egc,
            year
        }
    },
    checkout: {
        order: {
            orderId,
            paymentOption
        },
        quotesAvailable: preApproved,
        financeFirstCheckoutEnabled
    },
    addOns: {
        vasItems,
        activeStore,
        addVASItem: {
            loading: addLoading,
            key: addKey
        },
        removeVASItem: {
            loading: removeLoading,
            key: removeKey
        },
        postBcLandingPlans: {
            data: postBcLandingPlans
        } = {}
    },
    deliveryInfo: {
        deliveryData: {selfPickUp}
    }
}) => ({
    activeStore,
    addKey,
    addLoading,
    appointmentId,
    content,
    egc,
    removeKey,
    removeLoading,
    orderId,
    preApproved,
    vasItems,
    year,
    financeFirstCheckoutEnabled,
    selfPickUp,
    postBcLandingPlans,
    paymentOption
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addVASItemConnect: addVASItem,
    clearVASCartConnect: clearVASCart,
    getVASItemsConnect: getVASItems,
    removeVASItemConnect: removeVASItem,
    setAddonScreenTypeConnect: setAddonScreenType,
    setCounterAnimationConnect: setCounterAnimation,
    showToastConnect: showToast,
    postBcUpdateStepConnect: postBcUpdateStep,
    getPostBcLandingPlansConnect: getPostBcLandingPlans,
    updatePostBcStepConnect: updatePostBcStep,
    getPaymentSummaryConnect: getPaymentSummary
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlatinumCoverExpandedSection);
