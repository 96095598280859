import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderDetail } from "../checkout-info/actions";
import PriceBreakUpRevamp from "./component";

const mapStateToProps = ({
    carDetails: {
        content: {promotion, gst},
        eligibleForExtendedWarranty
    },
    deliveryInfo: {
        shippingPrice
    },
    config: {
        saleConfig
    },
    checkout: {
        byoFinanceData: {
            deposit: byoDeposit
        },
        financeData,
        order: {
            priceBreakDown,
            discount,
            status,
            financeOpted,
            financeProvider,
            deliveryMode,
            orderId,
            extendedWarrantyOpted,
            extendedWarrantyOptedPage,
            appointmentId
        },
        promoCode,
        isManualSales,
        testDriveData,
        tradeInOpted
    },
    user: {
        isUserZeroDpVariant
    },
    preApprovalLoan: {
        quotesAvailable: preApproved
    },
    myBookings: {
        paymentSummaryData
    }
}) => ({
    financeData,
    saleConfig,
    byoDeposit,
    shippingPrice,
    tradeInOpted,
    promotion,
    discount,
    gst,
    promoCode,
    extendedWarrantyOpted,
    extendedWarrantyOptedPage,
    priceBreakDown,
    status,
    financeOpted,
    financeProvider,
    isManualSales,
    deliveryMode,
    testDriveData,
    isUserZeroDpVariant,
    preApproved,
    orderId,
    paymentSummaryData,
    eligibleForExtendedWarranty,
    appointmentId

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrderDetailConnect: getOrderDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceBreakUpRevamp);
