import { appUrl } from "../../constants/url-constants";

export const getMakeModelSeoUrl = (make, model) => {
    const formattedMake = make?.toLowerCase().replace(/\s+/g, "-") || "";
    const formattedModel = model?.toLowerCase().replace(/\s+/g, "-") || "";
    const fullSeoListingPath = `buy-used-${formattedMake}${formattedModel ? `-${formattedModel}` : ""}`;
    return {
        absoluteUrl: `${appUrl()}/${fullSeoListingPath}`,
        relativeUrl: `/${fullSeoListingPath}`
    };
};

