/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
// import { checkoutSummarySaleLabel } from "../../../utils/helpers/on-sale-label";
// import { ORDER_STATUS, DELIVERY_TYPES } from "../../../constants/checkout-constants";
// import usePricing from "../../../hooks/use-pricing";

const DriveAwayPriceModal = ({
    onClose,
    paymentSummaryData
}) => {
    const {dapSummary: summary} = paymentSummaryData || {};
    const renderSubItems = (summaryItem) => {
        return (
            <div styleName={"styles.secondSectionWrap"}>

                {summaryItem?.lineItems?.map((subItem) => (
                    <div styleName={"styles.firstSectionWrapper"}>
                        <p styleName={"styles.leftText"}>{subItem?.label}</p>
                        <p styleName={"styles.priceInBold"}>{subItem?.type === "SUBTRACTION" ? `-${makePriceLabelRound(subItem?.amount)}` : makePriceLabelRound(subItem?.amount)}</p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose} close={onClose}>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.headerWrap"}>
                    <p styleName={"styles.nextStep"}>drive away price</p>
                    <div styleName={"styles.close"}>
                        <CloseButton type="grey" onClickHandler={onClose}/>
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.priceBreakupWrapper"}>
                        {summary && summary.lineItems.length > 0 &&
                        summary.lineItems.map((item) => {
                            return (
                                <React.Fragment key={item?.key}>
                                    <div styleName={"styles.firstSectionWrapper"}>
                                        <p styleName={"styles.leftText"}>{item?.label}</p>
                                        <p styleName={"styles.priceInBold"}>{item?.type === "SUBTRACTION" ? "-" : ""}{makePriceLabelRound(item.amount)}</p>
                                    </div>
                                    {item?.summary?.lineItems &&
                                    item?.summary?.lineItems.length > 0 &&
                                    renderSubItems(item?.summary)}
                                </React.Fragment>

                            );
                        })
                        }
                        <div styleName={"styles.firstSectionWrapper styles.border"}>
                            <p styleName={"styles.leftText"}>{summary?.aggregatedItem?.label}</p>
                            <p styleName={"styles.totalPrice"}>{makePriceLabelRound(summary?.aggregatedItem?.amount)}</p>
                        </div>
                    </div>
                    {Object.keys(summary?.termsAndConditions || {}).length > 0 && (
                        <div styleName={"styles.bottomBox"}>
                            {(summary?.termsAndConditions || []).map((tnc, index) => (
                                <p styleName={"styles.bottomText"} key={index}>
                                    {tnc}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    myBookings: {
        paymentSummaryData
    }
}) => ({

    paymentSummaryData
});

DriveAwayPriceModal.propTypes = {
    onClose: PropTypes.func,
    paymentSummaryData: PropTypes.object
};

export default connect(mapStateToProps, null)(DriveAwayPriceModal);
