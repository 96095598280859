/* eslint-disable complexity */
/* eslint-disable no-debugger */
import React from "react";
import CloseButton from "../../shared/icon-cross";
import BookCarDetail from "../checkout-car-detail/component";
import styles from "./styles.css";
import PropTypes from "prop-types";
import { getBookingConfirmedDate } from "../../../utils/helpers/get-day-from-date";
import BookingsFooter from "../bookings-footer/component";
// import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
// import { getPaymentTypeLabel } from "../../../utils/helpers/checkout-util";
import { DELIVERY_TYPES, PAYMENT_TYPE } from "../../../constants/checkout-constants";
import ArrowIcon from "../../shared/arrow";
import shippingIcon from "./images/shipping-address.svg";
import contactIcon from "./images/contact-icon.svg";
import DOLLORICON from "./images/price-icon.svg";
// import priceIcon from "./images/price-icon.svg";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getPaymentTypeLabel, getPaymentTypePrefix } from "../../../utils/helpers/checkout-util";
// import PaymentHistoryCards from "../payment-history-cards";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import PriceBreakdown from "../price-breakdown";
import { LOAN_PROVIDER } from "../../../constants/app-constants";
import ComplimentaryBenefits from "../../au.mobile/complimentary-benefits";
import { getGroupedObj } from "../../../utils/helpers/get-grouped-obj";

const OrderSummaryPopup = ({
    content,
    config,
    orderId,
    bookingAmount,
    bookingConfirmDate,
    modifiedDate,
    setShowOrderSummary,
    deliveryAddress,
    paymentMethodType,
    financeOpted,
    tradeinOpted,
    splitAmountDetails = {},
    handlePaymentHistory = () => {},
    pickUpAddress,
    deliveryMode,
    isManualSales,
    paymentSummaryData,
    financeProvider,
    chargeDetails
    // discountApplied
}) => {
    // const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const { addressLine1, addressLine2, customerPhoneNumber} = deliveryAddress || "";
    const {totalAmountPaid, amountDue, totalAmountToCollect, summary, dapSummary} = paymentSummaryData || {};
    const isInHouse = financeProvider === LOAN_PROVIDER.CARS24 && financeOpted;
    const { charges } = getGroupedObj(chargeDetails, "type");
    const { state = ""} = pickUpAddress || {};

    // const [ viewPriceBreakup, setViewPriceBreakup ] = useState(false);
    const { isSplitPaymentOpted} = splitAmountDetails;
    const finalAmountToPay = amountDue || totalAmountToCollect;

    const closeOrderSummary = () => {
        setShowOrderSummary(false);
    };

    // const togglePriceBreakup = () => {
    //     setViewPriceBreakup(!viewPriceBreakup);
    // };

    const trackEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SPLIT_ORDER_SUMMARY_POST_PAYMENT, {eventLabel});
    };

    // const handlePaymentHistory = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setShowPaymentHistory(!showPaymentHistory);
    //     trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SPLIT_ORDER_SUMMARY_POST_PAYMENT, {eventLabel: "view_payment_history"});
    // };

    const renderDAPPriceOnSplit = () => {
        let  dapLabel = finalAmountToPay ?  "Drive-away Price" : "Total Amount Paid";
        dapLabel = tradeinOpted ? "Final Price" : dapLabel;
        return (
            <div
                styleName={"styles.paymentInfo"}>
                <p styleName={"styles.titleText"}>
                    <label>{dapLabel}</label>
                    <span>{makePriceLabelRound(Math.abs(totalAmountToCollect))}</span>
                </p>
                {/* {!!discountApplied && <p styleName="styles.discountApply">
                    Includes {makePriceLabelRound(discountApplied)} Christmas discount
                </p>} */}
                {!isManualSales && <React.Fragment>
                    {!!finalAmountToPay &&
                    <React.Fragment>
                        <p styleName={"styles.priceBreakup"}>
                            <span styleName={"styles.priceLabel"}>{financeOpted ? "Deposit Paid" :  "Total Paid"}</span>
                            <strong styleName={"styles.price styles.blueText"}>{makePriceLabelRound(totalAmountPaid)}</strong>
                        </p>
                        {!financeOpted && <p styleName={"styles.priceBreakup"}>
                            <span styleName={"styles.priceLabel"}>Amount Due</span>
                            <strong styleName={"styles.price styles.redText"}>{makePriceLabelRound(amountDue)}</strong>
                        </p>}
                    </React.Fragment>
                    }

                    <span styleName={"styles.viewCta"} onClick={handlePaymentHistory}>View Payment History <ArrowIcon /></span>
                </React.Fragment>}
            </div>
        );
    };

    const renderDAPPrice = () => {
        return (
            <React.Fragment>
                <p styleName={"styles.priceBreakup"}>
                    <strong styleName={"styles.priceLabel"}>Total Amount Paid </strong>
                    <span styleName={"styles.price"}>{makePriceLabelRound(bookingAmount)}</span>
                </p>
                {/* {!!discountApplied && <p styleName="styles.discountApply">
                    Includes {makePriceLabelRound(discountApplied)} Christmas discount
                </p>} */}

                {bookingAmount ? <p styleName={"styles.priceBreakup styles.border"}>
                    <small styleName={"styles.paymentMode"}>{getPaymentTypePrefix(paymentMethodType)} {getPaymentTypeLabel(paymentMethodType)}</small>
                </p> : ""}
            </React.Fragment>
        );
    };

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.header"}>
                <h4 styleName={"styles.heading"}>ORDER SUMMARY</h4>
                <div styleName={"styles.headerActions"}>
                    <span styleName={"styles.crossIcon"} onClick={closeOrderSummary}>
                        <CloseButton type="grey"/>
                    </span>
                </div>
            </div>
            <div styleName={"styles.outer"}>
                <div styleName={"styles.orderIdWrap"}>
                    <strong styleName={"styles.orderId"}>Order ID: #{orderId} </strong>
                    {bookingConfirmDate && (
                        <span styleName={"styles.orderDetail"}>
                        On {getBookingConfirmedDate(new Date(bookingConfirmDate))}
                        </span>
                    )}
                    {!bookingConfirmDate && paymentMethodType === PAYMENT_TYPE.BANK_TRANSFER.value && modifiedDate && (
                        <span styleName={"styles.orderDetail"}>
                        Payment initiated on {getBookingConfirmedDate(new Date(modifiedDate))}
                        </span>
                    )}
                </div>
                <div styleName={"styles.detailWrapperBox"}>
                    <BookCarDetail {...content} config={config} showPaidInfo={false}/>
                </div>
                {/* <div styleName={"styles.paidBox styles.totalAmount"}> */}

                {isSplitPaymentOpted || financeOpted ? renderDAPPriceOnSplit() : renderDAPPrice() }

                {/* <React.Fragment>
                    <div styleName={"styles.priceBreakupWrap"}>
                        <p styleName={"styles.priceBreakup styles.bottomSpace"}>
                            <strong styleName={"styles.driveAway"}>Total Amount Paid </strong>
                            <strong styleName={"styles.priceText"}>$59,995</strong>
                        </p>
                        <span styleName={"styles.priceLabel"}>Paid by card</span>
                    </div>
                </React.Fragment> */}
                <div styleName={"styles.shippingWrapper"}>
                    <h3 styleName={"styles.titleWithIcon"}>
                        <img src={shippingIcon} alt="Shipping Address" />
                        {deliveryMode === DELIVERY_TYPES.PickUp ? "Pick Up Address" : "Shipping Address"}
                    </h3>
                    {
                        deliveryMode === DELIVERY_TYPES.PickUp ? <React.Fragment>
                            <p styleName={"styles.shippingAddress"}> {pickUpAddress.addressLine1}</p>
                            <p styleName={"styles.shippingAddress"}>{state}</p>
                        </React.Fragment> : <React.Fragment>
                            <p styleName={"styles.shippingAddress"}>{addressLine1}</p>
                            <p styleName={"styles.shippingAddress"}>{addressLine2}</p>
                        </React.Fragment>
                    }

                </div>
                <div styleName={"styles.contactDetailWrapper"}>
                    <h3 styleName={"styles.titleWithIcon"}>
                        <img src={contactIcon} alt="Contact Details" />
                        Contact Details
                    </h3>
                    <p>+61 {customerPhoneNumber}</p>
                </div>
                {/* <h3 styleName={"styles.titleWithIcon"}>
                    <img src={priceIcon} alt="Price Breakdown" />
                        Price Breakdown
                </h3> */}
                <div>
                    <div styleName={"styles.new-price-breakup"}>
                        <img src={DOLLORICON}/>
                        <p>Price Breakdown</p>
                    </div>
                    <PriceBreakdown postBcSummary={ isInHouse ? summary : dapSummary} hidePriceBreakup={true} />
                    {!isInHouse && <div className={"mb-3"}>
                        <ComplimentaryBenefits complemetaryBenefits={{...charges}} />
                    </div>}
                </div>
                <BookingsFooter cancelLabelPosition="top" trackButtonClick={trackEvent}/>
                {/* {showPaymentHistory &&  <PaymentHistoryCards onClose={handlePaymentHistory}/>} */}
            </div>

        </div>
    );
};

OrderSummaryPopup.propTypes = {
    orderId: PropTypes.string,
    bookingAmount: PropTypes.number,
    bookingConfirmDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    appointmentId: PropTypes.string,
    setShowOrderSummary: PropTypes.func,
    deliveryInfo: PropTypes.object,
    // totalAmount: PropTypes.number,
    deliveryAddress: PropTypes.object,
    content: PropTypes.object,
    chargeDetails: PropTypes.array,
    config: PropTypes.object,
    paymentMethodType: PropTypes.string,
    deliveryMode: PropTypes.string,
    egcData: PropTypes.array,
    tradeinOpted: PropTypes.bool,
    isManualSales: PropTypes.bool,
    tradeInData: PropTypes.object,
    splitAmountDetails: PropTypes.object,
    finalRemainingAmount: PropTypes.number,
    handlePaymentHistory: PropTypes.func,
    pickUpAddress: PropTypes.object,
    paymentSummaryData: PropTypes.object,
    financeOpted: PropTypes.bool,
    financeProvider: PropTypes.string
    // discountApplied: PropTypes.number
};

export default OrderSummaryPopup;
