/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import styles from "./styles.css";
import BannerRevamp from "../add-ons/banner-revamp/component";
import WarrantyInstallationIncludedCard from "../warranty-installation-included-card/component";
import AddonsTestimonialSlider from "./addons-testimonial-slider/component";
import CarPackagesRevamp from "./car-packages-revamp/component";
import PickProducts from "./pick-products/component";
import VasCartSummary from "../vas-cart-summary/component";
import VasStore from "../add-ons/vas-store/component";
import DriveAwayPriceModal from "../drive-away-price-modal-revamp/component";
import Loader from "../../shared/loader";
import { trackAmplitude, trackDesktopAmplitude } from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../tracking/amplitude-events";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { CHECKOUT_STEPS, CHECKOUT_STEPS_STATUS} from "../../../constants/checkout-constants";
import Button from "../../shared/button";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { SCREEN_TYPE } from "../add-ons/reducers";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import VasProductDetail from "../add-ons/vas-product-detail";
import ReviewYourPurchase from "../add-ons/review-your-purchase/component";
import { filterExcludedItems } from "../../../utils/helpers/checkout-util";
import { SERVICING_VARIANT_MAPPING, VAS_PAGE_SCREEN_TYPE } from "../../../constants/app-constants";
import usePricing from "../../../hooks/use-pricing";

const AddOnsRevamp = ({
    appointmentId,
    content,
    clearVASCartConnect,
    getVASBundlesConnect,
    getVASCartConnect,
    order,
    orderId,
    routeData,
    screenType,
    setAddonScreenTypeConnect,
    setCounterAnimationConnect,
    showCounterAnimation,
    updateCheckoutStepsConnect,
    vasCartData,
    vasTrackerConnect,
    servicingPriceConfig,
    isServicingConfigLoading
}) => {
    const history = useHistory();
    const vasCartItems = (vasCartData || {}).items || [];

    let urlParams;

    if (typeof window !== "undefined" && window.location) {
        urlParams = new window.URLSearchParams(window.location.search);
    }

    const carYearQuery = urlParams && urlParams.get("carYear");
    const typeQuery = urlParams && urlParams.get("type");
    const keyQuery = urlParams && urlParams.get("key");
    const carPriceQuery = urlParams && urlParams.get("carPrice");

    const { egc, year } = content || {};

    // const { deliveryData = {} } = deliveryInfo || {};
    // const { homeDelivery = [] } = deliveryData || {};

    const showCartView = screenType === SCREEN_TYPE.CART_VIEW;
    const showVASStore = screenType === SCREEN_TYPE.VAS_STORE;

    const { priceBreakDown } = order || {};
    // const selectedDeliveryMode = deliveryMode || orderDeliveryMode || "";
    // const calculateShippingPrice =
    //     deliveryData && deliveryData.homeDelivery && selectedDeliveryMode === DELIVERY_TYPES.HomeDelivery
    //         ? (homeDelivery &&
    //             homeDelivery[0] &&
    //             homeDelivery[0].shipping &&
    //             homeDelivery[0].shipping[0] &&
    //             homeDelivery[0].shipping[0].shippingPrice) ||
    //         0
    //         : 0; /* No need to show shipping price on 1st delivery screen */
    //     const shippingCharge =
    // calculateShippingPrice > 0 && deliveryMode === DELIVERY_TYPES.HomeDelivery ? calculateShippingPrice : 0;
    const { dapOrTradePrice } = usePricing();

    const [showDapModal, setShowDapModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const isNewServicingVariant = servicingPriceConfig?.experiment === SERVICING_VARIANT_MAPPING.VARIANT;
    const filteredVASItems = isNewServicingVariant ? filterExcludedItems(vasCartItems) : vasCartItems;
    const isAddOnPurchased = filteredVASItems.length > 0;

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        return () => {
            if (showCounterAnimation) setCounterAnimationConnect(false);
        };
    }, [setCounterAnimationConnect, showCounterAnimation]);

    useEffect(() => {
        if (!filteredVASItems.length && showCartView) {
            setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
        }
    }, [setAddonScreenTypeConnect, showCartView, filteredVASItems.length]);

    useEffect(() => {
        if (orderId && year && egc && !isServicingConfigLoading) {
            const promises = [getVASCartConnect({ orderId, year, egc, vasPageName: VAS_PAGE_SCREEN_TYPE.ADD_ONS }), getVASBundlesConnect(year, egc)];
            Promise.allSettled(promises).finally(() => {
                setLoading(false);
            });
        }
    }, [egc, getVASBundlesConnect, getVASCartConnect, orderId, year, isServicingConfigLoading]);

    useEffect(() => {
        if (!isAddOnPurchased && orderId) {
            vasTrackerConnect(orderId, "LANDING_PAGE_VISITED");
        }
    }, [isAddOnPurchased, orderId, vasTrackerConnect]);

    const onSkipClick = () => {
        trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Skip",
            eventLabel: "Yes"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.PAYMENT_MODE_LANDED, {
            Skip: "Skip"
        });

        vasTrackerConnect(orderId, "VAS_SKIPPED");

        const stepData = {
            appointmentId,
            step: CHECKOUT_STEPS.ADDONS,
            state: CHECKOUT_STEPS_STATUS.SKIPPED
        };
        updateCheckoutStepsConnect(stepData);
        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const onVasStoreClose = () => {
        if (filteredVASItems.length > 0) setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
        else setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
        setCounterAnimationConnect(false);
    };

    const onProceed = () => {
        if (showCartView) {
            trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                eventAction: "Cart page_Action",
                eventLabel: "Proceed"
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CART_PAGE, {
                "Cart page_Action": "Proceed"
            });
        } else {
            trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
                eventLabel: "Yes",
                eventAction: "Proceed"
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.PAYMENT_MODE_LANDED, {
                Proceed: "Proceed"
            });
        }

        vasTrackerConnect(orderId, "VAS_ADDED");

        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const onSkip = async () => {
        setLoading(true);
        try {
            await clearVASCartConnect(orderId, VAS_PAGE_SCREEN_TYPE.ADD_ONS);
            vasTrackerConnect(orderId, "VAS_SKIPPED");
            onProceed();
        } catch (error) {
            //console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const toggleDapModal = () => {
        setShowDapModal(!showDapModal);
    };

    const onDapClick = () => {
        if (showCartView) {
            trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                eventAction: "Cart page_Action",
                eventLabel: "DAP_popup_clicked"
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CART_PAGE, {
                "Cart page_Action": "Drive away price"
            });
        } else {
            trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                eventAction: "DAP pop up",
                eventLabel: "Yes"
            });
            trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.CART_PAGE, {
                "VAS Store_Bottom nav_click": "Drive away price"
            });
        }

        toggleDapModal();
    };

    const showProducDetailPage = typeQuery && carYearQuery && keyQuery && carPriceQuery;

    if (loading) {
        return <Loader />;
    }

    if (showProducDetailPage) {
        return (
            <VasProductDetail
                open={showProducDetailPage}
                typeQuery={typeQuery}
                carPriceQuery={carPriceQuery}
                carYearQuery={carYearQuery}
                keyQuery={keyQuery}
            />
        );
    }

    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.middleSectionWrapper"}>
                <div styleName={"styles.addOns"}>
                    {showCartView ? (
                        <React.Fragment>
                            <BannerRevamp />
                            <ReviewYourPurchase />
                            <VasCartSummary />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <LandingPage />
                            {isAddOnPurchased && <div styleName={"styles.landingPageEmptySpace"} />}
                        </React.Fragment>
                    )}
                </div>
                <Footer
                    showNumberAnimation={showCounterAnimation}
                    onDapClick={onDapClick}
                    price={dapOrTradePrice}
                    isAddOnPurchased={isAddOnPurchased}
                    onProceed={onProceed}
                    onSkip={onSkip}
                    onSkipClick={onSkipClick}
                    showCartView={showCartView}
                />
            </div>
            <div styleName={"styles.leftPanelWrapper"}>
                <WarrantyInstallationIncludedCard />
            </div>

            {showVASStore && <VasStore open={showVASStore} onClose={onVasStoreClose} />}
            {showDapModal && <DriveAwayPriceModal onClose={toggleDapModal} priceBreakDown={priceBreakDown} />}
        </div>
    );
};

const LandingPage = () => {
    useEffect(() => {
        trackDesktopAmplitude(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Landed",
            eventLabel: "Yes"
        });

        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.LANDING_PAGE, {
            Landed: "Landed"
        });
    }, []);

    return (
        <React.Fragment>
            <BannerRevamp />
            <CarPackagesRevamp />
            <PickProducts />
            <AddonsTestimonialSlider />
        </React.Fragment>
    );
};

const Footer = ({
    showNumberAnimation,
    showCartView,
    isAddOnPurchased,
    onSkip,
    onProceed,
    onDapClick,
    onSkipClick,
    price
}) => {
    if (showCartView) {
        return (
            <div styleName={`styles.buttonWrapper ${showCartView ? "" : "styles.fixedButton"}`}>
                <div styleName={"styles.btn1"}>
                    <Button text="SKIP ADD-ONS" onClick={onSkip} />
                </div>
                <div styleName={"styles.btn2"}>
                    <Button text="ADD & PROCEED" onClick={onProceed} />
                </div>
            </div>
        );
    } else if (isAddOnPurchased) {
        return (
            <div styleName={"styles.addOnPurchased"}>
                <div styleName={"styles.priceWrapper"}>
                    {showNumberAnimation ? (
                        <p styleName={"styles.amount"}>
                            <CountUp duration={1} separator="," prefix="$" end={Math.abs(price || 0).toFixed(0)} />
                        </p>
                    ) : (
                        <div styleName={"styles.amount"}>{makePriceLabelRound(Math.abs(price || 0))}</div>
                    )}
                    <div onClick={onDapClick} styleName={"styles.dap"}>
                        Drive Away Price
                    </div>
                </div>

                <Button styleName={"styles.proceedBtn"} text={"Proceed"} onClick={onProceed} />
            </div>
        );
    }

    return (
        <div onClick={onSkipClick} styleName={"styles.buttonContainer"}>
            <button>Skip Add-ons</button>
        </div>
    );
};

Footer.propTypes = {
    isAddOnPurchased: PropTypes.bool,
    onDapClick: PropTypes.func,
    onProceed: PropTypes.func,
    onSkip: PropTypes.func,
    onSkipClick: PropTypes.func,
    preApproved: PropTypes.bool,
    price: PropTypes.number,
    showCartView: PropTypes.bool,
    showNumberAnimation: PropTypes.bool
};

AddOnsRevamp.propTypes = {
    activeStore: PropTypes.string,
    appointmentId: PropTypes.string,
    chargeDetails: PropTypes.array,
    clearVASCartConnect: PropTypes.func,
    content: PropTypes.object,
    deliveryMode: PropTypes.object,
    deliveryInfo: PropTypes.object,
    egcData: PropTypes.object,
    tradeInData: PropTypes.object,
    getVASBundlesConnect: PropTypes.func,
    getVASCartConnect: PropTypes.func,
    getVASItemsConnect: PropTypes.func,
    loading: PropTypes.bool,
    order: PropTypes.object,
    orderId: PropTypes.string,
    preApproved: PropTypes.bool,
    routeData: PropTypes.object,
    screenType: PropTypes.string,
    setAddonScreenTypeConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    showCounterAnimation: PropTypes.bool,
    updateCheckoutStepsConnect: PropTypes.func,
    vasCartData: PropTypes.object,
    vasTrackerConnect: PropTypes.func,
    servicingPriceConfig: PropTypes.object,
    isServicingConfigLoading: PropTypes.bool
};

export default AddOnsRevamp;
