const getGroupedObj = (arr = [], key) => {
    return arr && arr.reduce((acc, value) => {
        if (value[key]) {
            if (acc[value[key]]) acc = { ...acc, [value[key]]: [ ...acc[value[key]], value]};
            else acc = { ...acc, [value[key]]: [ value]};
        }
        return acc;
    }, {});
};

export {
    getGroupedObj
};
