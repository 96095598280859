/* eslint-disable complexity */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import PlatinumCoverPlans from "../add-ons-plans";

const PostBcAddons = ({ selfPickUp }) => {
    const servicingLocation = selfPickUp?.[0]?.pickUpLocation?.locationAddress;

    return (
        <React.Fragment>
            <div styleName={"styles.modalWrapper"}>
                <PlatinumCoverPlans servicingLocation={servicingLocation} />
            </div>
        </React.Fragment>
    );
};

PostBcAddons.propTypes = {
    selfPickUp: PropTypes.array
};

export default PostBcAddons;
