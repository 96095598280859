import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import verified from "./images/verified.svg";
import Slider from "react-slick";
import bannerOne from "./images/banner-1.png";
import bannerTwo from "./images/banner-2.png";
import bannerThree from "./images/banner-3.png";
import PostBcVasCongratulationScreenCard from "../post-bc-vas-congratulation-screen-card/component";
import arrowBack from "./images/arrow-back.svg";
import saveCookie from "../../../utils/helpers/save-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import PLATINUM_COVER_GA_EVENTS from "../post-payment-journey/add-ons/tracking";
import CoverageIcon from "./images/coverage.svg";
import GaurenteeIcon from "./images/gaurentee.svg";
import DollarIcon from "./images/dollar.svg";
import ConstructionIcon from "./images/construction.svg";
import { LOAN_PROVIDER } from "../../../constants/app-constants";

const PostBcVasCongratulationScreen = ({ availableKeys, carName, userName, orderId, userType, financeProvider }) => {
    const [isOpen, setIsOpen] = useState(false);
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrow: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000
    };

    const slides = [
        {
            topBanner: bannerTwo,
            heading: "Car servicing : Get peace of mind while you drive",
            planKey: "CAR_SERVICING",
            slideData: [
                {
                    icon: CoverageIcon,
                    text: "Full vehicle protection"
                },
                {
                    icon: ConstructionIcon,
                    text: "Skilled technicians"
                },
                {
                    icon: DollarIcon,
                    text: "Fully financeable"
                }
            ]
        },
        {
            topBanner: bannerThree,
            heading: "Style & protection : Select a CARS24 care package.",
            planKey: "VAS_POPULAR_BUNDLES",
            slideData: [
                {
                    icon: CoverageIcon,
                    text: "Upto lifetime warranty"
                },
                {
                    icon: ConstructionIcon,
                    text: "Certified installations"
                },
                {
                    icon: DollarIcon,
                    text: "Fully financeable"
                }
            ]
        },
        {
            topBanner: bannerOne,
            heading: "Platinum cover : Save big on warranty extension.",
            planKey: "EXTENDED_WARRANTY_PLAN",
            slideData: [
                {
                    icon: CoverageIcon,
                    text: "Best in class coverage"
                },
                {
                    icon: GaurenteeIcon,
                    text: "Best price Guarenteed"
                },
                {
                    icon: DollarIcon,
                    text: "Fully financeable"
                }
            ]
        },
        {
            topBanner: bannerOne,
            heading: "Platinum cover : Save big on warranty extension.",
            planKey: "CAR_COVER_BUNDLES",
            slideData: [
                {
                    icon: CoverageIcon,
                    text: "Best in class coverage"
                },
                {
                    icon: GaurenteeIcon,
                    text: "Best price. Gaurenteed"
                },
                {
                    icon: DollarIcon,
                    text: "Fully financeable"
                }
            ]
        }
    ].filter((slide) => availableKeys?.includes(slide.planKey));

    // Sorting slides based on the order of availableKeys
    const sortedSlides = slides.sort((a, b) => {
        return availableKeys.indexOf(a.planKey) - availableKeys.indexOf(b.planKey);
    });

    const handleCloseModal = () => {
        setIsOpen(false);
        // Set a cookie to prevent showing the modal again for this order ID
        saveCookie(`modal_shown_${orderId}`, "true");
        trackDesktopCustomEventsAU("", {
            ...PLATINUM_COVER_GA_EVENTS.LANDING_PAGE_ACTION,
            eventLabel: PLATINUM_COVER_GA_EVENTS.LANDING_PAGE_ACTION.eventLabel("Clicked_Next", userType)
        });
    };

    useEffect(() => {
        // Check if the modal cookie exists for this order ID
        const modalShown = parseCookie(`modal_shown_${orderId}`);
        if (!modalShown) {
            trackDesktopCustomEventsAU("", {
                ...PLATINUM_COVER_GA_EVENTS.LANDING_PAGE_ACTION,
                eventLabel: PLATINUM_COVER_GA_EVENTS.LANDING_PAGE_ACTION.eventLabel("Landed", userType)
            });
            setIsOpen(true); // Show the modal if the cookie is absent
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal isOpen={isOpen}>
            <div styleName={"styles.modalOuter"}>
                <div styleName={"styles.topPart"}>
                    <img src={verified} />
                    <p styleName={"styles.heading"}>Congratulations {userName}!</p>
                    <p styleName={"styles.subHeading"}>Your {carName} is reserved for you.</p>
                </div>
                <div styleName={"styles.sliderContainer"}>
                    <Slider {...settings}>
                        {sortedSlides.map((el) => (
                            <PostBcVasCongratulationScreenCard
                                key={el.planKey}
                                bannerImage={el.topBanner}
                                heading={el.heading}
                                slideData={el.slideData}
                            />
                        ))}
                    </Slider>
                </div>
                <p styleName={"styles.bottomText"}>
                    {financeProvider ===  LOAN_PROVIDER.CARS24
                        ? "Complete loan application and sign contract later."
                        : "Upload your driving license & sign contract thereafter."}
                </p>
            </div>
            <div styleName={"styles.buttonWrapper"}>
                <button onClick={handleCloseModal}>
                        Next
                    <img src={arrowBack} alt="Back Arrow Icon" />
                </button>
            </div>
        </Modal>
    );
};

PostBcVasCongratulationScreen.propTypes = {
    availableKeys: PropTypes.array,
    carName: PropTypes.string,
    userName: PropTypes.string,
    orderId: PropTypes.string,
    userType: PropTypes.string,
    financeProvider: PropTypes.string
};

export default PostBcVasCongratulationScreen;
