import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const PostBcVasCongratulationScreenSteps = ({ slideData }) => {
    return (
        <div styleName={"styles.stepsOuter"}>
            {slideData.map((el) => (
                <div styleName={"styles.steps"}>
                    <img src={el.icon} alt={el.text} />
                    <p styleName={"styles.stepsText"}>{el.text}</p>
                </div>
            ))}
        </div>
    );
};

PostBcVasCongratulationScreenSteps.propTypes = {
    slideData: PropTypes.array
};

export default PostBcVasCongratulationScreenSteps;
