import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { NUMBER } from "../../../constants/app-constants";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const Tooltip = ({
    customStyleHeading = false,
    children,
    heading,
    body,
    defaultTootlipStyle,
    onClickCallback = () => {},
    showComponent = false,
    displayComponent = <></>,
    displayComponentProp = {},
    trackEvents = () => {}
}) => {
    const targetRef = useRef(null);
    const tooltipRef = useRef(null);
    const [tootlipStyle, setTooltipStyle] = useState(defaultTootlipStyle || "styles.wrapper");
    const [toolTip, setTooltip] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                if (toolTip) {
                    trackEvents(false);
                    setTooltip(false);
                }
            }
        };
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolTip]);

    const getThePositionStyle = () => {
        const targetRect = targetRef.current.getBoundingClientRect();
        const targetDistanceFromBottom = window.innerHeight - targetRect.top;
        const tooltipHeight = (tooltipRef.current && tooltipRef.current.offsetHeight || NUMBER.TWO_HUNDRED) +
        targetRef.current.offsetHeight;
        if (!defaultTootlipStyle) {
            if (targetDistanceFromBottom > tooltipHeight) {
                setTooltipStyle("styles.bottom");
            } else {
                setTooltipStyle("styles.top");
            // tooltipRef.current.style.marginTop = `-${tooltipHeight + NUMBER.TEN}px`;
            }
        }
    };

    const onClick = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        await yieldToMain();
        setTooltip(!toolTip);
        getThePositionStyle();
        onClickCallback();
        trackEvents(!toolTip);
    };

    const renderPara = (line) => {
        if (React.isValidElement(line)) {
            return line;
        }
        return <p dangerouslySetInnerHTML={{__html: line}} />;
    };
    const Component = displayComponent;

    return (
        <div styleName="styles.tooltip">
            <span ref={targetRef} styleName={"styles.target"} tabIndex="0" onClick={onClick}>
                {children}
            </span>
            {toolTip && <div className="tooltipContainer" styleName={tootlipStyle} ref={tooltipRef} tabIndex="0">
                {!!heading && <div styleName={customStyleHeading ? "styles.customHeading" : "styles.heading"}>{heading}</div>}
                {!!body && <div styleName={"styles.body"}>{body.map((line) => renderPara(line))}</div>}
                <i />
                {showComponent && <Component {...displayComponentProp} />}
            </div>
            }
        </div>
    );
};

Tooltip.propTypes = {
    customStyleHeading: PropTypes.bool,
    children: PropTypes.object,
    heading: PropTypes.string,
    body: PropTypes.array,
    onClickCallback: PropTypes.func,
    defaultTootlipStyle: PropTypes.string,
    displayComponent: PropTypes.string,
    displayComponentProp: PropTypes.object,
    showComponent: PropTypes.bool,
    trackEvents: PropTypes.func
};

export default Tooltip;
