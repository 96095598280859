import React from "react";
import styles from "./styles.css";
import FestivalBannerImage from "./images/festival-banner-webapp.webp";
import SearchRevamp from "../search-revamp";
import { Helmet } from "react-helmet";
import textImage from "./images/text.webp";

const FestivalBanner = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={FestivalBannerImage} as={"image"} type="image/jpg" />
            </Helmet>
            <div styleName={"styles.wrapper"}>
                <img src={FestivalBannerImage} alt="Christmas banner" />
                <div styleName={"styles.contentWrapper"}>
                    <div className="container">
                        <div styleName={"styles.textWrap"}>
                            <img src={textImage} />
                        </div>
                        <div styleName={"styles.outer"}>
                            <SearchRevamp isHomePage={true} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FestivalBanner;
