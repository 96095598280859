/* eslint-disable complexity */
/* eslint-disable no-undef */
/* eslint-disable max-statements */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { initiateOrderPayment, getOrderDetail, fetchEgcPrice, updateLoanOffer, updateFinanceDetail, updateByoLoan, sessionInit, paymentInit, getStripePublicKey, getWarrantyPlans, postZeroDpPaymentApi } from "../checkout-info/actions";
import { getCheckoutPageURL, getMyBookingCarDetailsURL, getCarsalesCallbackURL, getGuestLoginPostPayURL, getTestDriveCheckoutURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import { DELIVERY_TYPES, ORDER_STATUS, PAYMENT_GATEWAY, PAYMENT_STATUS, PAYMENT_TYPE, GUEST_LOGN_POST_PAYMENT_JOURNEY } from "../../../constants/checkout-constants";
import { appUrl } from "../../../constants/url-constants";
import { useHistory, useLocation } from "react-router";
import styles from "./styles.css";
import Modal from "../../shared/modal";
import CircleTimerLoader from "../../shared/circle-timer-loader";
import { EXTENDEDWARRANTY, LOAN_PROVIDER, NUMBER, ORDER_TYPE } from "../../../constants/app-constants";
import { tasksRoutes } from "../../../constants/au.desktop/tasks-routes";
import { getAmountWithSurcharge, validateSplitPay } from "../../../utils/helpers/split-payment";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import CloseButton from "../../shared/icon-cross";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import { postPaymentRoutes } from "../../../constants/au.desktop/post-payment-routes";
import { setPaymentConfiguration } from "../adyen-payment/actions";
import { testDriveRoutes } from "../../../constants/au.desktop/test-drive-routes";
import usePricing from "../../../hooks/use-pricing";

const getSuccessText = ({ financeProvider, financeOpted, zeroDp }) => {
    if (zeroDp) {
        return "ZERO_DP";
    } else if (financeProvider === LOAN_PROVIDER.CARS24 && financeOpted) {
        return "FINANCE";
    }
    return "";
};

export default function payment(Component) {
    const Payment = (props) => {
        const {
            content,
            orderId,
            deliveryMode,
            financeProvider,
            deliveryInfo,
            isAffiliate,
            tradeinOpted = false,
            deliveryAddress,
            financeOpted,
            financeData = {},
            byoFinanceData,
            splitPayments = [],
            splitAmountDetails = {},
            imageConfig,
            promoCode = {},
            orderType = "",
            testDriveData = {},
            status = "",
            isUserZeroDpVariant,
            bookingConfirmDate,
            isGuestLogin,
            zeroDp,
            initiateOrderPayment: initiateOrderPaymentConnect,
            getOrderDetail: getOrderDetailConnect,
            fetchEgcPrice: fetchEgcPriceConnect,
            updateLoanOffer: updateLoanOfferConnect,
            updateFinanceDetail: updateFinanceDetailConnect,
            updateByoLoan: updateByoLoanConnect,
            paymentInit: paymentInitConnect,
            sessionInit: sessionInitConnect,
            getWarrantyPlans: getWarrantyPlansConnect,
            postZeroDpPaymentApi: postZeroDpPaymentApiConnect,
            setPaymentConfiguration: setPaymentConfigurationConnect
        } = props;

        const {pathname} = useLocation();
        const currentRoute = useRef();
        const { loan: { deposit = 0 } = {} } = financeData || {};
        const { isSplitPaymentOpted, selectedSplitAmount = 0, isFirstSplitPay } = splitAmountDetails;
        const [openChangeModal, setChangeModal] = useState(false);
        const [openSplitPaymentPopup, setopenSplitPaymentPopup] = useState(false);
        const history = useHistory();
        const { appointmentId } = content;
        const [showAppplePayModal, setShowAppplePayModal] = useState(false);

        const isDriva = financeProvider === LOAN_PROVIDER.DRIVA && financeOpted;
        const isInHouse = financeProvider === LOAN_PROVIDER.CARS24 && financeOpted;
        const isBYOF = financeProvider === LOAN_PROVIDER.BYOF && financeOpted;
        const { couponCode, isCouponApplied } = promoCode;

        const { deposit: byoDeposit } = byoFinanceData;
        const { dapOrTradePrice, tradeInPrice, totalDap, totalDapWithoutTradein } = usePricing();
        let finalPrice = (isDriva || isInHouse) ? deposit : dapOrTradePrice;
        finalPrice = isBYOF ? byoDeposit : finalPrice;
        finalPrice = isSplitPaymentOpted ? selectedSplitAmount : finalPrice;
        const { amountWithSurcharge } = getAmountWithSurcharge(finalPrice, PAYMENT_TYPE.CARD.value, isInHouse) || {};

        const { year, make, model, mainImage } = content || {};
        const carFullName = `${year || ""} ${make || ""} ${model || ""}`;
        const hasMainImage = mainImage && mainImage.path && imageConfig && imageConfig.imageHost ? true : false;
        const carImageUrl = hasMainImage
            ? fastlyUrl(imageConfig.imageHost, mainImage.path, imageTypes.auCarCheckoutThumbnail)
            : null;

        const redirectToHighTrade = async () => {
            //TODO: might need to handle route after first split payment in case of price change
            if (tradeinOpted) {
                const { relativeURL: backURL } = getCheckoutPageURL(content, checkoutRoutes.tradeInValuation.route);
                history.push(backURL);
            } else if (currentRoute.current) {
                const { relativeURL: backURL } = getCheckoutPageURL(content, currentRoute.current);
                history.push(backURL);
            } else if (isDriva || isInHouse) {
                await updateFinanceDetailConnect({ financeOpted: false, financeProvider: LOAN_PROVIDER.NONE });
                const { relativeURL: backURL } = getCheckoutPageURL(content, checkoutRoutes.paymentOptions.route);
                history.push(backURL);
            } else if (tradeInPrice.value > NUMBER.TEN_THOUSAND) {
                const { relativeURL: backURL } = getCheckoutPageURL(content, checkoutRoutes.paymentOptions.route);
                history.push(backURL);
            }
            // else if (splitPaymentOpted) {
            //     const {relativeURL: backURL} = getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.viewTask.route);
            //     history.push(backURL);
            // }
        };

        const onComplete = () => {
            setChangeModal(false);
            redirectToHighTrade();
        };

        const getSuccessUrl = () => {
            if (isGuestLogin) {
                return getGuestLoginPostPayURL(appointmentId, null, {
                    status: PAYMENT_STATUS.SUCCESS,
                    jtype: getSuccessText({ financeProvider, financeOpted, zeroDp })
                });
            } else if (financeProvider === LOAN_PROVIDER.BYOF || financeProvider === LOAN_PROVIDER.NONE && !bookingConfirmDate) {
                return getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.addOns.route,
                    { status: PAYMENT_STATUS.SUCCESS },
                    "VARIANT_B");
            } else {
                return getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.addOns.route, { status: PAYMENT_STATUS.SUCCESS });
            }
        };
        const getFailureUrl = (paymentMethodType, paymentGateway, cardType) => {
            if (status === ORDER_STATUS.CREATED) {
                return getCheckoutPageURL(content, checkoutRoutes.summary.route, { status: PAYMENT_STATUS.RETRY, type: paymentMethodType, gateway: paymentGateway, cardType });
            }
            return getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.orderPaymentSummary.route, { status: PAYMENT_STATUS.RETRY, type: paymentMethodType, gateway: paymentGateway, cardType });
        };
        const getBankTransferUrl = () => {
            if (ORDER_STATUS.RESERVED === status) {
                const { relativeURL } =   getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.bankTransferDetails.route);
                history.push(relativeURL);
            } else {
                const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.bankTransferDetails.route);
                history.push(relativeURL);
            }
        };

        const getCancelUrl = () => {
            if (status === ORDER_STATUS.CREATED) {
                return getCheckoutPageURL(content, checkoutRoutes.summary.route, { status: PAYMENT_STATUS.CANCEL });
            } else {
                return getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.viewTask.route, { status: PAYMENT_STATUS.CANCEL });
            }
        };

        const getPaymentUrl = (params) => {
            if (isSplitPaymentOpted && pathname.indexOf("my-bookings") >-1) {
                return getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.payment.route, params);
            }
            if (pathname.indexOf("test-drive") >-1) {
                return getTestDriveCheckoutURL(content, testDriveRoutes.payment.route, params);
            }
            return getCheckoutPageURL(content, checkoutRoutes.payment.route, params);
        };

        const handleInitiatePayment = ({ paymentProps = {}, redirectionUrls = {}, callbacks = {} }) => {
            const { paymentRetry, paymentMethodType = "", paymentGateway = "", isWallet, walletName, cardType } = paymentProps;
            const {
                prePayment = () => { },
                paymentInitiated = () => { },
                onRequestCallBackClick = () => { },
                paymentFailed = () => { },
                onApiProcessed = () => {}
            } = callbacks;
            const { SUCCESS_URL, CANCEL_URL, FAILURE_URL } = redirectionUrls;
            const { deliveryDate, deliveryInfoError, pickUpDate } = deliveryInfo;
            // if delivery address not found
            const deliveryOrPickupAvailable = deliveryMode !== DELIVERY_TYPES.PickUp ? !deliveryDate : !pickUpDate;
            if (orderType !== ORDER_TYPE.TEST_DRIVE && (!paymentRetry && (deliveryOrPickupAvailable || deliveryInfoError))) {
                onRequestCallBackClick();
            } else {
                prePayment();
                const { absoluteURL: DEFAULT_SUCCESS_URL } = getSuccessUrl();
                const { absoluteURL: DEFAULT_CANCEL_URL } = getCancelUrl();
                const { absoluteURL: DEFAULT_FAILURE_URL } = getFailureUrl(paymentMethodType, paymentGateway, cardType);

                const payload = {
                    homepageUrl: `${appUrl()}/`,
                    paymentGateway,
                    paymentMethodType,
                    tradeinOpted,
                    cardType,
                    isWallet,
                    couponOpted: false,
                    orderType,
                    financeOpted,
                    financeProvider,
                    "successUrl": SUCCESS_URL || DEFAULT_SUCCESS_URL,
                    "cancelUrl": CANCEL_URL || DEFAULT_CANCEL_URL,
                    "failureUrl": FAILURE_URL || DEFAULT_FAILURE_URL
                };
                if (isWallet) {
                    payload.walletName = walletName;
                }
                if (couponCode && isCouponApplied && isFirstSplitPay) {
                    payload.couponCode = couponCode;
                    payload.couponOpted = true;
                }
                // const isUserFirstSplitPayment = splitPayments.filter(d => d.paymentStatus === PAYMENT_STATUS.CREATED);
                const finalChargeAmount = getAmountWithSurcharge(finalPrice, paymentMethodType, isInHouse) || {};

                if (isAffiliate && !isWallet && paymentGateway === PAYMENT_GATEWAY.adeyn) {
                    payload.cancelUrl = getCarsalesCallbackURL();
                }
                initiateOrderPaymentConnect(orderId, payload, finalChargeAmount.amountWithSurcharge).then(async (response) => {
                    await paymentInitiated();
                    await getOrderDetailConnect(appointmentId);
                    paymentInitConnect(false);
                    if (response.amount === 0 && response.paymentStatus === PAYMENT_STATUS.SUCCESS) {
                        window.location.href = DEFAULT_SUCCESS_URL;
                        // history.push(relativeURL);
                    } else if (PAYMENT_TYPE.BANK_TRANSFER.value === paymentMethodType) {
                        getBankTransferUrl();
                    } else  if (paymentGateway === PAYMENT_GATEWAY.poli) {
                        if (response.success) {
                            window.location.href = response.navigationURL;
                        } else {
                            window.location.href = FAILURE_URL || DEFAULT_FAILURE_URL;
                        }
                    } else {
                        setPaymentConfigurationConnect({
                            ...response,
                            successUrl: payload.successUrl,
                            cancelUrl: payload.cancelUrl,
                            failureUrl: `${payload.failureUrl}&id=${response.sessionId}`
                        });
                        const { sessionId = "", sessionData = "" } = response;
                        sessionInitConnect(sessionData);
                        const {relativeURL} = getPaymentUrl({ id: sessionId, cardType });
                        history.push(relativeURL);
                    }
                }).catch(() => {
                    paymentFailed();
                    paymentInitConnect(false);
                    // setisLoading(false);
                }).finally(() => {
                    onApiProcessed();
                });
            }
        };

        const handlePayment = async (paymentArgument) => {
            try {
                const { paymentProps = {}, redirectionUrls = {}, callbacks } = paymentArgument;
                const { paymentMethodType = "", paymentGateway = "" } = paymentProps;
                const {
                    prePayment = () => { },
                    paymentInitiated = () => { },
                    paymentFailed = () => { }
                } = callbacks;
                const { SUCCESS_URL, FAILURE_URL } = redirectionUrls;
                paymentInitConnect(true);
                if (status !== ORDER_STATUS.CREATED) {
                    handleInitiatePayment(paymentArgument);
                    return;
                }

                const { totalAmountPaid: oldTotalAmount } = validateSplitPay({ splitPayments });
                const response = await getOrderDetailConnect(appointmentId);
                const { totalAmountPaid: newTotalAmount } = validateSplitPay({ splitPayments: response.splitPayments });
                const { amount: testDriveAmountPaid } = testDriveData || {};

                const warrantyResponse = await getWarrantyPlansConnect();
                const { extendedWarranty: warranty = {} } = warrantyResponse || {};
                const preSelectedPlan = response.chargeDetails.find(item => item.key === EXTENDEDWARRANTY);
                const selectedPlan = (warranty && warranty.plans || [])
                    .find(item => item.key === (preSelectedPlan && preSelectedPlan.name) && item.amount !== (preSelectedPlan && preSelectedPlan.amount));

                if (selectedPlan) {
                    setChangeModal(true);
                    currentRoute.current = checkoutRoutes.carCover.route;
                    return;
                }
                // if (response.vehiclePrice !== egcData[0].egc) {
                if (isUserZeroDpVariant && isInHouse && deposit === 0) {
                    const query = { status: PAYMENT_STATUS.SUCCESS, type: ZERO_DP_VARIANTS.ZERO_DP, jtype: ZERO_DP_VARIANTS.ZERO_DP, mode: GUEST_LOGN_POST_PAYMENT_JOURNEY.FINANCE_ZERO };
                    let successURL;
                    if (isGuestLogin) {
                        successURL = getGuestLoginPostPayURL(appointmentId, "verify-user", query);
                    } else {
                        successURL = getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route, query);
                    }
                    const { absoluteURL: DEFAULT_FAILURE_URL } = getCheckoutPageURL(content, checkoutRoutes.summary.route, { status: PAYMENT_STATUS.RETRY, type: paymentMethodType, gateway: paymentGateway });
                    try {
                        prePayment();
                        await postZeroDpPaymentApiConnect();
                        paymentInitiated();
                        window.location.href = SUCCESS_URL || successURL.absoluteURL;
                    } catch (e) {
                        paymentFailed();
                        window.location.href = FAILURE_URL || DEFAULT_FAILURE_URL;
                    }
                } else if (orderType !== ORDER_TYPE.TEST_DRIVE) {
                    if ((Math.round(response.totalAmount) - (testDriveAmountPaid || 0)) !== Math.round(totalDapWithoutTradein.value)) {
                        // todo:Deepak what this breaks
                        await fetchEgcPriceConnect(appointmentId, deliveryAddress.state);
                        const driveAwayPrice = tradeinOpted ? tradeInPrice.value : totalDap.value;
                        // const amount = (tradeinOpted ? tradeInPrice.value : totalDap.value) - deposit;
                        const amount = totalDap.value - deposit;
                        if (isDriva || isInHouse) {
                            await updateLoanOfferConnect({ ...financeData, driveAwayPrice, loan: { ...financeData.loan, amount } });
                        } else if (isBYOF) {
                            const { lenderName, deposit: depositValue } = byoFinanceData;
                            await updateByoLoanConnect({ lenderName, deposit: depositValue, orderId, appointmentId, driveAwayPrice });
                        }
                        setChangeModal(true);
                    } else if (oldTotalAmount !== newTotalAmount) {
                        // todo:Deepak test this
                        setChangeModal(true);
                        setopenSplitPaymentPopup(true);
                    } else {
                        handleInitiatePayment(paymentArgument);
                    }
                } else {
                    handleInitiatePayment(paymentArgument);
                }

            } catch (error) {
                setChangeModal(false);
            }
        };

        return (
            <React.Fragment>
                <Component handlePayment={handlePayment}  {...props}  canMakePayment={{
                    applePay: true,
                    googlePay: true
                }}/>
                {openChangeModal &&
                    <Modal isOpen={true}>
                        <div styleName="styles.modalWrappers">
                            <p styleName="styles.modalTexts">
                                {openSplitPaymentPopup ? `The payment for this order has been updated` : `It looks like this car's price has been updated.
                            Please give us a few seconds while we refresh the latest price!`}</p>
                            <CircleTimerLoader
                                interval={5}
                                size="large"
                                onComplete={onComplete}
                            />
                        </div>
                    </Modal>
                }
                <Modal
                    isOpen={showAppplePayModal}
                    close={() => setShowAppplePayModal(false)}
                    contentClass="modalActionContentDesktop"
                >
                    <div styleName={"styles.closeButton"}>
                        <CloseButton type="grey" />
                    </div>
                    {/* <div styleName={"styles.carImage"}> */}
                    <img src={carImageUrl} alt="imageUrl" styleName={"styles.carImage"} />
                    {/* </div> */}
                    <p styleName={"styles.carName"}>{carFullName}</p>
                    <p styleName={"styles.priceStyle"}>{makePriceLabelRound(amountWithSurcharge)}</p>
                    {(isBYOF || isDriva || isInHouse) && <p styleName={"styles.downPayment"}>(Downpayment)</p>}
                    {/* <div styleName={"styles.applePay"}>
                        <img src={AppleIcon} />
                        <button onClick={() => paymentRequest.show()}>
                            Pay
                        </button>
                    </div> */}
                </Modal>
            </React.Fragment>
        );
    };

    Payment.propTypes = {
        tradeInData: PropTypes.object,
        isTradeOpted: PropTypes.bool,
        egcData: PropTypes.array,
        deliveryInfo: PropTypes.object,
        content: PropTypes.object,
        orderId: PropTypes.object,
        tradeinOpted: PropTypes.bool,
        initiateOrderPayment: PropTypes.func,
        getOrderDetail: PropTypes.func,
        deliveryAddress: PropTypes.object,
        fetchEgcPrice: PropTypes.func,
        financeOpted: PropTypes.bool,
        financeData: PropTypes.object,
        updateLoanOffer: PropTypes.func,
        updateFinanceDetail: PropTypes.func,
        financeProvider: PropTypes.string,
        byoFinanceData: PropTypes.object,
        updateByoLoan: PropTypes.func,
        splitPaymentOpted: PropTypes.bool,
        deliveryMode: PropTypes.bool,
        splitPayments: PropTypes.array,
        splitAmountDetails: PropTypes.object,
        paymentInit: PropTypes.func,
        getStripePublicKey: PropTypes.func,
        imageConfig: PropTypes.object,
        couponCode: PropTypes.string,
        promoCode: PropTypes.object,
        chargeDetails: PropTypes.array,
        isAffiliate: PropTypes.bool,
        isUserZeroDpVariant: PropTypes.bool,
        orderType: PropTypes.string,
        testDriveData: PropTypes.object,
        getWarrantyPlans: PropTypes.func,
        postZeroDpPaymentApi: PropTypes.func,
        status: PropTypes.string,
        bookingConfirmDate: PropTypes.string,
        isGuestLogin: PropTypes.bool,
        zeroDp: PropTypes.bool,
        setPaymentConfiguration: PropTypes.func,
        sessionInit: PropTypes.string
    };

    const mapStateToProps = ({
        carDetails: { content, config },
        checkout: {
            order: {
                deliveryAddress,
                chargeDetails,
                orderId,
                financeOpted,
                financeProvider,
                splitPaymentOpted,
                splitPayments,
                deliveryMode,
                orderType,
                status,
                bookingConfirmDate,
                zeroDp
            },
            financeData,
            egcData,
            tradeInData,
            tradeinOpted,
            byoFinanceData,
            splitAmountDetails,
            promoCode,
            testDriveData

        },
        user: { isAffiliate, isUserZeroDpVariant, isGuestLogin },
        deliveryInfo
    }) => {
        return {
            content,
            orderId,
            financeOpted,
            imageConfig: config,
            deliveryInfo,
            deliveryAddress,
            chargeDetails,
            financeData,
            egcData,
            tradeInData,
            tradeinOpted,
            byoFinanceData,
            financeProvider,
            splitPaymentOpted,
            splitPayments,
            splitAmountDetails,
            deliveryMode,
            promoCode,
            isAffiliate,
            orderType,
            testDriveData,
            status,
            isUserZeroDpVariant,
            bookingConfirmDate,
            isGuestLogin,
            zeroDp
        };

    };

    const mapDispatchToProps = {
        initiateOrderPayment,
        updateLoanOffer,
        getOrderDetail,
        fetchEgcPrice,
        updateFinanceDetail,
        updateByoLoan,
        paymentInit,
        sessionInit,
        getStripePublicKey,
        // updateCheckoutSteps,
        getWarrantyPlans,
        postZeroDpPaymentApi,
        setPaymentConfiguration
    };

    return connect(mapStateToProps, mapDispatchToProps)(Payment);
}
