/* eslint-disable complexity */
/* eslint-disable no-debugger */
import { createReducer } from "reduxsauce";
import Types from "./types";
import { COUNTRY_CODE, COUPON_STATUS, CURRENCY, VERIFY_LICENCE_SCREEN_TYPES } from "../../../constants/app-constants";
import { DRIVA_POST_OFFER_SCREENS, FINANCE_CYCLE, FINANCE_TYPE, VEHICLE_STATE, PAYMENT_MODE } from "../../../constants/checkout-constants";
import { DRIVA_MIN_AMOUNT, ZERO_DP_MINIMUM_DEPOSIT } from "../../../config/driva-config";
import { DEFAULT_FINANCE_TYPE } from "../../../config/finance-config";
import { isCallbackRequestExpired } from "../../../utils/helpers/finance-util";
import { LOAN_OFFER_SCREENS } from "../../../constants/loan-offers";

export const ORDER_INITIAL_ADDRESS = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    customerName: null,
    customerPhoneNumber: null,
    landmark: null,
    latitude: null,
    longitude: null,
    mapLink: null,
    mapTxt: null,
    pincode: null,
    state: null
};

const TEST_DRIVE_INITIAL_STATE = {
    date: "",
    slotKey: "",
    to: "",
    from: "",
    hubAddress: "",
    status: "",
    reschedule: ""
};

const ORDER_INITIAL_STATE = {
    bookingAmount: 0,
    bookingConfirmDate: null,
    chargeDetails: null,
    country: COUNTRY_CODE.AU,
    createdDate: null,
    currency: CURRENCY.AU,
    deliveryAddress: null,
    deliveryTime: null,
    deliveryMode: null,
    financeOpted: true,
    financeTerms: null,
    id: "",
    orderId: "",
    pickupStoreId: null,
    pickupTime: null,
    status: null,
    storeId: "",
    totalAmount: 0,
    userId: "",
    vehiclePrice: 0,
    vehicleType: "car",
    testDriveDetails: {...TEST_DRIVE_INITIAL_STATE},
    testDriveStatus: null,
    tradeinOpted: null
};

export const FINANCE_ADDRESS_INITIAL_STATE = {
    durationMonths: null,
    durationYears: null,
    full: "",
    postCode: "",
    state: "",
    street: "",
    streetNumber: "",
    suburb: "",
    unit: 0
};

const FINANCE_EXTRA = {
    vehicleMake: "",
    vehicleModel: "",
    vehicleState: VEHICLE_STATE.USED,
    vehicleYear: 0
};

const FINANCE_PERSONAL_DETAILS_INITIAL_STATE = {
    mobile: "",     //mobile
    email: "",
    firstName: "",  //firstName
    lastName: "",   //lastName
    middleName: "", //middleName
    dateOfBirth: "", //dateOfBirth
    residency: "", //residency
    visaNumber: "",
    livingSituation: null, //livingSituation
    address: { ...FINANCE_ADDRESS_INITIAL_STATE },
    previousAddress: {}, // previousAddress
    employment: [] //employment
};

const TRADE_IN_INITIAL_STATE = {};
const BYO_FINANCE_INITIAL_STATE = {
    lenderName: "",
    deposit: null,
    amount: null,
    isGetByoFinanceLoading: false,
    isUpdateByoFinanceLoading: false,
    isGetByoFinanceError: false,
    isUpdateByoFinanceError: false
};

const PROMO_CODE_INITIAL_STATE = {
    discountPrice: 0,
    finalPrice: 0,
    status: "",
    message: ""
};

const CHECKOUT_STEPS_INITAL_STATE = {
    step: {
        basic: "pending",
        delivery: "pending",
        tradein: "pending",
        payment: "pending",
        checkout: "pending",
        carCover: "pending"
    }
};

const CALLBACK_DETAILS_INITIAL_STATE = {
    requested: false,
    expiry: null,
    source: null,
    isExpired: true
};

const INITIAL_FINANCE_DATA = {
    submitted: false,
    selectedQuote: null,
    appointmentId: null,
    financeType: FINANCE_TYPE.CARS24, // one of drivaFinance or approvedLoan
    user: {
        ...FINANCE_PERSONAL_DETAILS_INITIAL_STATE
    },
    loan: {
        amount: 0, // loan = price - deposit
        deposit: DRIVA_MIN_AMOUNT,
        term: 0, // loan years
        balloon: 0 // in percents
    },
    loanType: {
        isPurposeBusiness: "NA",
        isAbnRegistered: "NA",
        isGstRegistered: "NA",
        businessYears: null
    },
    // creditScore is used for dev purpose only, to replicate loan errors, and should not be a part prod payload
    // creditScore: {
    //     equifaxScore: 750,
    //     vedaScore: 700
    // },
    extra: {
        ...FINANCE_EXTRA
    },
    selectedCycle: FINANCE_CYCLE.WEEKLY,
    preApproveLoanDetails: {
        lenderName: "",
        make: "",
        model: "",
        user: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            phone: ""
        }
    }
};

const DRIVING_LICENCE_INITIAL_STATE = {
    state: "",
    number: "",
    type: "",
    frontUrl: "",
    backUrl: "",
    status: ""
};

const CAR_RESERVED_MODAL_INITIAL_STATE = {
    show: false,
    appointmentId: null,
    make: "",
    modal: "",
    heading: "",
    bodyText: "",
    redirectUrl: ""
};

export const INITIAL_STATE = {
    personalDetails: {
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        whatsappOptIn: true,
        userPurpose: {
            purpose: ""
        }
        // ...userReducer.address
    },

    order: {
        ...ORDER_INITIAL_STATE
    },
    byoFinanceData: {
        ...BYO_FINANCE_INITIAL_STATE
    },
    /* Driva finance data */
    financeData: INITIAL_FINANCE_DATA,
    readOnlyselectedQuote: null,
    readOnlyFinanceData: {},
    oldDepositAmount: 0,
    financeQuotes: null, // list of loan offers,
    topQuotes: null,
    quote: null,
    quotes: null,
    otherQuotes: null,
    financePostOfferCurrentScreen: DRIVA_POST_OFFER_SCREENS.OFFER_LIST_SCREEN,
    previousStep: "", // route
    currentStep: "",  // route
    nextStep: "",     // route
    maxStep: "",      // route
    prevTabIndex: 0,

    deliveryMode: "", // HOME or SELF_PICKUP
    updateDeliveryError: null,
    checkoutInitialStep: "",
    createOrderError: null,
    orderDetailError: null,
    isOrderDetailLoading: true,
    isGetOrderDetailLoading: true,

    isFinanceDataLoading: true,
    isGetFinanceDataLoading: true,
    financeDataError: null, // also used to show generic error screen with no lenders
    isFinanceDataPosted: false,
    isGetFinanceDataError: null,
    financeErrorResponse: null, // in case of 200 from driva api
    isCreateFinanceDataError: null,
    isUpdateFinanceDataError: null,
    orderAvailable: null,
    quotesAvailable: null,
    pollStatus: null,

    recalculateTriggered: false,
    isEgcDataLoading: false,
    egcData: [],
    allStatesPrice: [],
    tradeinOpted: null,
    tradeInData: TRADE_IN_INITIAL_STATE,
    valuationTags: [],
    priceComparison: {},
    makeModelFlow: {
        isFetchingOrderDetails: false,
        currentStep: {},
        maxStep: {},
        mmvyUserInputs: {
            screenStep: null,
            makeId: null,
            modelId: null,
            year: null,
            fuelType: null,
            bodyType: null,
            transmissionType: null,
            driveType: null,
            variant: null
        },
        rejectionReasonInfo: {
            show: false,
            type: null
        }
    },
    cancelationReasons: [],
    isTradeInDataLoading: true,
    isGetTradeInDataLoading: true,
    isGetTradeInDataError: null,
    isCreateTradeInDataError: null,
    isUpdateTradeInDataError: null,
    tradeInStates: [],
    tradeInCarDetails: {},
    tradeInCarVariants: {},
    isCarDataLoading: false,
    isCarVariantLoading: false,
    checkoutSourcePathname: "",
    carTradeInRegoVin: {},
    carTradeInCarMakeModelId: {},
    splitAmountDetails: {
        selectedSplitAmount: 0,
        totalAmountPaid: 0,
        remainingAmount: 0
    },
    splitPaymentUserState: {
        selectePaymentMode: "",
        selectedRange: {min: 0, max: 0},
        selectedSliderValue: 0
    },
    isPaymentHistoryFetched: false,
    paymentHistoryData: [],
    paymentHistoryError: null,
    signContractData: [],
    verificationData: {},
    isGetSignContractFetched: false,
    signContractDataError: null,
    isPostSignContractFetched: true,
    isVerificationStatusFetched: true,
    signContractPollCompleted: false,
    verifyIdCompleted: false,
    adyenFailureData: {},
    isGetAdyenFailureFetched: false,
    adyenFailureDataError: null,
    adyenFailurePollCompleted: false,
    paymentProcessStarted: false,
    sessionData: "",
    currentDeliveryStep: null,
    externalApplicationStatus: null,
    tradeVehicle: TRADE_IN_INITIAL_STATE,
    checkoutStepsData: CHECKOUT_STEPS_INITAL_STATE,
    promoCode: PROMO_CODE_INITIAL_STATE,
    extendedWarranty: {},
    isManualSales: false,
    carDeliverySlots: {},
    testDriveStepsData: {
        step: {
            basic: "pending",
            delivery: "pending"
        }
    },
    bookingSummary: {},
    testDriveData: {},
    inHouseFinanceEligibility: {data: DEFAULT_FINANCE_TYPE.inHouseEligible, error: ""},
    inHouseFinanceEligibilityFetched: true,
    isGetValidQuotesLoading: false,
    requestForWarrantyCallback: null,
    callbackDetails: CALLBACK_DETAILS_INITIAL_STATE,
    byofCallbackDetails: CALLBACK_DETAILS_INITIAL_STATE,
    checkoutCallbackRequestLoading: false,
    checkoutCallbackError: null,
    showZeroDpSelectOfferCoachMark: true,
    reBookedOrder: {},
    isReBookOrderDetailLoading: false,
    reBookorderDetailError: false,
    zeroDpRebookOrder: null,
    paymentPayload: null,
    isGetZeroDpTextDataConfigLoading: false,
    zeroDpTextConfig: {},
    zeroDpTextConfigError: null,
    leastWeeklyRepayment: null,
    loanOfferFAQMetadata: {
        loading: false,
        error: null,
        faqs: {}
    },
    // TODO: REVIST THIS STATE AS IT MIGHT NOT BE NEEDED AFTER VAS STORE
    windowTinting: {
        loading: false,
        error: null,
        addLoading: false,
        addError: null,
        removeLoading: false,
        removeError: null,
        appointmentId: "",
        tintingPlans: [],
        interestRate: 0
    },
    checkoutEditCompletedStages: [],
    loanOfferScreen: LOAN_OFFER_SCREENS.LOADER,
    checkoutEditFormData: {
        user: {
            ...FINANCE_PERSONAL_DETAILS_INITIAL_STATE
        },
        loan: {
            amount: 0, // loan = price - deposit
            deposit: DRIVA_MIN_AMOUNT,
            term: 0, // loan years
            balloon: 0 // in percents
        },
        loanType: {
            isPurposeBusiness: "NA",
            isAbnRegistered: "NA",
            isGstRegistered: "NA",
            businessYears: null
        }
    },
    checkoutEditFormExitModal: false,
    checkoutEditQuestionsUpdateModal: false,
    checkoutEditQuestionsUpdateModalType: "",
    checkoutEditQuestionsUpdateModalViewed: false,
    checkoutEditMode: false,
    checkoutPreApprovalStep: {
        stage: 0,
        form: 0
    },
    tradeinVariantList: null,
    bankDetails: {},
    selectOfferScreenLoader: true,
    checkoutSummaryFAQData: {
        loading: false,
        error: null,
        faqs: []
    },
    stEligibleData: {
        loading: false,
        isStEligible: false
    },
    drivingLicenceData: {
        loading: false,
        error: null,
        screenType: VERIFY_LICENCE_SCREEN_TYPES.INTRO,
        ...DRIVING_LICENCE_INITIAL_STATE
    },
    carReservedModal: CAR_RESERVED_MODAL_INITIAL_STATE,
    showInterstateTransferModal: false
};

export const populateCheckoutPersonalData = (state = INITIAL_STATE, { userData }) => {
    const {firstName, middleName, lastName, profileMobile, email, whatsappOptIn, userEmail} = userData;
    const personalDetails = {
        firstName,
        middleName,
        lastName,
        phone: profileMobile,
        userEmail: email || userEmail,
        whatsappOptIn: whatsappOptIn ? whatsappOptIn : true
    };

    return {
        ...state,
        personalDetails: {
            ...state.personalDetails, ...personalDetails
        }
    };
};

export const populateEmailFromToken = (state = INITIAL_STATE, {userData}) => {
    const { email } = userData;
    const personalDetails = {
        userEmail: email
    };
    return {
        ...state,
        personalDetails: {
            ...state.personalDetails, ...personalDetails
        }
    };
};

export const setUserPersonalDetails = (state = INITIAL_STATE, { personalDetails }) => {
    return {
        ...state,
        personalDetails: {
            ...state.personalDetails, ...personalDetails
        }
    };
};

export const setDeliveryMode = (state = INITIAL_STATE, { deliveryMode }) => {
    return {
        ...state,
        deliveryMode
    };
};

export const setCheckoutLoginType = (state = INITIAL_STATE, { checkoutType }) => {
    return {
        ...state,
        checkoutLoginType: checkoutType
    };
};

export const setFinancingScreen = (state = INITIAL_STATE, { activeScreen }) => {
    return {
        ...state,
        financeData: { ...state.financeData, activeScreen}
    };
};

export const setLoanDetails = (state = INITIAL_STATE, { newData }) => {
    if (state.checkoutEditMode) {
        return {
            ...state,
            checkoutEditFormData: {
                ...state.checkoutEditFormData,
                loan: { ...state.checkoutEditFormData.loan, ...newData }
            }
        };
    }

    return {
        ...state,
        financeData: { ...state.financeData, loan: { ...state.financeData.loan, ...newData } }
    };
};

export const setLoanType = (state = INITIAL_STATE, { newData }) => {
    if (state.checkoutEditMode) {
        return {
            ...state,
            checkoutEditFormData: {
                ...state.checkoutEditFormData,
                loanType: {
                    ...state.checkoutEditFormData.loanType,
                    ...newData
                }
            }
        };
    }
    return {
        ...state,
        financeData: { ...state.financeData, loanType: { ...state.financeData.loanType, ...newData } }
    };
};

export const setFinanceSelectedCycle = (state = INITIAL_STATE, { newData }) => {
    return {
        ...state,
        financeData: { ...state.financeData, selectedCycle: newData }
    };
};

export const setPostFinanceScreen = (state = INITIAL_STATE, { newData }) => {
    return {
        ...state,
        financePostOfferCurrentScreen: newData
    };
};

export const setFinancePersonalDetails = (state = INITIAL_STATE, { data }) => {
    if (state.checkoutEditMode) {
        return {
            ...state,
            checkoutEditFormData: {
                ...state.checkoutEditFormData,
                user: {
                    ...state.checkoutEditFormData.user,
                    ...data
                }
            }
        };
    }

    return {
        ...state,
        financeData: {
            ...state.financeData,
            user: {
                ...state.financeData.user,
                ...data
            }
        }
    };
};

export const updateDeliveryModeSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: {...data, testDriveStatus: state.order?.testDriveStatus},
        isOrderDetailLoading: false,
        orderDetailError: null
    };
};

export const updateDeliveryModeFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        updateDeliveryError: error
    };
};

export const createOrderRequest = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isOrderDetailLoading: isLoading,
        isFinanceDataLoading: true
    };
};

export const createOrderSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: data,
        isOrderDetailLoading: false,
        orderDetailError: null,
        createOrderError: null
    };
};

export const createOrderFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        createOrderError: error,
        isOrderDetailLoading: false,
        isFinanceDataLoading: false
    };
};

export const getOrderDetailInit = (state = INITIAL_STATE, {isLoading}) => {
    return {
        ...state,
        isOrderDetailLoading: isLoading,
        isGetOrderDetailLoading: isLoading,
        isGetFinanceDataLoading: true,
        isFinanceDataLoading: true
    };
};

export const getOrderDetailSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: data,
        financeData: {
            ...state.financeData,
            appointmentId: data.appointmentId
        },
        isOrderDetailLoading: false,
        isGetOrderDetailLoading: false,
        isGetFinanceDataLoading: false,
        orderDetailError: null,
        isFinanceDataLoading: false,
        isManualSales: data.paymentMode === PAYMENT_MODE.OFFLINE
    };
};

export const getOrderDetailFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        order: {...ORDER_INITIAL_STATE},
        isOrderDetailLoading: false,
        isGetOrderDetailLoading: false,
        isGetFinanceDataLoading: true,
        orderDetailError: error
    };
};

export const getLoanOfferDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: true,
        isGetFinanceDataLoading: true,
        financeDataError: null,
        isGetFinanceDataError: null,
        financeErrorResponse: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

const getLoanOfferFAQRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        loanOfferFAQMetadata: {
            ...state.loanOfferFAQMetadata,
            loading: true
        }
    };
};

const getLoanOfferFAQSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        loanOfferFAQMetadata: {
            ...state.loanOfferFAQMetadata,
            loading: false,
            faqs: data
        }
    };
};

const getLoanOfferFAQFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        loanOfferFAQMetadata: {
            ...state.loanOfferFAQMetadata,
            loading: false,
            faqs: {},
            error: error.message || "Something went wrong"
        }
    };
};

const setCheckoutEditMode = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutEditMode: data
    };
};

const setCheckoutEditCompletedStages = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutEditCompletedStages: data
    };
};

const setLoanOfferScreen = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        loanOfferScreen: data
    };
};

const setCheckoutEditFormExitModal = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutEditFormExitModal: data
    };
};

const setCheckoutEditFormData = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutEditFormData: data
    };
};

const setCheckoutEditQuestionsUpdateModal = (state = INITIAL_STATE, { data, viewed = false, modalType }) => {
    return {
        ...state,
        checkoutEditQuestionsUpdateModal: data,
        checkoutEditQuestionsUpdateModalViewed: viewed,
        checkoutEditQuestionsUpdateModalType: modalType
    };
};

export const setSelectedQuote = (state = INITIAL_STATE, { data = {}}) => {
    return {
        ...state,
        financeData: {
            ...state.financeData,
            selectedQuote: data.selectedQuote
        },
        readOnlyselectedQuote: data.selectedQuote,
        oldDepositAmount: data.oldDepositAmount
    };
};

const retainSelectedQuotes = (data, state) => {
    const storedSelectedQuote = state.readOnlyselectedQuote;
    if (!data.selectedQuote && storedSelectedQuote) {
        const totalQuotes =  (data.otherQuotes || data.topQuotes) ? [...(data.otherQuotes || []), ...(data.topQuotes || [])] : data.quotes;
        const selectedQuote = (totalQuotes || []).filter((value) => value.displayName === storedSelectedQuote.displayName);
        if (Array.isArray(selectedQuote) && selectedQuote.length) {
            return selectedQuote[0];
        } else  if (!data.errorResponse && !totalQuotes) {
            return storedSelectedQuote;
        } else {
            return null;
        }
    }
    return data.selectedQuote;
};

const updateChargeDetails = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: {
            ...state.order,
            chargeDetails: data
        }
    };
};

export const getLoanOfferDetailsSuccess = (state = INITIAL_STATE, { data = {}, isInHouseFinance, isUserZeroDpVariant }) => {
    return {
        ...state,
        // ...(isInHouseFinance && {isFinanceDataPosted: true}),
        financeData: isInHouseFinance ?
            {   ...data.loanOfferRequest,
                selectedQuote: retainSelectedQuotes(data, state),
                loan: data.loanOfferRequest && data.loanOfferRequest.loan ? data.loanOfferRequest.loan :  {
                    ...state.financeData.loan,
                    deposit: isUserZeroDpVariant ? ZERO_DP_MINIMUM_DEPOSIT : DRIVA_MIN_AMOUNT
                },
                user: data.loanOfferRequest && data.loanOfferRequest.user ? data.loanOfferRequest.user :  state.financeData.user,
                loanType: data.loanOfferRequest && data.loanOfferRequest.loanType ? data.loanOfferRequest.loanType :  state.financeData.loanType,
                extra: data.loanOfferRequest && data.loanOfferRequest.extra ? data.loanOfferRequest.extra :  state.financeData.extra
            } : {
                ...data.loanOfferRequest
            },
        // financeData: data.loanOfferRequest,
        leastWeeklyRepayment: data.leastWeeklyRepayment,
        checkoutEditFormData: {
            ...data.loanOfferRequest,
            user: data.loanOfferRequest.user || state.checkoutEditFormData.user,
            loan: data.loanOfferRequest.loan || state.checkoutEditFormData.loan,
            loanType: data.loanOfferRequest.loanType || state.checkoutEditFormData.loanType
        },
        readOnlyFinanceData: data.loanOfferRequest,
        oldDepositAmount: data.loanOfferRequest && data.loanOfferRequest.loan && data.loanOfferRequest.loan.deposit,
        externalApplicationStatus: data.externalApplicationStatus,
        financeQuotes: (data.otherQuotes || data.topQuotes) ? [...(data.otherQuotes || []), ...(data.topQuotes || [])] : data.quotes,
        topQuotes: data.topQuotes,
        quote: (data || {}).quote || {},
        quotes: (data || {}).quotes || [],
        otherQuotes: data.otherQuotes,
        financeErrorResponse: data.errorResponse,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        pollStatus: data.pollStatus,
        zeroDpRebookOrder: data.zeroDpRebookOrder,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null,
        errorCode: data.errorCode
    };
};

export const getEmptyLoanOfferDetailsSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const getLoanOfferDetailsFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        isGetFinanceDataLoading: false,
        financeErrorResponse: null,
        financeDataError: error,
        isGetFinanceDataError: true,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const createLoanOfferRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: true,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null,
        financeErrorResponse: null
    };
};

export const createLoanOfferSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        financeData: {
            ...data.loanOfferRequest,
            selectedQuote: data.selectedQuote
        },
        financeQuotes: (data.otherQuotes || data.topQuotes) ? [...(data.otherQuotes || []), ...(data.topQuotes || [])] : data.quotes,
        oldDepositAmount: data.loanOfferRequest && data.loanOfferRequest.loan && data.loanOfferRequest.loan.deposit,
        topQuotes: data.topQuotes,
        otherQuotes: data.otherQuotes,
        financeErrorResponse: data.errorResponse,
        isFinanceDataPosted: true,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        pollStatus: data.pollStatus,
        isFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null

    };
};

export const createLoanOfferFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        financeDataError: error,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: true,
        isUpdateFinanceDataError: null,
        financeErrorResponse: null

    };
};

export const updateLoanOfferRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isFinanceDataLoading: isLoading,
        financeErrorResponse: null,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const updateLoanOfferSuccess = (state = INITIAL_STATE, { data, isInHouseFinance }) => {
    return {
        ...state,
        financeData: isInHouseFinance ? {
            ...data.loanOfferRequest,
            selectedQuote: retainSelectedQuotes(data, state)
        } : {
            ...data.loanOfferRequest
        },
        leastWeeklyRepayment: data.leastWeeklyRepayment,
        readOnlyFinanceData: data.loanOfferRequest,
        oldDepositAmount: data.loanOfferRequest && data.loanOfferRequest.loan && data.loanOfferRequest.loan.deposit,
        financeErrorResponse: data.errorResponse,
        isFinanceDataPosted: true,
        financeQuotes: (data.otherQuotes || data.topQuotes) ? [...(data.otherQuotes || []), ...(data.topQuotes || [])] : data.quotes,
        topQuotes: data.topQuotes,
        otherQuotes: data.otherQuotes,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        pollStatus: data.pollStatus,
        isFinanceDataLoading: false,
        financeDataError: null,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null

    };
};

export const updateLoanOfferFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        financeErrorResponse: null,
        financeQuotes: null,
        topQuotes: null,
        otherQuotes: null,
        financeDataError: error,
        isGetFinanceDataError: null,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: true

    };
};

export const updateFinanceSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        order: data,
        isOrderDetailLoading: false,
        orderDetailError: null
    };
};

// modify this if you need to persist some data
export const softResetCheckout = (state = INITIAL_STATE) => {
    return {
        ...INITIAL_STATE,
        personalDetails: state.personalDetails
        // order: state.order
    };
};

export const setMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {...state, maxStep};
};

export const setRecalculateTriggered = (state = INITIAL_STATE, {recalculateTriggered}) => {
    return {...state, recalculateTriggered};
};

export const fetchEgcPriceRequest = (state = INITIAL_STATE) => {
    return {...state, isEgcDataLoading: true};
};

export const fetchEgcPriceSuccess = (state = INITIAL_STATE, {data, allStatesPrice}) => {
    return {...state, isEgcDataLoading: false, egcData: [...data], allStatesPrice};
};

export const fetchEgcPriceFailure = (state = INITIAL_STATE, {error}) => {
    return {...state, isEgcDataLoading: false, egcData: [...error]};
};
//trade-in

export const getTradeInDetailsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const getTradeInDetailsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        valuationTags: data.valuationTags || [],
        priceComparison: data.priceComparison || {},
        makeModelFlow: {
            ...state.makeModelFlow,
            mmvyUserInputs: data.mmvyUserInputs || {},
            isFetchingOrderDetails: false
        },
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const getTradeInDetailsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataLoading: false,
        isGetTradeInDataError: true,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null,
        tradeInData: TRADE_IN_INITIAL_STATE

    };
};

export const createTradeInRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: true,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const createTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: true,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isTradeInDataLoading: isLoading,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInSuccess = (state = INITIAL_STATE, { data, valuationTags, priceComparison }) => {
    return {
        ...state,
        tradeInData: {
            ...data,
            minorExteriorDamage: data.minorExteriorDamage || {},
            majorExteriorDamage: data.majorExteriorDamage || {}
        },
        valuationTags,
        priceComparison,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: null
    };
};

export const updateTradeInFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeInDataLoading: false,
        isGetTradeInDataError: null,
        isCreateTradeInDataError: null,
        isUpdateTradeInDataError: true
    };
};

export const updateByoLoanInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            isUpdateByoFinanceLoading: true
        }
    };
};

export const updateByoLoanSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            ...data,
            isUpdateByoFinanceLoading: false
        }
    };
};

export const updateByoLoanFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            isUpdateByoFinanceLoading: false,
            isUpdateFinanceDataError: error
        }
    };
};

export const getByoLoanInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            isGetByoFinanceLoading: true
        }
    };
};
export const getByoLoanSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            ...data,
            isGetByoFinanceLoading: false
        }
    };
};

export const getByoLoanFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        byoFinanceData: {
            ...state.byoFinanceData,
            isGetFinanceDataError: error,
            isGetByoFinanceLoading: false
        }
    };
};

export const customerOptedTrade = (state = INITIAL_STATE, {tradeinOpted}) => ({...state, tradeinOpted});

export const fetchTradeInCarMakeModel = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarDataLoading: true
    };
};

export const fetchTradeInCarMakeModelSuccess = (state = INITIAL_STATE, { tradeInCarDetails }) => {
    return {
        ...state,
        tradeInCarDetails,
        isCarDataLoading: false
    };
};

export const fetchTradeInCarMakeModelFailure = (state = INITIAL_STATE, tradeInCarDetails) => {
    return {
        ...state,
        tradeInCarDetails,
        isCarDataLoading: false
    };
};

export const fetchTradeInCarVariant = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCarVariantLoading: true
    };
};

export const fetchTradeInCarVariantSuccess = (state = INITIAL_STATE, {tradeInCarVariants}) => {
    return {
        ...state,
        tradeInCarVariants,
        isCarVariantLoading: false
    };
};

export const fetchTradeInCarVariantFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        tradeInCarVariants: [],
        isCarVariantLoading: false,
        makeModelFlow: {
            ...state.makeModelFlow,
            rejectionReasonInfo: {
                show: true,
                type: error
            }
        }
    };
};

// export const fetchTradeInCarMakeModelSuccess = (state = INITIAL_STATE, {tradeInCarDetails}) => ({...state, tradeInCarDetails});
// export const fetchTradeInCarMakeModelFailure = (state = INITIAL_STATE, tradeInCarDetails) => ({...state, tradeInCarDetails});

// export const fetchTradeInCarVariantSuccess = (state = INITIAL_STATE, {tradeInCarVariants}) => ({...state, tradeInCarVariants});
// export const fetchTradeInCarVariantFailure = (state = INITIAL_STATE, tradeInCarVariants) => ({...state, tradeInCarVariants});

export const fetchTradeInStatesSuccess = (state = INITIAL_STATE, {tradeInStates}) => ({...state, tradeInStates});
export const fetchTradeInStatesFailure = (state = INITIAL_STATE, tradeInStates) => ({...state, tradeInStates});
export const saveCheckoutSourcePathname = (state = INITIAL_STATE, { data }) => ({...state, checkoutSourcePathname: data});

export const saveTradeInRegoVin = (state = INITIAL_STATE, { data }) => ({...state, carTradeInRegoVin: data});

export const saveTradeInCarMakeModelId = (state = INITIAL_STATE, { data }) => ({...state, carTradeInCarMakeModelId: data});

export const resetTradeInDetails = (state = INITIAL_STATE) => {
    return {
        ...state,
        tradeInData: TRADE_IN_INITIAL_STATE
    };
};

export const setSplitPayment = (state = INITIAL_STATE, {data}) => ({
    ...state,
    splitAmountDetails: {
        ...state.splitAmountDetails,
        isFirstSplitPay: data.isFirstSplitPay,
        isSplitPaymentOpted: data.isSplitPaymentOpted,
        totalAmountPaid: data.totalAmountPaid,
        selectedSplitAmount: data.selectedSplitAmount,
        isFullPaymentDone: data.isFullPaymentDone,
        remainingAmount: data.remainingAmount,
        finalRemainingAmount: data.finalRemainingAmount,
        splitPayLabel: data.splitPayLabel
    }});

export const setSplitPaymentUserState =  (state = INITIAL_STATE, {data}) => {
    const { paymentMode, range, sliderValue} = data;
    return ({
        ...state,
        splitPaymentUserState: {
            ...state.splitPaymentUserState,
            selectePaymentMode: paymentMode,
            selectedRange: {...state.splitPaymentUserState.selectedRange, min: range.min, max: range.max},
            selectedSliderValue: sliderValue
        }
    });
};

export const getPaymentHistoryInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isPaymentHistoryFetched: data
    };
};

export const getPaymentHistorySuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        paymentHistoryData: data,
        isPaymentHistoryFetched: true
    };
};

export const getPaymentHistoryFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPaymentHistoryFetched: true,
        paymentHistoryError: error
    };
};

export const getSignContractInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isGetSignContractFetched: data
    };
};

export const getSignContractSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        signContractData: data,
        isGetSignContractFetched: true
    };
};

export const getSignContractFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isGetSignContractFetched: true,
        signContractDataError: error
    };
};

export const getVerificationStatusInit = (state = INITIAL_STATE, { data }) => {

    return {
        ...state,
        isVerificationStatusFetched: data
    };
};

export const getVerificationStatusSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        verificationData: data,
        isVerificationStatusFetched: true
    };
};

export const getVerificationStatusFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isVerificationStatusFetched: true,
        verificationDataError: error
    };
};

export const postSignContractInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isPostSignContractFetched: data
    };
};

export const postSignContractSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        signContractData: (state.signContractData || []).reduce((acc, value) => {
            acc = value.documentType === data.documentType ? [...acc, data] : [...acc, value];
            return acc;
        }, []),
        isPostSignContractFetched: true
    };
};

export const postSignContractFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPostSignContractFetched: true,
        signContractDataError: error
    };
};

export const registerVerificationStatusInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isPostVerifyContractFetched: data
    };
};

export const registerVerificationStatusSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        verificationData: data,
        isPostVerifyContractFetched: true
    };
};

export const registerVerificationStatusFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isPostVerifyContractFetched: true,
        verificationData: error
    };
};

export const signContractPollStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        signContractPollCompleted: data
    };
};

export const verifyIdPollStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        verifyIdCompleted: data
    };
};

export const paymentInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        paymentProcessStarted: data
    };
};

export const sessionInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        sessionData: data
    };
};

export const setCurrentDeliveryStep = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        currentDeliveryStep: data
    };
};

export const getTradeInVehicleRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeVehicleLoading: true,
        isGetTradeVehicleLoading: true,
        isGetTradeVehicleError: null,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const getTradeInVehicleSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isTradeVehicleLoading: false,
        isGetTradeVehicleLoading: false,
        tradeVehicle: data,
        isGetTradeVehicleError: null,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const getTradeInVehicleFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTradeVehicleLoading: false,
        isGetTradeVehicleLoading: false,
        isGetTradeVehicleError: true,
        isCreateTradeVehicleError: null,
        isUpdateTradeVehicleError: null
    };
};

export const getCheckoutStepsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: true,
        isGetCheckoutStepsDataLoading: true,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const getCheckoutStepsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataLoading: false,
        checkoutStepsData: { ...data },
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const getCheckoutStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        checkoutStepsData: CHECKOUT_STEPS_INITAL_STATE,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataError: true,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const createCheckoutStepsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: true,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const createCheckoutStepsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutStepsData: data,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const createCheckoutStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: true,
        isUpdateCheckoutStepsDataError: null
    };
};

export const updateCheckoutStepsRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: isLoading,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const updateCheckoutStepsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        checkoutStepsData: data,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: null
    };
};

export const updateCheckoutStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isCheckoutStepsDataLoading: false,
        isGetCheckoutStepsDataError: null,
        isCreateCheckoutStepsDataError: null,
        isUpdateCheckoutStepsDataError: true
    };
};

export const getPromoCodeRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: true, isError: false}
    };
};
export const getPromoCodeSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        promoCode: {...data, isLoading: false, isError: false}
    };
};
export const getPromoCodeFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: false, isError: true}
    };
};
export const postPromoCodeRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: true, isError: false}
    };
};
export const postPromoCodeSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        promoCode: {...data, isCouponApplied: data.status === COUPON_STATUS.SUCCESS, isLoading: false, isError: data.status !== COUPON_STATUS.SUCCESS}
    };
};
export const postPromoCodeFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: false, isError: true, isCouponApplied: false, message: "Something went wrong."}
    };
};

export const resetPromoCode = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: PROMO_CODE_INITIAL_STATE
    };
};

export const removePromoCodeInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: true, isError: false}
    };
};
export const removePromoCodeSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        promoCode: { ...PROMO_CODE_INITIAL_STATE, ...data, message: "", status: "", couponCode: ""}
    };
};
export const removePromoCodeFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        promoCode: {...state.promoCode, isLoading: false, isError: true, isCouponApplied: false, message: "Something went wrong."}
    };
};

export const resetDrivaFinanceData = (state = INITIAL_STATE) => {
    return {
        ...state,
        financeData: {
            ...state.readOnlyFinanceData
        }
    };
};
export const getWarrantyPlansRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        extendedWarranty: { ...state.extendedWarranty, isLoading: true, isError: false}
    };
};
export const getWarrantyPlansSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        extendedWarranty: { ...state.extendedWarranty, warrantyPlanInfo: data, isLoading: false, isError: false}
    };
};
export const getWarrantyPlansFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        extendedWarranty: {...state.extendedWarranty, isLoading: false, isError: true}
    };
};

export const updateWarrantyPlansRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: true,
        isFinanceDataPosted: false,
        extendedWarranty: {...state.extendedWarranty, isLoading: true, isError: false}
    };
};
export const updateWarrantyPlansSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        isFinanceDataPosted: true,
        extendedWarranty: {...state.extendedWarranty, chargeApplied: data, isLoading: false, isError: false}
    };
};
export const updateWarrantyPlansFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isFinanceDataLoading: false,
        isFinanceDataPosted: true,
        extendedWarranty: {...state.extendedWarranty, isLoading: false, isError: true}
    };
};

export const getCarSlotsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        carDeliverySlots: { ...state.carDeliverySlots, isLoading: true, isError: false}
    };
};
export const getCarSlotsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        carDeliverySlots: {  ...data, isLoading: false, isError: false}
    };
};
export const getCarSlotsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        carDeliverySlots: { ...state.carDeliverySlots, isLoading: false, isError: true}
    };
};

export const getTestDriveStepsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: true,
        isGetTestDriveStepsDataLoading: true,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const getTestDriveStepsSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataLoading: false,
        TestDriveStepsData: {...state.testDriveStepsData, ...data},
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const getTestDriveStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataError: true,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const createTestDriveStepsRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: true,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const createTestDriveStepsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        testDriveStepsData: data,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const createTestDriveStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: true,
        isUpdateTestDriveStepsDataError: null
    };
};

export const updateTestDriveStepsRequest = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: isLoading,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const updateTestDriveStepsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        testDriveStepsData: data,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: null
    };
};

export const updateTestDriveStepsFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isTestDriveStepsDataLoading: false,
        isGetTestDriveStepsDataError: null,
        isCreateTestDriveStepsDataError: null,
        isUpdateTestDriveStepsDataError: true
    };
};

export const getBookingSummaryRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        bookingSummary: { ...state.bookingSummary, isLoading: true, isError: false}
    };
};
export const getBookingSummarySuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        bookingSummary: {  ...data, isLoading: false, isError: false}
    };
};
export const getBookingSummaryFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        bookingSummary: { ...state.bookingSummary, isLoading: false, isError: true}
    };
};

export const getTestDriveDataRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        testDriveData: { ...state.testDriveData, isLoading: true, isError: false}
    };
};
export const getTestDriveDataSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        testDriveData: {  ...data, isLoading: false, isError: false}
    };
};
export const getTestDriveDataFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        testDriveData: { ...state.testDriveData, isLoading: false, isError: true}
    };
};

export const  checkInHouseFinanceEligibilityRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        inHouseFinanceEligibilityFetched: false
    };
};

export const  checkInHouseFinanceEligibilitySuccess = (state = INITIAL_STATE, {data = false}) => {
    return {
        ...state,
        inHouseFinanceEligibility: {
            ...state.inHouseFinanceEligibility,
            data
        },
        inHouseFinanceEligibilityFetched: true
    };
};

export const checkInHouseFinanceEligibilityFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        inHouseFinanceEligibility: {
            ...state.inHouseFinanceEligibility,
            error
        },
        inHouseFinanceEligibilityFetched: true
    };
};

export const getValidQuotesInHouseFinanceRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        isGetValidQuotesLoading: true
    };
};

export const getValidQuotesInHouseFinanceSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        financeData: {
            ...data.loanOfferRequest,
            selectedQuote: data.selectedQuote
        },
        // financeData: data.loanOfferRequest,
        isGetValidQuotesLoading: false,
        isFinanceDataPosted: true,
        checkoutEditFormData: {
            ...data.loanOfferRequest,
            user: data.loanOfferRequest.user || state.checkoutEditFormData.user,
            loan: data.loanOfferRequest.loan || state.checkoutEditFormData.loan,
            loanType: data.loanOfferRequest.loanType || state.checkoutEditFormData.loanType
        },
        readOnlyFinanceData: data.loanOfferRequest,
        externalApplicationStatus: data.externalApplicationStatus,
        financeQuotes: (data.otherQuotes || data.topQuotes) ? [...(data.otherQuotes || []), ...(data.topQuotes || [])] : data.quotes,
        topQuotes: data.topQuotes,
        quote: (data || {}).quote || {},
        quotes: (data || {}).quotes || [],
        otherQuotes: data.otherQuotes,
        financeErrorResponse: data.errorResponse,
        zeroDpRebookOrder: data.zeroDpRebookOrder,
        orderAvailable: data.orderAvailable,
        quotesAvailable: data.quotesAvailable,
        pollStatus: data.pollStatus
    };
};

export const getValidQuotesInHouseFinanceFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isGetValidQuotesLoading: false,
        isGetFinanceDataLoading: false,
        financeErrorResponse: null,
        financeDataError: error,
        isGetFinanceDataError: true,
        isCreateFinanceDataError: null,
        isUpdateFinanceDataError: null
    };
};

export const createTestDriveAvailabilityRequest = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isOrderDetailLoading: isLoading,
        orderDetailError: null,
        createOrderError: null
    };
};
export const createTestDriveAvailabilitySuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        order: {...data.order, testDriveLocationDetails: data.testDriveLocationDetails || {}},
        isOrderDetailLoading: false,
        orderDetailError: null,
        createOrderError: null
    };
};
export const createTestDriveAvailabilityFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        createOrderError: error,
        isOrderDetailLoading: false,
        isFinanceDataLoading: false
    };
};

export const requestedForWarrantyCallback = (state = INITIAL_STATE, {data}) => {
    return { ...state, requestForWarrantyCallback: data  };
};

export const postEventImpressionSourceInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isPostEventImpressionLoading: isLoading
    };
};
export const postEventImpressionSourceSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        isPostEventImpressionLoading: false,
        deliveryEvent: data,
        deliveryEventError: null
    };
};
export const postEventImpressionSourceFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        isPostEventImpressionLoading: false,
        deliveryEvent: null,
        deliveryEventError: error
    };
};

export const fetchCancellationReasonSuccess = (state = INITIAL_STATE, {cancelationReasons}) => {
    return {
        ...state,
        cancelationReasons
    };
};

export const fetchCancellationReasonFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        cancelationReasons: [],
        cancelationReasonsError: error
    };
};

const fetchCheckoutCallbackRequestInit = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        checkoutCallbackRequestLoading: isLoading,
        checkoutCallbackError: ""
    };
};

const fetchCheckoutCallbackRequestSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        callbackDetails: {
            ...data,
            isExpired: isCallbackRequestExpired(data.expiry)
        },
        checkoutCallbackRequestLoading: false,
        checkoutCallbackError: ""
    };
};
const fetchCheckoutCallbackRequestFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        checkoutCallbackRequestLoading: false,
        checkoutCallbackError: error,
        callbackDetails: CALLBACK_DETAILS_INITIAL_STATE
    };
};

const checkoutCallbackRequestInit = (state = INITIAL_STATE, { isLoading = true }) => {
    return {
        ...state,
        checkoutCallbackRequestLoading: isLoading,
        checkoutCallbackError: ""
    };
};

const checkoutCallbackRequestSuccess = (state = INITIAL_STATE, {data = {}}) => {
    return {
        ...state,
        callbackDetails: {
            ...data,
            isExpired: isCallbackRequestExpired(data.expiry)
        },
        checkoutCallbackRequestLoading: false,
        checkoutCallbackError: ""
    };
};

const byofCallbackRequestSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        byofCallbackDetails: {
            ...data,
            isExpired: isCallbackRequestExpired(data.expiry)
        },
        checkoutCallbackRequestLoading: false,
        checkoutCallbackError: ""
    };
};

const checkoutCallbackRequestFailure = (state = INITIAL_STATE, {error}) => {
    return {
        ...state,
        callbackDetails: CALLBACK_DETAILS_INITIAL_STATE,
        checkoutCallbackRequestLoading: false,
        checkoutCallbackError: error
    };
};

const setZeroDpSelectOfferCoachMark = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        showZeroDpSelectOfferCoachMark: data
    };
};

export const postReBookZeroDpOrderInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isReBookOrderDetailLoading: isLoading
    };
};

export const getAdyenFailureInit = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isGetAdyenFailureFetched: data
    };
};
export const getAdyenFailureSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        adyenFailureData: data,
        isGetAdyenFailureFetched: true
    };
};
export const getAdyenFailureFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        isGetAdyenFailureFetched: true,
        adyenFailureDataError: error
    };
};
export const adyenFailurePollStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        adyenFailurePollCompleted: data
    };
};

export const postReBookZeroDpOrderSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        reBookedOrder: data,
        isReBookOrderDetailLoading: false,
        reBookorderDetailError: null
    };
};

export const postReBookZeroDpOrderFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        reBookorderDetailError: error,
        isReBookOrderDetailLoading: false
    };
};

export const setOrderPaymentPayload = (state = INITIAL_STATE, {paymentPayload}) => {
    return {
        ...state,
        paymentPayload
    };
};

export const setOrderPaymentPayloadUpload = (state = INITIAL_STATE, {paymentPayload}) => {
    return {
        ...state,
        paymentPayload: {...state.paymentPayload, paymentPayload}
    };
};

export const getZeroDpTextDataConfigInit = (state = INITIAL_STATE, {isLoading = true}) => {
    return {
        ...state,
        isGetZeroDpTextDataConfigLoading: isLoading
    };
};

export const getZeroDpTextDataConfigSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        zeroDpTextConfig: data,
        isGetZeroDpTextDataConfigLoading: false,
        zeroDpTextConfigError: null
    };
};

export const getZeroDpTextDataConfigFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        zeroDpTextConfigError: error,
        isGetZeroDpTextDataConfigLoading: false
    };
};

const updateCheckoutPreApprovalStep = (state = INITIAL_STATE, { stage = state.checkoutPreApprovalStep.stage, form = state.checkoutPreApprovalStep.form }) => {
    return {
        ...state,
        checkoutPreApprovalStep: {
            ...state.checkoutPreApprovalStep,
            stage,
            form
        }
    };
};

export const resetCheckoutFinanceData = (state = INITIAL_STATE) => {
    return {
        ...state,
        financeData: INITIAL_FINANCE_DATA
    };
};

const setSelectOfferScreenLoader = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        selectOfferScreenLoader: data
    };
};

export const setTradeinVariantList = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        tradeinVariantList: data
    };
};

const setMakeFlowMaxStep = (state = INITIAL_STATE, {maxStep}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            maxStep
        }
    };
};

const setMakeFlowCurrentStep = (state = INITIAL_STATE, {currentStep}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            currentStep
        }
    };
};

const setMakeModelYear = (state = INITIAL_STATE, {makeModelYear}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            makeModelYear
        }
    };
};

const setMakeFlowHardRejection = (state = INITIAL_STATE, {rejectionReasonInfo}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            rejectionReasonInfo
        }
    };
};

const setMmvyUserInputs = (state = INITIAL_STATE, {mmvyUserInputs}) => {
    return {
        ...state,
        makeModelFlow: {
            ...state.makeModelFlow,
            mmvyUserInputs
        }
    };
};
export const setBankDetails = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        bankDetails: data
    };
};

const getCheckoutSummaryFAQRequest = (state = INITIAL_STATE) => {
    return {
        ...state,
        checkoutSummaryFAQData: {
            ...state.checkoutSummaryFAQData,
            loading: true
        }
    };
};

const getCheckoutSummaryFAQSuccess = (state = INITIAL_STATE, { data = {} }) => {
    return {
        ...state,
        checkoutSummaryFAQData: {
            ...state.checkoutSummaryFAQData,
            loading: false,
            faqs: data
        }
    };
};

const getCheckoutSummaryFAQFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        checkoutSummaryFAQData: {
            ...state.checkoutSummaryFAQData,
            loading: false,
            faqs: {},
            error: error.message || "Something went wrong"
        }
    };
};

const updateTestDriveDetails = (state = INITIAL_STATE, { data, status }) => {
    return {
        ...state,
        order: {
            ...state.order,
            testDriveDetails: data,
            testDriveStatus: status
        }
    };
};

const getIsStraightThroughEligbleStart = (state = INITIAL_STATE) => {
    return {
        ...state,
        stEligibleData: {
            ...state.stEligibleData,
            loading: true
        }
    };
};

const getIsStraightThroughEligbleSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        stEligibleData: {
            loading: false,
            isStEligible: data.eligible || false

        }
    };
};
const getIsStraightThroughEligbleFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        stEligibleData: {
            loading: false,
            isStEligible: false
        }
    };
};

const setFinanceFirstCheckoutEnabled = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        financeFirstCheckoutEnabled: data
    };
};

const getLicenceUploadStatusByOrderId = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        drivingLicenceData: {
            loading: false,
            ...data
        }
    };
};

const setDrivingLicenceDetails = (state = INITIAL_STATE, { data}) => {
    // console.log("=>status", {data, status});
    return {
        ...state,
        drivingLicenceData: {
            loading: false,
            ...data
        },
        order: {
            ...state.order
        }
    };
};

const setLicenceUploadScreenType = (state = INITIAL_STATE, { screenType }) => {
    return {
        ...state,
        drivingLicenceData: {
            ...state.drivingLicenceData,
            screenType
        }
    };
};

const setUpdateCarReservedModalInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        carReservedModal: {
            ...state.carReservedModal,
            isLoading: true
        }
    };
};

const setUpdateCarReservedModal = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        carReservedModal: {
            ...state.carReservedModal,
            ...data
        }
    };
};
const setShowInterstateTransferModal = (state = INITIAL_STATE, { showInterstateTransferModal }) => {
    return {
        ...state,
        showInterstateTransferModal
    };
};

export const HANDLERS = {
    [Types.POPULATE_CHECKOUT_PERSONAL_DATA]: populateCheckoutPersonalData,
    [Types.POPULATE_EMAIL_FROM_TOKEN]: populateEmailFromToken,
    [Types.SET_DELIVERY_MODE]: setDeliveryMode,
    [Types.SET_FINANCE_PERSONAL_DETAILS]: setFinancePersonalDetails,
    [Types.SET_FINANCE_SCREEN]: setFinancingScreen,
    [Types.SET_LOAN_DETAILS]: setLoanDetails,
    [Types.SET_LOAN_TYPE]: setLoanType,
    [Types.SET_FINANCE_SELECTED_CYCLE]: setFinanceSelectedCycle,
    [Types.SET_POST_FINANCE_SCREEN]: setPostFinanceScreen,
    [Types.UPDATE_DELIVERY_MODE_SUCCESS]: updateDeliveryModeSuccess,
    [Types.UPDATE_DELIVERY_MODE_FAILURE]: updateDeliveryModeFailure,
    [Types.CREATE_ORDER_REQUEST]: createOrderRequest,
    [Types.CREATE_ORDER_SUCCESS]: createOrderSuccess,
    [Types.CREATE_ORDER_FAILURE]: createOrderFailure,
    [Types.GET_ORDER_DETAIL_SUCCESS]: getOrderDetailSuccess,
    [Types.GET_ORDER_DETAIL_FAILURE]: getOrderDetailFailure,
    [Types.GET_ORDER_DETAIL_INIT]: getOrderDetailInit,
    [Types.SET_MAX_STEP]: setMaxStep,
    [Types.SET_USER_PERSONAL_DETAILS]: setUserPersonalDetails,
    [Types.SOFT_RESET_CHECKOUT]: softResetCheckout,

    [Types.GET_LOAN_OFFER_REQUEST]: getLoanOfferDetailsRequest,
    [Types.GET_LOAN_OFFER_SUCCESS]: getLoanOfferDetailsSuccess,
    [Types.GET_EMPTY_LOAN_OFFER_SUCCESS]: getEmptyLoanOfferDetailsSuccess,
    [Types.GET_LOAN_OFFER_FAILURE]: getLoanOfferDetailsFailure,

    [Types.CREATE_LOAN_OFFER_REQUEST]: createLoanOfferRequest,
    [Types.CREATE_LOAN_OFFER_SUCCESS]: createLoanOfferSuccess,
    [Types.CREATE_LOAN_OFFER_FAILURE]: createLoanOfferFailure,

    [Types.UPDATE_LOAN_OFFER_REQUEST]: updateLoanOfferRequest,
    [Types.UPDATE_LOAN_OFFER_SUCCESS]: updateLoanOfferSuccess,
    [Types.UPDATE_LOAN_OFFER_FAILURE]: updateLoanOfferFailure,

    [Types.UPDATE_FINANCE_DETAILS_SUCCESS]: updateFinanceSuccess,

    [Types.SET_RECALCULATE_TRIGGERED]: setRecalculateTriggered,
    [Types.FETCH_EGC_PRICE]: fetchEgcPriceRequest,
    [Types.FETCH_EGC_PRICE_SUCCESS]: fetchEgcPriceSuccess,
    [Types.FETCH_EGC_PRICE_FAILURE]: fetchEgcPriceFailure,

    [Types.GET_TRADE_IN_REQUEST]: getTradeInDetailsRequest,
    [Types.GET_TRADE_IN_SUCCESS]: getTradeInDetailsSuccess,
    [Types.GET_TRADE_IN_FAILURE]: getTradeInDetailsFailure,

    [Types.CREATE_TRADE_IN_REQUEST]: createTradeInRequest,
    [Types.CREATE_TRADE_IN_SUCCESS]: createTradeInSuccess,
    [Types.CREATE_TRADE_IN_FAILURE]: createTradeInFailure,

    [Types.UPDATE_TRADE_IN_REQUEST]: updateTradeInRequest,
    [Types.UPDATE_TRADE_IN_SUCCESS]: updateTradeInSuccess,
    [Types.UPDATE_TRADE_IN_FAILURE]: updateTradeInFailure,
    [Types.SET_TRADE_OPTION]: customerOptedTrade,
    [Types.GET_TRADE_IN_STATE_SUCCESS]: fetchTradeInStatesSuccess,
    [Types.GET_TRADE_IN_STATE_FAILURE]: fetchTradeInStatesFailure,

    [Types.GET_TRADE_IN_CAR_DETAILS]: fetchTradeInCarMakeModel,
    [Types.GET_TRADE_IN_CAR_DETAILS_SUCCESS]: fetchTradeInCarMakeModelSuccess,
    [Types.GET_TRADE_IN_CAR_DETAILS_FAILURE]: fetchTradeInCarMakeModelFailure,

    [Types.GET_TRADE_IN_CAR_VARIANT]: fetchTradeInCarVariant,
    [Types.GET_TRADE_IN_CAR_VARIANT_SUCCESS]: fetchTradeInCarVariantSuccess,
    [Types.GET_TRADE_IN_CAR_VARIANT_FAILURE]: fetchTradeInCarVariantFailure,

    [Types.SET__MAKE_MODEL_MAX_STEP]: setMakeFlowMaxStep,
    [Types.SET__MAKE_MODEL_CURRENT_STEP]: setMakeFlowCurrentStep,
    [Types.SET__MAKE_MODEL_HARD_REJECTION]: setMakeFlowHardRejection,
    [Types.SET_MAKE_MODEL_YEAR]: setMakeModelYear,
    [Types.SET_MMVY_USER_INPUTS]: setMmvyUserInputs,

    [Types.SAVE_CHECKOUT_SOURCE_PATHNAME]: saveCheckoutSourcePathname,
    [Types.GET_BYO_LOAN_INIT]: getByoLoanInit,
    [Types.GET_BYO_LOAN_SUCCESS]: getByoLoanSuccess,
    [Types.GET_BYO_LOAN_FAILURE]: getByoLoanFailure,
    [Types.UPDATE_BYO_LOAN_INIT]: updateByoLoanInit,
    [Types.UPDATE_BYO_LOAN_SUCCESS]: updateByoLoanSuccess,
    [Types.UPDATE_BYO_LOAN_FAILURE]: updateByoLoanFailure,
    [Types.SET_SPLIT_PAYMENT]: setSplitPayment,
    [Types.SET_SPLIT_PAYMENT_USER_STATE]: setSplitPaymentUserState,
    [Types.GET_PAYMENT_HISTORY_SUCCESS]: getPaymentHistorySuccess,
    [Types.GET_PAYMENT_HISTORY_FAILURE]: getPaymentHistoryFailure,
    [Types.GET_PAYMENT_HISTORY_INIT]: getPaymentHistoryInit,
    [Types.GET_SIGN_CONTRACT_INIT]: getSignContractInit,
    [Types.GET_SIGN_CONTRACT_SUCCESS]: getSignContractSuccess,
    [Types.GET_SIGN_CONTRACT_FAILURE]: getSignContractFailure,

    [Types.POST_SIGN_CONTRACT_INIT]: postSignContractInit,
    [Types.POST_SIGN_CONTRACT_SUCCESS]: postSignContractSuccess,
    [Types.POST_SIGN_CONTRACT_FAILURE]: postSignContractFailure,
    [Types.SIGN_CONTRACT_POLL_STATUS]: signContractPollStatus,

    [Types.GET_VERIFICATION_ID_STATUS]: verifyIdPollStatus,

    [Types.PAYMENT_INIT]: paymentInit,
    [Types.SESSION_INIT]: sessionInit,

    [Types.SET_CURRENT_DELIVERY_STEP]: setCurrentDeliveryStep,

    [Types.GET_TRADE_IN_VEHICLE_REQUEST]: getTradeInVehicleRequest,
    [Types.GET_TRADE_IN_VEHICLE_SUCCESS]: getTradeInVehicleSuccess,
    [Types.GET_TRADE_IN_VEHICLE_FAILURE]: getTradeInVehicleFailure,

    [Types.GET_CHECKOUT_STEPS_INIT]: getCheckoutStepsRequest,
    [Types.GET_CHECKOUT_STEPS_SUCCESS]: getCheckoutStepsSuccess,
    [Types.GET_CHECKOUT_STEPS_FAILURE]: getCheckoutStepsFailure,

    [Types.CREATE_CHECKOUT_STEPS_REQUEST]: createCheckoutStepsRequest,
    [Types.CREATE_CHECKOUT_STEPS_SUCCESS]: createCheckoutStepsSuccess,
    [Types.CREATE_CHECKOUT_STEPS_FAILURE]: createCheckoutStepsFailure,

    [Types.UPDATE_CHECKOUT_STEPS_REQUEST]: updateCheckoutStepsRequest,
    [Types.UPDATE_CHECKOUT_STEPS_SUCCESS]: updateCheckoutStepsSuccess,
    [Types.UPDATE_CHECKOUT_STEPS_FAILURE]: updateCheckoutStepsFailure,

    [Types.GET_PROMO_CODE_REQUEST]: getPromoCodeRequest,
    [Types.GET_PROMO_CODE_SUCCESS]: getPromoCodeSuccess,
    [Types.GET_PROMO_CODE_FAILURE]: getPromoCodeFailure,
    [Types.POST_PROMO_CODE_REQUEST]: postPromoCodeRequest,
    [Types.POST_PROMO_CODE_SUCCESS]: postPromoCodeSuccess,
    [Types.POST_PROMO_CODE_FAILURE]: postPromoCodeFailure,
    [Types.RESET_POST_PROMO_CODE]: resetPromoCode,
    [Types.REMOVE_PROMO_CODE_REQUEST]: removePromoCodeInit,
    [Types.REMOVE_PROMO_CODE_SUCCESS]: removePromoCodeSuccess,
    [Types.REMOVE_PROMO_CODE_FAILURE]: removePromoCodeFailure,
    [Types.RESET_DRIVA_FINANCE_DATA]: resetDrivaFinanceData,

    [Types.GET_WARRANTY_PLANS_REQUEST]: getWarrantyPlansRequest,
    [Types.GET_WARRANTY_PLANS_SUCCESS]: getWarrantyPlansSuccess,
    [Types.GET_WARRANTY_PLANS_FAILURE]: getWarrantyPlansFailure,
    [Types.UPDATE_WARRANTY_PLANS_REQUEST]: updateWarrantyPlansRequest,
    [Types.UPDATE_WARRANTY_PLANS_SUCCESS]: updateWarrantyPlansSuccess,
    [Types.UPDATE_WARRANTY_PLANS_FAILURE]: updateWarrantyPlansFailure,

    [Types.GET_TEST_DRIVE_INIT]: getCarSlotsRequest,
    [Types.GET_TEST_DRIVE_SUCCESS]: getCarSlotsSuccess,
    [Types.GET_TEST_DRIVE_FAILURE]: getCarSlotsFailure,

    [Types.GET_TEST_DRIVE_STEPS_INIT]: getTestDriveStepsRequest,
    [Types.GET_TEST_DRIVE_STEPS_SUCCESS]: getTestDriveStepsSuccess,
    [Types.GET_TEST_DRIVE_STEPS_FAILURE]: getTestDriveStepsFailure,
    [Types.CREATE_TEST_DRIVE_STEPS_REQUEST]: createTestDriveStepsRequest,
    [Types.CREATE_TEST_DRIVE_STEPS_SUCCESS]: createTestDriveStepsSuccess,
    [Types.CREATE_TEST_DRIVE_STEPS_FAILURE]: createTestDriveStepsFailure,
    [Types.UPDATE_TEST_DRIVE_STEPS_REQUEST]: updateTestDriveStepsRequest,
    [Types.UPDATE_TEST_DRIVE_STEPS_SUCCESS]: updateTestDriveStepsSuccess,
    [Types.UPDATE_TEST_DRIVE_STEPS_FAILURE]: updateTestDriveStepsFailure,

    [Types.UPDATE_TEST_DRIVE_DETAILS]: updateTestDriveDetails,

    [Types.GET_BOOKINGS_SUMMARY_INIT]: getBookingSummaryRequest,
    [Types.GET_BOOKINGS_SUMMARY_SUCCESS]: getBookingSummarySuccess,
    [Types.GET_BOOKINGS_SUMMARY_FAILURE]: getBookingSummaryFailure,

    [Types.GET_TEST_DRIVE_DATA_INIT]: getTestDriveDataRequest,
    [Types.GET_TEST_DRIVE_DATA_SUCCESS]: getTestDriveDataSuccess,
    [Types.GET_TEST_DRIVE_DATA_FAILURE]: getTestDriveDataFailure,

    [Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_REQUEST]: checkInHouseFinanceEligibilityRequest,
    [Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_SUCCESS]: checkInHouseFinanceEligibilitySuccess,
    [Types.CHECK_IN_HOUSE_FINANCE_ELIGIBILITY_FAILURE]: checkInHouseFinanceEligibilityFailure,

    [Types.GET_VALID_QUOTES_HOUSE_FINANCE_REQUEST]: getValidQuotesInHouseFinanceRequest,
    [Types.GET_VALID_QUOTES_HOUSE_FINANCE_SUCCESS]: getValidQuotesInHouseFinanceSuccess,
    [Types.GET_VALID_QUOTES_HOUSE_FINANCE_FAILURE]: getValidQuotesInHouseFinanceFailure,

    [Types.GET_VERIFICATION_STATUS_INIT]: getVerificationStatusInit,
    [Types.GET_VERIFICATION_STATUS_SUCCESS]: getVerificationStatusSuccess,
    [Types.GET_VERIFICATION_STATUS_FAILURE]: getVerificationStatusFailure,

    [Types.REGISTER_VERIFICATION_STATUS_INIT]: registerVerificationStatusInit,
    [Types.REGISTER_VERIFICATION_STATUS_SUCCESS]: registerVerificationStatusSuccess,
    [Types.REGISTER_VERIFICATION_STATUS_FAILURE]: registerVerificationStatusFailure,

    [Types.POST_EVENT_IMPRESSION_SOURCE_INIT]: postEventImpressionSourceInit,
    [Types.POST_EVENT_IMPRESSION_SOURCE_SUCCESS]: postEventImpressionSourceSuccess,
    [Types.POST_EVENT_IMPRESSION_SOURCE_FAILURE]: postEventImpressionSourceFailure,

    [Types.CREATE_TEST_DRIVE_AVAILABILITY_REQUEST]: createTestDriveAvailabilityRequest,
    [Types.CREATE_TEST_DRIVE_AVAILABILITY_SUCCESS]: createTestDriveAvailabilitySuccess,
    [Types.CREATE_TEST_DRIVE_AVAILABILITY_FAILURE]: createTestDriveAvailabilityFailure,
    [Types.SET_SELECTED_QUOTE]: setSelectedQuote,

    [Types.SAVE_CAR_REGNO_VIN]: saveTradeInRegoVin,

    [Types.SAVE_TRADE_IN_ID]: saveTradeInCarMakeModelId,

    [Types.RESET_CAR_TRADE_IN_DETAILS]: resetTradeInDetails,
    [Types.REQUESTED_FOR_WARRANRY_CALLBACK]: requestedForWarrantyCallback,

    [Types.FETCH_TRADE_IN_CANCELLATION_REASON_SUCCESS]: fetchCancellationReasonSuccess,
    [Types.FETCH_TRADE_IN_CANCELLATION_REASON_FAILURE]: fetchCancellationReasonFailure,

    [Types.FETCH_CHECKOUT_CALLBACK_REQUEST_INIT]: fetchCheckoutCallbackRequestInit,
    [Types.FETCH_CHECKOUT_CALLBACK_REQUEST_SUCCESS]: fetchCheckoutCallbackRequestSuccess,
    [Types.FETCH_CHECKOUT_CALLBACK_REQUEST_FAILURE]: fetchCheckoutCallbackRequestFailure,

    [Types.CHECKOUT_CALLBACK_REQUEST_INIT]: checkoutCallbackRequestInit,
    [Types.CHECKOUT_CALLBACK_REQUEST_SUCCESS]: checkoutCallbackRequestSuccess,
    [Types.BYOF_CALLBACK_REQUEST_SUCCESS]: byofCallbackRequestSuccess,
    [Types.CHECKOUT_CALLBACK_REQUEST_FAILURE]: checkoutCallbackRequestFailure,

    [Types.SET_ZERO_DP_SELECT_OFFER_COACHMARK]: setZeroDpSelectOfferCoachMark,

    [Types.POST_ZERO_DP_PAYMENT_API_INIT]: postReBookZeroDpOrderInit,
    [Types.POST_ZERO_DP_PAYMENT_API_SUCCESS]: postReBookZeroDpOrderSuccess,
    [Types.POST_ZERO_DP_PAYMENT_API_FAILURE]: postReBookZeroDpOrderFailure,

    [Types.GET_LOAN_OFFER_FAQ_REQUEST]: getLoanOfferFAQRequest,
    [Types.GET_LOAN_OFFER_FAQ_SUCCESS]: getLoanOfferFAQSuccess,
    [Types.GET_LOAN_OFFER_FAQ_FAILURE]: getLoanOfferFAQFailure,

    [Types.INITIATE_ORDER_PAYMENT_REQUEST]: setOrderPaymentPayload,
    [Types.UPLOAD_BANK_TRANSFER_FILE_SUCCESS]: setOrderPaymentPayloadUpload,

    [Types.GET_ZERO_DP_TEXT_DATA_CONFIG_INIT]: getZeroDpTextDataConfigInit,
    [Types.GET_ZERO_DP_TEXT_DATA_CONFIG_SUCCESS]: getZeroDpTextDataConfigSuccess,
    [Types.GET_ZERO_DP_TEXT_DATA_CONFIG_FAILURE]: getZeroDpTextDataConfigFailure,
    [Types.RESET_CHECKOUT_FINANCE_DATA]: resetCheckoutFinanceData,
    [Types.SET_TRADEIN_VARIANT_LIST]: setTradeinVariantList,

    [Types.SET_LOAN_OFFER_SCREEN_LOADER]: setSelectOfferScreenLoader,

    [Types.UPDATE_CHECKOUT_PRE_APPROVAL_STEPS]: updateCheckoutPreApprovalStep,

    [Types.SET_CHECKOUT_EDIT_MODE]: setCheckoutEditMode,
    [Types.GET_BANK_TRANSFER_SUCCESS]: setBankDetails,
    [Types.SET_CHECKOUT_EDIT_COMPLETED_STAGES]: setCheckoutEditCompletedStages,
    [Types.SET_LOAN_OFFER_SCREENS]: setLoanOfferScreen,
    [Types.SET_CHECKOUT_EDIT_FORM_EXIT_MODAL]: setCheckoutEditFormExitModal,
    [Types.SET_CHECKOUT_EDIT_QUESTIONS_UPDATE_MODAL]: setCheckoutEditQuestionsUpdateModal,
    [Types.SET_CHECKOUT_EDIT_FORM_DATA]: setCheckoutEditFormData,

    [Types.UPDATE_CHARGE_DETAILS]: updateChargeDetails,
    [Types.GET_ADYEN_FAILURE_INIT]: getAdyenFailureInit,
    [Types.GET_ADYEN_FAILURE_SUCCESS]: getAdyenFailureSuccess,
    [Types.GET_ADYEN_FAILURE_FAILURE]: getAdyenFailureFailure,
    [Types.ADYEN_FAILURE_POLL_STATUS]: adyenFailurePollStatus,

    [Types.MSITE_CHECKOUT_SUMMARY_FAQ_REQUEST]: getCheckoutSummaryFAQRequest,
    [Types.MSITE_CHECKOUT_SUMMARY_FAQ_SUCCESS]: getCheckoutSummaryFAQSuccess,
    [Types.MSITE_CHECKOUT_SUMMARY_FAQ_FAILURE]: getCheckoutSummaryFAQFailure,

    [Types.SET_ST_ELIGIBLE_START]: getIsStraightThroughEligbleStart,
    [Types.SET_ST_ELIGIBLE_SUCCESS]: getIsStraightThroughEligbleSuccess,
    [Types.SET_ST_ELIGIBLE_FAILURE]: getIsStraightThroughEligbleFailure,
    [Types.SET_FINANCE_FIRST_CHECKOUT_ENABLED]: setFinanceFirstCheckoutEnabled,
    [Types.SET_CHECKOUT_LOGIN_TYPE]: setCheckoutLoginType,

    [Types.GET_DRIVING_LICENCE_DETAILS_SUCCESS]: getLicenceUploadStatusByOrderId,
    [Types.SET_DRIVING_LICENCE_DETAILS]: setDrivingLicenceDetails,
    [Types.SET_LICENCE_UPLOAD_SCREEN_TYPE]: setLicenceUploadScreenType,

    [Types.SET_UPDATE_CAR_RESERVED_MODAL_INIT]: setUpdateCarReservedModalInit,
    [Types.SET_UPDATE_CAR_RESERVED_MODAL]: setUpdateCarReservedModal,
    [Types.SET_SHOW_INTERSTATE_TRANSFER_MODAL]: setShowInterstateTransferModal
};

export default createReducer(INITIAL_STATE, HANDLERS);

