import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderDetail, resetApplyPromoCode, verifyReservedStatus } from "../checkout-info/actions";
import AdyenPay from "./component";

const mapStateToProps = ({
    paymentConfig,
    carDetails: { content,
        config },
    checkout: { currentDeliveryStep, checkoutCallbackRequestLoading, callbackDetails, financeData, sessionData }
}) => ({
    paymentConfig,
    content,
    imageConfig: config,
    currentDeliveryStep,
    sessionData,
    checkoutCallbackRequestLoading, callbackDetails, financeData
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrderDetailConnect: getOrderDetail,
    resetApplyPromoCodeConnect: resetApplyPromoCode,
    verifyReservedStatusConnect: verifyReservedStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdyenPay);
