import ordinalInWord from "./ordinal-in-word";
// import { shortMonthName } from "./get-month-by-number";
import makePriceLabel from "./make-price-label";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import dashedLowercase from "./dashed-lowercase";
import { makeOdometerLabelAE } from "./make-odometer-label";
import { CURRENCY } from "../../constants/app-constants";

const getCarName = (details = {}) => {
    const { year, make, model } = details || {};
    return `${year} ${make} ${model}`;
};

const getCarKilometersDrivenLabel = (details) => details.odometerReading && makeOdometerLabelAE(details.odometerReading);
const getCarOwnersLabel = (details) => details.ownerNumber && `${ordinalInWord(details.ownerNumber)}`;
const getCarPriceLabel = (details, key = "price", currency = CURRENCY.AU) => makePriceLabel(details[key], currency);
const getDownpayentLabel = (details, currency = CURRENCY.AU) => makePriceLabel(((details || {}).emiDetails || {}).downPayment, currency);
const getEmiLabel = (details, currency = CURRENCY.AU) => makePriceLabel(((details || {}).emiDetails || {}).emi, currency);
const getPurchaseDateBadge = (details) => {
    try {
        // eslint-disable-next-line no-magic-numbers
        const diff = Math.floor((new Date() - new Date(details.purchaseDate)) / (1000 * 60 * 60 * 24));
        const diffMap = {
            "-1": "Added Today",
            0: "Added Today",
            1: "Added Yesterday"
        };
        return diffMap[diff] ? diffMap[diff] : `Added ${formatDistanceToNowStrict(new Date(details.purchaseDate))} ago`;
    } catch (e) {
        return "";
    }
};

const TRIM_TYPES = {
    "fabric": "fabric",
    "leather": "leather",
    "partial-leather": "partialLeather"
};

const carProprtiesAe = (details) => {
    // const purchaseDate = new Date(details.purchaseDate);
    const kilometersDriven = getCarKilometersDrivenLabel(details);
    // const buildYear = `${shortMonthName(purchaseDate.getMonth() + 1)} ${(purchaseDate.getFullYear())}`;

    return [
        { title: "Kilometers", value: kilometersDriven, type: "kilometer" },
        { title: "Transmission", value: details.transmissionType, type: "transmission" },
        { title: "Specs", value: details.specs, type: details.specs === "GCC" ? "gcc" : "nongcc" },
        { title: "Drive Type", value: details.driveType, type: details.driveType === "Four Wheel Drive" ? "4wd" : "2wd" },
        { title: "Fuel Type", value: details.fuelType, type: "fuel" },
        { title: "Trim", value: details.interiorTrimType, type: TRIM_TYPES[dashedLowercase(details.interiorTrimType)] }
        // { title: "Purchased", value: details.year, type: "buildyear" },

        // { title: "Body Type",
        //     value: details.bodyType,
        //     type: "bodytype"
        // }
    ];
};

export default carProprtiesAe;
export {
    getCarName,
    getCarKilometersDrivenLabel,
    getCarOwnersLabel,
    getCarPriceLabel,
    getDownpayentLabel,
    getEmiLabel,
    getPurchaseDateBadge
};
