import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import PostBcVasCongratulationScreenSteps from "../post-bc-vas-congratulation-screen-steps/component";

const PostBcVasCongratulationScreenCard = ({ bannerImage, heading, slideData }) => {
    return (
        <div styleName={"styles.cardOuter"}>
            <div styleName={"styles.imageWrap"}>
                <img src={bannerImage} alt="Congratulation banner image" />
            </div>
            <div styleName={"styles.contentWrap"}>
                <div styleName={"styles.upNextWrap"}>
                    <p styleName={"styles.upNext"}>Up next</p>
                </div>
                <p styleName={"styles.heading"}>{heading}</p>
                <PostBcVasCongratulationScreenSteps slideData={slideData} />
            </div>
        </div>
    );
};

PostBcVasCongratulationScreenCard.propTypes = {
    bannerImage: PropTypes.string,
    heading: PropTypes.string,
    slideData: PropTypes.array
};

export default PostBcVasCongratulationScreenCard;
