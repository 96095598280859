import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PostBcAddons from "./component";

const mapStateToProps = ({
    deliveryInfo: {
        deliveryData: { selfPickUp }
    }
}) => ({
    selfPickUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostBcAddons);
