/* eslint-disable max-statements */
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import H2Regular from "../../shared/h2-regular/component";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
// import { getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";
// import { checkoutSummarySaleLabel } from "../../../utils/helpers/on-sale-label";
// import { DELIVERY_TYPES } from "../../../constants/checkout-constants";
// import { checkoutSummarySaleLabel } from "../../../utils/helpers/on-sale-label";
// import { DELIVERY_TYPES } from "../../../constants/checkout-constants";
// import usePricing from "../../../hooks/use-pricing";

// eslint-disable-next-line complexity
const DriveAwayPriceModal = ({
    onClose,
    paymentSummaryData
}) => {

    const {dapSummary: summary} = paymentSummaryData || {};

    const renderSubItems = (summaryItem) => {
        return (
            <div styleName={"styles.secondSectionWrap"}>

                {summaryItem?.lineItems?.map((subItem) => (
                    <div styleName={"styles.firstSectionWrapper"}>
                        <p styleName={"styles.leftText"}>{subItem?.label}</p>
                        <p styleName={"styles.priceInBold"}>{subItem?.type === "SUBTRACTION" ? `-${makePriceLabelRound(subItem?.amount)}` : makePriceLabelRound(subItem?.amount)}</p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Modal isOpen={true} close={onClose}>
            <div styleName={"styles.car_cover_main"}>
                <div styleName={"styles.heading"}>
                    <H2Regular text="Drive Away Price" />
                    <div styleName={"styles.closeWrapper"}>
                        <CloseButton type="grey" onClickHandler={onClose} />
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.priceBreakupWrapper"}>
                        {summary && summary.lineItems.length > 0 &&
                        summary.lineItems.map((item) => {
                            return (
                                <React.Fragment key={item?.key}>
                                    <div styleName={"styles.firstSectionWrapper"}>
                                        <p styleName={"styles.leftText"}>{item?.label}</p>
                                        <p styleName={"styles.priceInBold"}>{item?.type === "SUBTRACTION" ? "-" : ""}{makePriceLabelRound(item.amount)}</p>
                                    </div>
                                    {item?.summary?.lineItems &&
                                    item?.summary?.lineItems.length > 0 &&
                                    renderSubItems(item?.summary)}
                                </React.Fragment>

                            );
                        })
                        }
                        <div styleName={"styles.firstSectionWrapper styles.border"}>
                            <p styleName={"styles.leftTextTotalPrice"}>{summary?.aggregatedItem?.label}</p>
                            <p styleName={"styles.totalPrice"}>{makePriceLabelRound(summary?.aggregatedItem?.amount)}</p>
                        </div>
                    </div>
                    {Object.keys(summary?.termsAndConditions || {}).length > 0 &&
                     (summary?.termsAndConditions || []).map((tnc, index) => (
                         <div styleName={"styles.bottomBox"} key={index}>
                             <p styleName={"styles.bottomText"}>
                                 {tnc}
                             </p>
                         </div>
                     ))}
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    myBookings: {
        paymentSummaryData
    }
}) => ({
    paymentSummaryData
});

DriveAwayPriceModal.propTypes = {
    priceBreakDown: PropTypes.array,
    onClose: PropTypes.func,
    paymentSummaryData: PropTypes.object
};

export default connect(mapStateToProps, null)(DriveAwayPriceModal);
