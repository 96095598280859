import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CallIcon from "./images/call.svg";
import mailIcon from "./images/mail.svg";

const PostBcVasNeedHelpCard = ({ isFinanceUser }) => {
    return (
        <div styleName={"styles.needHelpOuter"}>
            <p styleName={"styles.heading"}>Need help?</p>
            <p styleName={"styles.callWrapper"}><img src={CallIcon} />If you have questions, call us :&nbsp;<span>< a href="tel:+61 399 177 765">+61 399 177 765</a></span></p>
            <p styleName={"styles.callWrapper"}><img src={mailIcon} />mail us :&nbsp;
                {isFinanceUser ? <a href="mailto:financing.au@cars24.com">financing.au@cars24.com</a> : <a href="mailto:hello.au@cars24.com">hello.au@cars24.com</a>}
            </p>
        </div>
    );
};

PostBcVasNeedHelpCard.propTypes = {
    isFinanceUser: PropTypes.bool
};

export default PostBcVasNeedHelpCard;
