import Types from "./types";
import {  B2cInventoryServiceAU as B2cInventoryService, BffDataAU, CheckoutDrivaService, CheckoutService, ListingServiceAU as  ListingService, ListingServiceAUV2 } from "../../../service";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { EXPERIMENT_TYPE, ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import { getAppliedFilterValues } from "../../../utils/helpers/filter-values";
import { FILTER_ENTITIES, NUMBER } from "../../../constants/app-constants";

const fetchDetails = () => ({
    type: Types.FETCH_CAR_DETAILS
});

const fetchCarDetailsSuccess = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailure = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE, error });

// eslint-disable-next-line complexity
const fetchCarDetails = (carId, params = {}, suppressLoader = false) => (dispatch, getState)  => {
    if (!suppressLoader) {
        dispatch(fetchDetails());
    }
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        },
        preApprovalLoan: {
            preApproved
        },
        filters: { selectedFilters }
    } = getState();
    const {[FILTER_ENTITIES.DEPOSIT]: depositFilterValue = NUMBER.FIVE_HUNDRED} = getAppliedFilterValues(selectedFilters || []) || {};
    // Trade-in toggle selected or not
    const { tradeIn = false } =  userTradeInData || {};
    params = {
        ...(params || {}),
        cdpRequest: true, // used for recently viewed cars /search-page API ordering and differentiate with v1/vehicle in BE
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP,
        ...(tradeIn && ({tradeIn: tradeInToggleSelected })),
        ...(depositFilterValue && !preApproved && ({deposit: depositFilterValue}))

    };

    if (!(params || {}).userFinanceType) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        params = {...(params || {}), userFinanceType};
    }
    const cdpAPI = ListingServiceAUV2.fetchCarDetailV3;

    return new Promise((resolve, reject) => {
        cdpAPI(carId, isGuestLogin ? null : secureToken, params)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                return resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarsInit = () => ({
    type: Types.FETCH_SIMILAR_CARS
});

const fetchSimilarCarsSuccess = (data, params) => ({
    type: Types.FETCH_SIMILAR_CARS_SUCCESS,
    data,
    params
});

const fetchSimilarCarsFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FAILURE,
    error
});

const fetchSimilarCars = (carId, params) => (dispatch, getState) => {
    dispatch(fetchSimilarCarsInit());
    const {
        user: {
            secureToken,
            isUserZeroDpVariant
        },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        },
        preApprovalLoan: {
            preApproved
        },
        filters: { selectedFilters }
    } = getState();
    const {[FILTER_ENTITIES.DEPOSIT]: depositFilterValue = NUMBER.FIVE_HUNDRED} = getAppliedFilterValues(selectedFilters || []) || {};
    // Trade-in toggle selected or not
    const { tradeIn = false } =  userTradeInData || {};
    params = {
        ...(params || {}),
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP,
        ...(tradeIn && ({tradeIn: tradeInToggleSelected })),
        ...(depositFilterValue && !preApproved && ({deposit: depositFilterValue}))
    };
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params, secureToken)
            .then(response => {
                dispatch(fetchSimilarCarsSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsFailure(error));
                reject(error);
            });
    });
};

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const setGsGalleryData = (data) => ({
    type: Types.SET_GS_GALLERY_DATA,
    data
});

const getAvailabilityInfoRequest = () => ({
    type: Types.GET_DELIVERY_INFO_REQUEST
});

const getAvailabilityInfoSuccess = (data, pincode) => ({
    type: Types.GET_DELIVERY_INFO_SUCCESS,
    data,
    pincode
});

const getAvailabilityInfoFailure = (error) => ({
    type: Types.GET_DELIVERY_INFO_FAILURE,
    error
});

const getAvailabilityInfo = (appointmentId, cityCode) => (dispatch) => {
    dispatch(getAvailabilityInfoRequest());
    return new Promise((resolve, reject) => {
        B2cInventoryService.getPickupLocation(appointmentId, cityCode).then((response) => {
            dispatch(getAvailabilityInfoSuccess(response.data, cityCode));
            resolve(response.data);
        }).catch((error) => {
            dispatch(getAvailabilityInfoFailure(error));
            reject(error);
        });
    });
};

const setFinanceFirstCheckoutVariant = (orderId, isFinanceFirstCheckout, secureToken) => () => {
    const ffCheckoutExperiment = isFinanceFirstCheckout ?  EXPERIMENT_TYPE.VARIANT_B : EXPERIMENT_TYPE.VARIANT_A;
    return new Promise((resolve, reject) => {
        const payload = {
            "userVariants": [
                {
                    "name": "finance-first-checkout-revamp",
                    "value": ffCheckoutExperiment
                }
            ]
        };
        CheckoutService.updateFFCheckoutVariant(orderId, payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDrivaRoiDetailsRequest = () => ({
    type: Types.GET_DRIVA_ROI_REQUEST
});

const getDrivaRoiDetailsSuccess = (data) => ({
    type: Types.GET_DRIVA_ROI_SUCCESS,
    data
});

const getDrivaRoiDetailsFailure = (error) => ({
    type: Types.GET_DRIVA_ROI_FAILURE,
    error
});

const getDrivaRoiDetails = (params) => (dispatch, getState) => {
    dispatch(getDrivaRoiDetailsRequest());
    const { user: { secureToken}} = getState();
    return new Promise((resolve, reject) => {
        CheckoutDrivaService.getDrivaRoiDetails(secureToken, params)
            .then((response) => {
                dispatch(getDrivaRoiDetailsSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(getDrivaRoiDetailsFailure(error));
                reject(error);
            });
    });
};

const getDeliveryTimelineRequest = () => ({
    type: Types.GET_DELIVERY_TIMELINE_REQUEST
});

const getDeliveryTimelineSuccess = (data) => ({
    type: Types.GET_DELIVERY_TIMELINE_SUCCESS,
    data
});

const getDeliveryTimelineFailure = (error) => ({
    type: Types.GET_DELIVERY_TIMELINE_FAILURE,
    error
});

const getDeliveryTimeline = (params) => (dispatch, getState) => {
    dispatch(getDeliveryTimelineRequest());
    const { user: { secureToken}} = getState();
    return new Promise((resolve, reject) => {
        ListingService.getDeliveryTimeline(secureToken, params)
            .then((response) => {
                dispatch(getDeliveryTimelineSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(getDeliveryTimelineFailure(error));
                reject(error);
            });
    });
};

const updateLoginFetchCarDetailCompleted = (data) => ({
    type: Types.UPDATE_LOGIN_CAR_DETAIL_FETCH_COMPLETED,
    data
});

const fetchComparsionDetailsSuccess = (data) => ({
    type: Types.FETCH_COMPARISION_DETAILS_SUCCESS,
    data
});

const fetchComparsionDetailsFailure = (error) => ({
    type: Types.FETCH_COMPARISION_DETAILS_FAILURE,
    error
});

const fetchComparsionDetails = (queryString) => (dispatch, getState) => {
    const {
        user: {
            secureToken,
            isUserZeroDpVariant
        },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        }
    } = getState();
    // Trade-in toggle selected or not
    const { tradeIn = false } =  userTradeInData || {};
    const params = {
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP
    };
    if (tradeIn) {
        queryString += `&tradeIn=${!!tradeInToggleSelected}`;
    }
    return new Promise((resolve, reject) => {
        ListingService.fetchComparsionDetails(queryString, secureToken, params)
            .then((response) => {
                dispatch(fetchComparsionDetailsSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchComparsionDetailsFailure(error));
                reject(error);
            });
    });
};

const fetchInspectionReportDataSuccess = (data) => ({
    type: Types.FETCH_INSPECTION_REPORT_DATA_SUCCESS,
    data
});

const fetchInspectionReportDataFailure = () => ({
    type: Types.FETCH_INSPECTION_REPORT_DATA_FAILURE
});

const fetchInspectionReportData = (params) => (dispatch, getState) => {
    const { user: { secureToken}} = getState();
    return new Promise((resolve, reject) => {
        BffDataAU.fetchInspectionReportDataApi(secureToken, params)
            .then((response) => {
                dispatch(fetchInspectionReportDataSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchInspectionReportDataFailure());
                reject(error);
            });
    });
};

const setCurrentDeliveryTimeline = (data) => ({
    type: Types.SET_CURRENT_DELIVERY_TIMELINE,
    data
});

const setDriveAwayPriceModal = (data) => ({
    type: Types.SET_DRIVE_AWAY_PRICE_MODAL,
    data
});

const setRedirectRoute = (data) => ({
    type: Types.SET_REDIRECT_ROUTE,
    data
});
const fetchDetailsV3 = () => ({
    type: Types.FETCH_CAR_DETAILS
});

const fetchCarDetailsSuccessV3 = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailureV3 = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE, error });

// eslint-disable-next-line complexity
const fetchCarDetailsV3 = (carId, params = {}, suppressLoader) => (dispatch, getState)  => {
    if (!suppressLoader) {
        dispatch(fetchDetailsV3());
    }
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();

    // Trade-in toggle selected or not
    params = {
        ...(params || {}),
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP
    };

    if (!(params || {}).userFinanceType) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        params = {...(params || {}), userFinanceType};
    }
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchCarDetailV3(carId, isGuestLogin ? null : secureToken, params, "MSITE")
            .then(response => {
                // const {config: {brandImageHost}} = response.data || {};
                // const updatedResponse =  getImageFromPath(JSON.parse(JSON.stringify(response.data)), brandImageHost);
                // console.log(response.data, updatedResponse);
                dispatch(fetchCarDetailsSuccessV3(response.data));
                return resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailureV3(error));
                reject(error);
            });
    });
};

const fetchTotalAvailableCarSuccess = (data) => ({
    type: Types.FETCH_TOTAL_CAR_SUCCESS,
    data
});
const fetchTotalAvailableCarFailure = () => ({
    type: Types.FETCH_TOTAL_CAR_FAILURE
});

const fetchTotalAvailableCar = (params) => async (dispatch) => {
    try {
        const response = await ListingService.getTotalAvailableCars(params);
        dispatch(fetchTotalAvailableCarSuccess(response.data));
    } catch (error) {
        fetchTotalAvailableCarFailure(error);
    }
};

const clearSimilarCarList = () => ({
    type: Types.CLEAR_SIMILAR_CAR_LIST
});

const prePopulateCarDetails = (data) => ({
    type: Types.PRE_POPULATE_DETAILS,
    data
});

const updateCarListIndex = (index) => {
    return {
        type: Types.SET_CAR_CARD_LIST_INDEX,
        index
    };
};

const setShowHubLocationModalInit = (showHubLocationModal) => ({
    type: Types.SET_SHOW_HUB_LOCATION_MODAL,
    showHubLocationModal
});

const setShowHubLocationModal = (data) => async (dispatch) => {
    dispatch(setShowHubLocationModalInit(data));
};
export {
    fetchCarDetails,
    updateCarDetailsSSRStatus,
    fetchSimilarCars,
    setGsGalleryData,
    getAvailabilityInfo,
    getDrivaRoiDetails,
    updateLoginFetchCarDetailCompleted,
    getDeliveryTimeline,
    fetchComparsionDetails,
    fetchInspectionReportData,
    setCurrentDeliveryTimeline,
    setDriveAwayPriceModal,
    setRedirectRoute,
    fetchCarDetailsV3,
    fetchTotalAvailableCar,
    clearSimilarCarList,
    prePopulateCarDetails,
    setFinanceFirstCheckoutVariant,
    updateCarListIndex,
    setShowHubLocationModal
};
