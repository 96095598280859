/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import DeliveryDriveAwayPriceModal from "../delivery-drive-away-price-modal/component";

import BlueArrow from "./images/blue-arrow.svg";

const PriceBreakdown = ({
    paymentSummaryData,
    hidePriceBreakup = false,
    postBcSummary = null
}) => {

    const [expandedModal, setShowExpandedModal] = useState("");
    const [expandedDetails, setExpandedDetails] = useState([]);
    const { summary: breakdownSummary = {} } = paymentSummaryData || {};
    const [showModal, setShowModal] = useState(false);
    const [modalSummary, setModalSummary] = useState({});

    const summary = postBcSummary || breakdownSummary;
    const handleModal = () => {
        setShowModal(!showModal);
    };

    const rootLevelItemClicked = (item) => {
        if (item?.summary) {
            if (expandedModal === item.key) {
                setShowExpandedModal("");
                setExpandedDetails([]);
            } else if (item?.summary?.viewType === "MODAL") {
                setModalSummary(item?.summary);
                handleModal();
            } else {
                setShowExpandedModal(item?.key);
                setExpandedDetails(item);
            }
        }
    };

    const showCharges = (item) => {
        setModalSummary(item?.summary);
        handleModal();
    };

    const renderAmountDisplay = (details) => {
        if (details?.type === "ADDITION") {
            return (
                <p styleName={"styles.detailLabelFFBreakdonw"}>
                    {makePriceLabelRound(details?.amount)}
                </p>
            );
        }

        if (details?.type === "FREE") {
            return (
                <React.Fragment>
                    <p
                        styleName={
                            details?.key === "GOVT_CHARGES"
                                ? "styles.typeCunderlinebreakdown"
                                : "styles.typeFreeLabelbreakDown"
                        }
                        onClick={() => showCharges(details)}
                    >
                        {details?.label}
                    </p>
                    <p styleName={"styles.typeFreeLabelbreakDown"}>Free</p>
                </React.Fragment>
            );
        }

        return (
            <p styleName={"styles.typeFreeLabelbreakDown"}>
                -{`${makePriceLabelRound(details?.amount)}`}
            </p>
        );
    };

    return (
        <div>
            {!hidePriceBreakup && <div styleName={"styles.headingWrapperPriceBreakdonwDesktop"}>
                <p styleName={"styles.priceBreakDownHeading"}>Price breakdown</p>
            </div>}
            <div styleName={"styles.greenBoxBottomWrap"}>
                {summary?.lineItems?.length > 0 &&
                    summary?.lineItems?.map((item) => (
                        <div key={item?.key}>
                            <div styleName={"styles.summaryLineItemBottomMargin"}>
                                <div styleName={"styles.flexWrap-ffBreakdown"}>
                                    <p
                                        styleName={
                                            item?.summary && Object.keys(item?.summary || {}).length > 0
                                                ? "styles.drivePrice"
                                                : "styles.typeAWithoutUnderline"
                                        }
                                        onClick={() => rootLevelItemClicked(item)}
                                    >
                                        {item?.label}
                                        {item?.summary && Object.keys(item?.summary || {}).length > 0 && (
                                            <img src={BlueArrow} />
                                        )}
                                    </p>
                                    {item?.type === "SUBTRACTION" ? (
                                        <div styleName={"styles.typeFreeLabelbreakDown"}>
                                            -{makePriceLabelRound(item?.amount)}
                                        </div>
                                    ) : (
                                        <div styleName={"styles.driveAmount-ffBreakdown"}>
                                            {makePriceLabelRound(item?.amount)}
                                        </div>
                                    )}
                                </div>
                                {item?.disclaimer && (
                                    <div styleName={"styles.breakdownContentsDisclaimerTextlabel"}>
                                        {item?.disclaimer}
                                    </div>
                                )}
                            </div>
                            {expandedModal && expandedModal === item.key && (
                                <div styleName={`styles.expandedWrapContainer-ffBreakdown`}>
                                    {expandedDetails?.summary?.lineItems.length > 0 &&
                                        expandedDetails?.summary?.lineItems.map((details) => {
                                            return (
                                                <div styleName={"styles.sectionWrapBreakDown"} key={details?.key}>
                                                    {details?.type !== "FREE" && (
                                                        <p
                                                            styleName={
                                                                details?.key === "GOVT_CHARGES"
                                                                    ? "styles.typeCunderlinebreakdown"
                                                                    : "styles.detailLabelFFBreakdonw"
                                                            }
                                                            onClick={() => showCharges(details)}
                                                        >
                                                            {details?.label}
                                                        </p>
                                                    )}
                                                    {renderAmountDisplay(details)}
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    ))}

                <div styleName={"styles.totalAmountContainerLoanBreakdown"}>
                    <p styleName={"styles.totalLoan-ffBreakdown"}>{summary?.aggregatedItem?.label}</p>
                    <p styleName={"styles.totalLoan-ffBreakdown"}>
                        {makePriceLabelRound(summary?.aggregatedItem?.amount)}
                    </p>
                </div>
            </div>

            {showModal && <DeliveryDriveAwayPriceModal onClose={handleModal} summary={modalSummary || {}} />}
        </div>
    );

};

PriceBreakdown.propTypes = {
    isInHouse: PropTypes.bool,
    splitPaymentOpted: PropTypes.bool,
    isByoOpted: PropTypes.bool,
    paymentSummaryData: PropTypes.object,
    hidePriceBreakup: PropTypes.bool,
    postBcSummary: PropTypes.object

};

export default PriceBreakdown;
