import { NUMBER } from "../../constants/app-constants";

const MOBILE_REGEX_AU_C2B = new RegExp(/^\d{8}$/);

/**
 * Validates an Australian mobile number excluding first digit (ex: 4). So, It'll validate
 * the rest of 8 digits only.
 *
 * @param {string} mobile - The mobile number to be validated.
 * @returns {boolean} - Returns `true` if the mobile number is valid, otherwise `false`.
 */
export const mobileNumberValidatorC2B = (mobile) => {
    return MOBILE_REGEX_AU_C2B.test(mobile);
};

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {*} mobile
 * if the length of mobile is greater than 8, take the last 8 digits and return it
 */
export const formatMobileNumber = (mobile) => {
    if (typeof mobile !== "string" || mobile.length < NUMBER.EIGHT) {
        return mobile;
    }
    return mobile.slice(-NUMBER.EIGHT);
};
