/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";

import styles from "./styles.css";

import Banner from "./banner/component";
import CarPackages from "./car-packages/component";
import PickAndChoose from "./pick-and-choose/component";
import CustomerTestimonials from "./customer-testimonials/component";
import DriveWithPeaceOfMind from "./drive-with-peace-of-mind/component";
import ReviewYourPurchase from "./review-your-purchase/component";
import VasCartSummary from "../vas-cart-summary/component";
import VasStore from "./vas-store/component";
import DriveAwayPriceModal from "../drive-away-price-modal-revamp/component";
import Disclaimer from "./disclaimer";

import TransparentButton from "../../shared/button/transparent-button";

import Loader from "../../shared/loader";

import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { SCREEN_TYPE } from "./reducers";

import { CHECKOUT_STEPS, CHECKOUT_STEPS_STATUS } from "../../../constants/checkout-constants";
import Button from "../../shared/button";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import VasProductDetail from "./vas-product-detail";
import { VAS_PAGE_SCREEN_TYPE } from "../../../constants/app-constants";
import usePricing from "../../../hooks/use-pricing";

const AddOns = ({
    appointmentId,
    content,
    clearVASCartConnect,
    getVASBundlesConnect,
    getVASCartConnect,
    order,
    orderId,
    preApproved,
    routeData,
    screenType,
    setAddonScreenTypeConnect,
    setCounterAnimationConnect,
    showCounterAnimation,
    updateCheckoutStepsConnect,
    vasCartData,
    vasTrackerConnect
}) => {
    const history = useHistory();
    const vasCartItems = (vasCartData || {}).items || [];
    const isAddOnPurchased = vasCartItems.length > 0;

    let urlParams;

    if (typeof window !== "undefined" && window.location) {
        urlParams = new window.URLSearchParams(window.location.search);
    }

    const carYearQuery = urlParams && urlParams.get("carYear");
    const typeQuery = urlParams && urlParams.get("type");
    const keyQuery = urlParams && urlParams.get("key");
    const carPriceQuery = urlParams && urlParams.get("carPrice");

    const { egc, year } = content || {};

    // const { deliveryData = {} } = deliveryInfo || {};
    // const { homeDelivery = [] } = deliveryData || {};

    const showCartView = screenType === SCREEN_TYPE.CART_VIEW;
    const showVASStore = screenType === SCREEN_TYPE.VAS_STORE;

    const { priceBreakDown } = order || {};
    // const selectedDeliveryMode = deliveryMode || orderDeliveryMode || "";
    // const calculateShippingPrice = deliveryData && deliveryData.homeDelivery && selectedDeliveryMode === DELIVERY_TYPES.HomeDelivery ? homeDelivery && homeDelivery[0] && homeDelivery[0].shipping && homeDelivery[0].shipping[0] && homeDelivery[0].shipping[0].shippingPrice || 0 : 0;/* No need to show shipping price on 1st delivery screen */
    // const shippingCharge = calculateShippingPrice > 0 && deliveryMode === DELIVERY_TYPES.HomeDelivery ? calculateShippingPrice : 0;
    const { dapOrTradePrice } = usePricing();

    const [showDapModal, setShowDapModal] = useState(false);
    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        return () => {
            if (showCounterAnimation) setCounterAnimationConnect(false);
        };
    }, [setCounterAnimationConnect, showCounterAnimation]);

    useEffect(() => {
        if (!vasCartItems.length && showCartView) {
            setAddonScreenTypeConnect({screenType: SCREEN_TYPE.LANDING_PAGE});
        }
    }, [setAddonScreenTypeConnect, showCartView, vasCartItems.length]);

    useEffect(() => {
        if (orderId && year && egc) {
            const promises = [getVASCartConnect({ orderId, year, egc, vasPageName: VAS_PAGE_SCREEN_TYPE.ADD_ONS }), getVASBundlesConnect(year, egc)];
            Promise
                .allSettled(promises)
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [egc, getVASBundlesConnect, getVASCartConnect, orderId, year]);

    useEffect(() => {
        if (!isAddOnPurchased && orderId) {
            vasTrackerConnect(orderId, "LANDING_PAGE_VISITED");
        }
    }, [isAddOnPurchased, orderId, vasTrackerConnect]);

    const onSkipClick = () => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Skip",
            eventLabel: "Yes"
        });

        vasTrackerConnect(orderId, "VAS_SKIPPED");

        const stepData = {
            appointmentId,
            step: CHECKOUT_STEPS.ADDONS,
            state: CHECKOUT_STEPS_STATUS.SKIPPED
        };
        updateCheckoutStepsConnect(stepData);
        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const onVasStoreClose = () => {
        if (vasCartItems.length > 0) setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
        else setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.LANDING_PAGE });
        setCounterAnimationConnect(false);
    };

    const onProceed = () => {
        if (showCartView) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                eventAction: "Cart page_Action",
                eventLabel: "Proceed"
            });
        } else {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
                eventLabel: "Yes",
                eventAction: "Proceed"
            });
        }

        vasTrackerConnect(orderId, "VAS_ADDED");

        const { relativeURL: nextURL } = getCheckoutPageURL(content, routeData.defaultNext);
        history.push(nextURL);
    };

    const onSkip = async () => {
        setLoading(true);
        try {
            await clearVASCartConnect(orderId);
            vasTrackerConnect(orderId, "VAS_SKIPPED");
            onProceed();
        } catch (error) {
            //console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const toggleDapModal = () => {
        setShowDapModal(!showDapModal);
    };

    const onDapClick = () => {
        if (showCartView) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_CART, {
                eventAction: "Cart page_Action",
                eventLabel: "DAP_popup_clicked"
            });
        } else {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_VAS_STORE, {
                eventAction: "DAP pop up",
                eventLabel: "Yes"
            });
        }

        toggleDapModal();
    };

    const showProducDetailPage = typeQuery && carYearQuery && keyQuery && carPriceQuery;

    if (loading) {
        return <Loader />;
    }

    if (showProducDetailPage) {
        return (
            <VasProductDetail
                open={showProducDetailPage}
                typeQuery={typeQuery}
                carPriceQuery={carPriceQuery}
                carYearQuery={carYearQuery}
                keyQuery={keyQuery}
            />
        );
    }

    return (
        <div styleName={"styles.outer"}>
            <div styleName={"styles.middleSectionWrapper"}>
                <div styleName={"styles.addOns"}>
                    {showCartView ? <ReviewYourPurchase /> :
                        <React.Fragment>
                            <LandingPage />
                            <Disclaimer additionalDisclaimers={["Scratch & dent cover doesn’t account for existing imperfections."]} />
                            {isAddOnPurchased && <div styleName={"styles.landingPageEmptySpace"} />}
                        </React.Fragment>
                    }
                </div>
                <Footer
                    showNumberAnimation={showCounterAnimation}
                    onDapClick={onDapClick}
                    price={dapOrTradePrice}
                    isAddOnPurchased={isAddOnPurchased}
                    onProceed={onProceed}
                    onSkip={onSkip}
                    onSkipClick={onSkipClick}
                    preApproved={preApproved}
                    showCartView={showCartView} />
            </div>
            <div styleName={"styles.leftPanelWrapper"}>
                {showCartView ?
                    <React.Fragment>
                        <Banner />
                        <VasCartSummary />
                    </React.Fragment>
                    :
                    <DriveWithPeaceOfMind />
                }
            </div>

            {showVASStore && <VasStore open={showVASStore} onClose={onVasStoreClose} />}
            {showDapModal && <DriveAwayPriceModal onClose={toggleDapModal} priceBreakDown={priceBreakDown}/>}
        </div>
    );
};

const LandingPage = () => {
    useEffect(() => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventAction: "Landed",
            eventLabel: "Yes"
        });
    }, []);

    return (
        <React.Fragment>
            <Banner />
            <CarPackages />
            <PickAndChoose />
            <CustomerTestimonials />
        </React.Fragment>
    );
};

const Footer = ({ showNumberAnimation, showCartView, isAddOnPurchased, onSkip, onProceed, onDapClick, onSkipClick, preApproved, price }) => {
    if (showCartView) {
        return (
            <div styleName={`styles.buttonWrapper ${showCartView ? "" : "styles.fixedButton"}`}>
                <div styleName={"styles.btn1"}>
                    <Button text="SKIP ADD-ONS" onClick={onSkip} />
                </div>
                <div styleName={"styles.btn2"}>
                    <Button text="ADD & PROCEED" onClick={onProceed} />
                </div>
            </div>
        );
    } else if (isAddOnPurchased) {
        return (
            <div styleName={"styles.addOnPurchased"}>
                <div styleName={"styles.priceWrapper"}>
                    {showNumberAnimation ? <p styleName={"styles.amount"}><CountUp duration={1} separator="," prefix="$" end={(Math.abs(price || 0)).toFixed(0)} /></p> : <div styleName={"styles.amount"}>{makePriceLabelRound(Math.abs(price || 0))}</div>}
                    <div onClick={onDapClick} styleName={"styles.dap"}>Drive Away Price</div>
                </div>

                <Button styleName={"styles.proceedBtn"} text={"Proceed"} onClick={onProceed} />
            </div>
        );
    }

    return <TransparentButton onClick={onSkipClick} text={preApproved ? "Skip, I don’t want add-ons" : "Skip ADD-ONS"} />;

};

Footer.propTypes = {
    isAddOnPurchased: PropTypes.bool,
    onDapClick: PropTypes.func,
    onProceed: PropTypes.func,
    onSkip: PropTypes.func,
    onSkipClick: PropTypes.func,
    preApproved: PropTypes.bool,
    price: PropTypes.number,
    showCartView: PropTypes.bool,
    showNumberAnimation: PropTypes.bool
};

AddOns.propTypes = {
    activeStore: PropTypes.string,
    appointmentId: PropTypes.string,
    chargeDetails: PropTypes.array,
    clearVASCartConnect: PropTypes.func,
    content: PropTypes.object,
    deliveryMode: PropTypes.object,
    deliveryInfo: PropTypes.object,
    egcData: PropTypes.object,
    tradeInData: PropTypes.object,
    getVASBundlesConnect: PropTypes.func,
    getVASCartConnect: PropTypes.func,
    getVASItemsConnect: PropTypes.func,
    loading: PropTypes.bool,
    order: PropTypes.object,
    orderId: PropTypes.string,
    preApproved: PropTypes.bool,
    routeData: PropTypes.object,
    screenType: PropTypes.string,
    setAddonScreenTypeConnect: PropTypes.func,
    setCounterAnimationConnect: PropTypes.func,
    showCounterAnimation: PropTypes.bool,
    updateCheckoutStepsConnect: PropTypes.func,
    vasCartData: PropTypes.object,
    vasTrackerConnect: PropTypes.func,
    getPaymentSummaryConnect: PropTypes.func
};

export default AddOns;

