import { PAYMENT_MODES } from "../../../constants/checkout-constants";

export const FINANCE_FIRST_PRE_APPROVED_SCREENS_KEYS = [
    "ffOverview",
    "loanQuote",
    "preApprovedTradeInIntro",
    "preApprovedTradeInValuation",
    "preApprovedAddOns",
    "preApprovedSummary"
];

export const MODAL_ERROR_MESSAGES = {
    [PAYMENT_MODES.CARD]: "Your refund has been initiated and will be processed within 5-7 business days. In the meantime, we’d love for you to explore similar listings to the _DYNAMIC_CAR_NAME_!",
    [PAYMENT_MODES.BANK_TRANSFER]: "Your refund has been initiated and will be processed within 5-7 business days. In the meantime, we’d love for you to explore similar listings to the _DYNAMIC_CAR_NAME_!",
    DEFAULT: "Don't worry, we have other listings similar to the _DYNAMIC_CAR_NAME_. Check them out now."
};

export const getModalErrorMessage = ({paymentMode, carName}) => {
    const message = MODAL_ERROR_MESSAGES[paymentMode] || MODAL_ERROR_MESSAGES.DEFAULT;
    return message.replace("_DYNAMIC_CAR_NAME_", carName);
};
