/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-shadow */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import styles from "./styles.css";
import Modal from "../../../shared/modal";
import ProductDetailHeader from "../../product-detail-header/component";
import Button from "../../../shared/button";
import VasProductDetailSlider from "../vas-product-detail-slider/component";
import WhatsIncludeCard from "../whats-include-card/component";
import VasSliderBundleDetail from "../vas-slider-bundle-detail/component";
import PackageBenefits from "../package-benefits/component";
import WhatsIncludeCardVTwo from "../whats-include-card-v2/component";
import VasPackageAddModal from "../../vas-package-add-modal/component";
import VasCartSummary from "../../vas-cart-summary/component";
import VasProductDetailFAQ from "../vas-product-detail-faq/component";
import CustomerTestimonials from "../customer-testimonials/component";

import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";

import DeleteIcon from "./images/delete-icon.svg";
import Testimonial1 from "./images/Testimonial_1.png";
import Testimonial2 from "./images/Testimonial_2.png";
import Testimonial3 from "./images/Testimonial_3.png";
import Loader from "../../../shared/loader";
import { trackMobileCustomEventsAU } from "../../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
import { getCheckoutPageURL } from "../../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../../constants/checkout-routes";
import { NUMBER } from "../../../../constants/app-constants";
import { SCREEN_TYPE } from "../reducers";
import Disclaimer from "../disclaimer";

const reviews = [
    {
        icon: Testimonial1,
        title: "Janelle, Melbourne",
        heading: "Great protection",
        description: "I could definitely tell the difference in the paint, it's a lot shinier and the water just beads off the vehicle when it rains. The exterior protection is doing its job, I have not had to wash the vehicle once. For someone who drives a vehicle often for work doing long hours normally I would have dirt and dust on the vehicle however this is not the case."
    },
    {
        icon: Testimonial2,
        title: "Danielle, Brisbane",
        heading: "Fantastic package",
        description: "I am very happy with the Protect Max bundle and how it makes the car look. I would definitely reccommend this package to friends and family as I have noticed that I dont have to maintain the car as much - the exterior still looks shiny and clean since delivery."
    },
    {
        icon: Testimonial3,
        title: "James, Melbourne",
        heading: "Great protection",
        description: "I am really happy with the quality of all the items included in the car care package - and even better that they were such great value for money. I would certainly recommend a car care package to my friends and family to give their new car purchase some extra flair."
    }
];

const settings = {
    slidesToShow: 2.3,
    slidesToScroll: 1,
    infinite: false,
    arrow: true,
    dots: false,
    focusOnSelect: true
};

const VasProductDetail = ({
    addVasItemData,
    addVASItemConnect,
    carPriceQuery,
    carYearQuery,
    content,
    getVASProductDetailConnect,
    keyQuery,
    open,
    orderId,
    preApproved,
    removeVASItemConnect,
    setAddonScreenTypeConnect,
    showToastConnect,
    typeQuery,
    vasCartData,
    vasProductDetail,
    getPaymentSummaryConnect
}) => {
    const history = useHistory();

    const [packageAddedModal, setPackageAddedModal] = useState(false);
    const [packageAddedModalInfo, setPackageAddedModalInfo] = useState({});

    const { loading, data } = vasProductDetail || {};
    const { metadata, value } = data || {};
    const {
        type,
        key,
        name,
        infoBar,
        benefits,
        faqs = [],
        detailedDescription,
        specifications,
        amount,
        discountedPrice,
        repaymentsPerWeek,
        bannerImages = [],
        features = [],
        products = []
    } = metadata || {};

    const { loading: addLoading } = addVasItemData || {};
    const vasCartItems = (vasCartData || {}).items || [];
    const isItemAlreadyPresent = vasCartItems.findIndex((item) => item.key === key) !== -1;

    const ctaText = preApproved ? `Add to loan at ${makePriceLabelRound(repaymentsPerWeek)}/week` : `Add to cart`;

    const { appointmentId } = content || {};

    const onProductDetailClose = () => {
        const { relativeURL } = getCheckoutPageURL(content, checkoutRoutes.addOns.route, "/");
        history.push(relativeURL);
    };

    const onAddClick = async () => {
        try {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
                eventAction: "Landed_detail_page_add_proceed",
                eventLabel: "Yes"
            });
            const { errorDetails } = await addVASItemConnect({ appointmentId, key, type, orderId });
            await getPaymentSummaryConnect();
            if (errorDetails) {
                const { variant, message } = errorDetails || {};
                if (variant === "TOAST") {
                    showToastConnect({ variant: "black", text: message, position: "bottom", toastHideTime: NUMBER.TWO_THOUSAND, toastStyle: "addOnToastMsg" });
                } else if (variant === "POPUP") {
                    setPackageAddedModal(true);
                    setPackageAddedModalInfo(errorDetails);
                }
            }
        } catch (error) {
            //console.log("error", error);
        }
    };

    const onRemoveClick = async () => {
        try {
            await removeVASItemConnect({ appointmentId, key, type, orderId });
            await getPaymentSummaryConnect();
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
                eventAction: "Landed_page_Cart_Remove",
                eventLabel: "Yes"
            });
        } catch (error) {
            //console.log("error", error);
        }
    };

    const onViewCartClick = async () => {
        await getPaymentSummaryConnect();
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
            eventAction: "Landed_page_Cart_View_Cart",
            eventLabel: "Yes"
        });
        setAddonScreenTypeConnect({ screenType: SCREEN_TYPE.CART_VIEW });
        onProductDetailClose();
    };

    useEffect(() => {
        getVASProductDetailConnect({type: typeQuery, carPrice: carPriceQuery, carYear: carYearQuery, key: keyQuery});
    }, [carPriceQuery, carYearQuery, getVASProductDetailConnect, keyQuery, typeQuery]);

    useEffect(() => {
        if (name) {
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_PRODUCT_DETAIL_PAGE, {
                eventAction: "Page_Landed",
                eventLabel: name
            });
        }
    }, [name]);

    if (loading) {
        return (
            <Modal isOpen={open}>
                <div styleName={"styles.vasModalOuter"}>
                    <Loader />
                </div>
            </Modal>
        );
    }

    return (
        <Modal isOpen={open}>
            <div styleName={"styles.vasModalOuter"}>
                <ProductDetailHeader
                    preApproved={preApproved}
                    type={type}
                    infoBar={infoBar}
                    name={name}
                    amount={amount}
                    discountedPrice={discountedPrice}
                    repaymentsPerWeek={repaymentsPerWeek}
                />
                <div className="container">
                    <div styleName={"styles.firstFoldWrapper"}>
                        <div styleName={"styles.leftSliderContainer"}>
                            <VasProductDetailSlider bannerImages={bannerImages} />
                            <VasSliderBundleDetail detailedDescription={detailedDescription} specifications={specifications} />
                            <PackageBenefits benefits={benefits} />
                            <div styleName={"styles.testimonialsWrapper"}>
                                <CustomerTestimonials reviews={reviews} settings={settings} />
                            </div>
                            <div styleName={"styles.faqWrapper"}>
                                <VasProductDetailFAQ faqData={faqs} />
                            </div>
                            {preApproved && <Disclaimer />}
                        </div>
                        <div styleName={"styles.rightSection"}>
                            <div styleName={"styles.whatIncludeWrapper"}>
                                <p styleName={"styles.whatIncludeHeading"}>What’s included</p>
                                {typeQuery === "BUNDLE" ?
                                    (products || []).length > 0 && products.map(({ key, name, iconUrl, description, price }) => (
                                        <WhatsIncludeCard key={key} carImage={iconUrl} includeHeading={name} includeDescription={description} amount={makePriceLabelRound(price)} />
                                    )) :
                                    (features || []).length > 0 && features.map(({name}) => (
                                        <WhatsIncludeCardVTwo key={name} name={name}  />
                                    ))
                                }
                            </div>
                            {type === "BUNDLE" && <VasCartSummary bundleValue={value} />}
                        </div>
                    </div>
                </div>
                {isItemAlreadyPresent ?
                    <div styleName={"styles.footerWrrapper"}>
                        <p onClick={onRemoveClick} styleName={"styles.removeItem"}><img src={DeleteIcon} />Remove</p>
                        <Button onClick={onViewCartClick} text="VIEW CART" />
                    </div>
                    :
                    <div styleName={"styles.footerWrrapper"}>
                        <p />
                        <Button isLoading={addLoading} onClick={onAddClick} text={ctaText} />
                    </div>
                }
            </div>

            <VasPackageAddModal info={packageAddedModalInfo} open={packageAddedModal} onClose={() => setPackageAddedModal(false)} />
        </Modal>
    );
};

VasProductDetail.propTypes = {
    addVasItemData: PropTypes.object,
    addVASItemConnect: PropTypes.func,
    carPriceQuery: PropTypes.string,
    carYearQuery: PropTypes.string,
    content: PropTypes.object,
    getVASProductDetailConnect: PropTypes.func,
    keyQuery: PropTypes.string,
    open: PropTypes.bool,
    orderId: PropTypes.string,
    preApproved: PropTypes.bool,
    removeVASItemConnect: PropTypes.func,
    setAddonScreenTypeConnect: PropTypes.func,
    showToastConnect: PropTypes.func,
    typeQuery: PropTypes.string,
    vasProductDetail: PropTypes.func,
    vasCartData: PropTypes.object,
    getPaymentSummaryConnect: PropTypes.func
};

export default VasProductDetail;
