/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openGuestLogin, closeGuestLogin} from "../trade-in-wrapper/actions";
import EmailPopup from "../enter-guest-email-modal";
import VerifyEmailPopup from "../guest-login-email-verify";
import { GUEST_POPUP_TYPES } from "../../../constants/app-constants";
import { yieldToMain } from "../../../../utils/helpers/yield-to-main";

export default function withGuestLogin(Component) {
    const AuthenticatedGuestComponent = React.memo(({
        isGuestLoginVisible,
        guestPopupType,
        openGuestLoginConnect,
        closeGuestLoginConnect,
        isMobileMandatory,
        ...props
    }) => {
        const [onGuestEmailEnteredCallback, setOnGuestLoginContinueCallback] = useState(() => () => { });
        const [onGuestEmailVerifyCallback, setOnGuestEmailVerifyCallback] = useState(() => () => { });
        // add an event that is fired when the modal is closed
        const [onGuestLoginCloseCallback, setOnGuestLoginCloseCallback] = useState(() => () => { });

        const setOnGuestLoginContinueCallbackHandler = func => {
            setOnGuestLoginContinueCallback(() => func);
        };

        const setOnGuestEmailVerifiedCallbackHandler = func => {
            setOnGuestEmailVerifyCallback(() => func);
        };

        const setOnGuestLoginCloseCallbackHandler = func => {
            setOnGuestLoginCloseCallback(() => func);
        };

        const clearCallbacksAndClose = () => {
            setOnGuestLoginContinueCallback(() => () => { });
            setOnGuestEmailVerifyCallback(() => () => { });
            closeGuestLoginConnect();
            onGuestLoginCloseCallback();
        };

        const handleBack = async () => {
            await yieldToMain();
            clearCallbacksAndClose();
        };

        return (
            <div>
                <Component
                    guestLoginProps={{
                        isGuestLoginVisible,
                        openGuestLoginConnect,
                        setOnGuestEmailEnteredCallback: setOnGuestLoginContinueCallbackHandler,
                        setOnGuestEmailVerifyCallback: setOnGuestEmailVerifiedCallbackHandler,
                        setOnGuestLoginCloseCallback: setOnGuestLoginCloseCallbackHandler
                    }}
                    {
                    ...props
                    }
                />
                { guestPopupType === GUEST_POPUP_TYPES.EMAIL &&
                    <EmailPopup
                        isVisible = {isGuestLoginVisible}
                        handleBack={handleBack}
                        onGuestEmailEnteredCallback = {onGuestEmailEnteredCallback}
                        clearCallbacksAndClose={clearCallbacksAndClose}
                        isMobileMandatory={isMobileMandatory}
                    />
                }
                {  guestPopupType === GUEST_POPUP_TYPES.VERIFY &&
                    <VerifyEmailPopup
                        isVisible = {isGuestLoginVisible}
                        onGuestEmailVerifyCallback = {onGuestEmailVerifyCallback}
                        clearCallbacksAndClose={clearCallbacksAndClose}
                        isMobileMandatory={isMobileMandatory}
                    />
                }

            </div>

        );
    });

    AuthenticatedGuestComponent.propTypes = {
        isGuestLoginVisible: PropTypes.bool,
        guestPopupType: PropTypes.string,
        openGuestLoginConnect: PropTypes.func,
        closeGuestLoginConnect: PropTypes.func,
        isMobileMandatory: PropTypes.bool
    };

    const mapStateToProps = ({
        auc2bTradein: { isGuestLoginVisible, guestPopupType, isMobileMandatory}
    }) => {
        return {
            isGuestLoginVisible,
            guestPopupType,
            isMobileMandatory
        };
    };

    const mapDispatchToProps = (dispatch) =>
        bindActionCreators(
            {
                openGuestLoginConnect: openGuestLogin,
                closeGuestLoginConnect: closeGuestLogin
            },
            dispatch
        );

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuestComponent);
}

