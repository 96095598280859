/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import Types from "./types";
import { CheckoutService, CheckoutDrivaService, UserAuthService, CheckoutTradeInService, ListingServiceAU, B2cConsumerFinanceService, SignContractService } from "../../../service";
import { AUTH_ADDRESS, X_VEHICLE_TYPE } from "../../../constants/api-constants";
import { formatDeliveryAddressToDrivaAddress } from "../../../utils/helpers/format-address-object";
import { getPreApprovedPayload } from "../../../utils/helpers/checkout-util";
import { ORDER_STATUS, VEHICLE_STATE } from "../../../constants/checkout-constants";
import { API_SOURCE, NUMBER, resetReducerKeys } from "../../../constants/app-constants";
import { getCarPrice, getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";

const populateCheckoutPersonalData = (userData) => ({
    type: Types.POPULATE_CHECKOUT_PERSONAL_DATA,
    userData
});

const setUserPersonalDetails = (personalDetails) => ({
    type: Types.SET_USER_PERSONAL_DETAILS,
    personalDetails
});

const setFinanceData = (data) => {
    return {
        type: Types.SET_FINANCE_DATA,
        data
    };
};

const setLoanDetails = (data) => {
    return {
        type: Types.SET_LOAN_DETAILS,
        newData: data
    };
};

const setLoanType = (data) => {
    return {
        type: Types.SET_LOAN_TYPE,
        newData: data
    };
};

const setPersonalDetailSuccessMessage = ({ successMessage }) => {
    return {
        type: Types.SET_PERSONAL_DETAIL_MESSAGE_FORM_ACTIVE,
        successMessage
    };
};

const setLoanTenureValue = ({ loanTenure }) => {
    return {
        type: Types.SET_LOAN_TENURE_VALUE,
        loanTenure
    };
};

const setCreditScoreValue = ({ creditScoreValue }) => {
    return {
        type: Types.SET_CREDIT_SCORE_VALUE,
        creditScoreValue
    };
};

const setDownPaymentValue = ({ downPaymentValue }) => {
    return {
        type: Types.SET_DOWNPAYMENT_VALUE,
        downPaymentValue
    };
};

const setMonthlyEmiValue = ({ monthlyEmiValue }) => {
    return {
        type: Types.SET_MONTHLY_EMI_VALUE,
        monthlyEmiValue
    };
};

const setFinanceSelectedValue = ({ financeSelected }) => {
    return {
        type: Types.SET_FINANCE_SELECTED,
        financeSelected
    };
};

const setMinMaxDownPaymentValue = ({ minDownpaymentValue, maxDownpaymentValue }) => {
    return {
        type: Types.SET_MIN_MAX_DOWNPAYMENT_VALUE,
        minDownpaymentValue,
        maxDownpaymentValue
    };
};

const setMinMaxEmiValue = ({ minEmiValue, maxEmiValue }) => {
    return {
        type: Types.SET_MIN_MAX_EMI_VALUE,
        minEmiValue,
        maxEmiValue
    };
};

const fetchEgcPriceInit = () => ({
    type: Types.FETCH_EGC_PRICE
});

const fetchEgcPriceSuccess = (data) => ({
    type: Types.FETCH_EGC_PRICE_SUCCESS,
    data
});

const fetchEgcPriceFailure = (error) => ({
    type: Types.FETCH_EGC_PRICE_FAILURE,
    error
});

const applyPromoCodeSuccess = (data) => ({
    type: Types.POST_PROMO_CODE_SUCCESS,
    data
});

const getOrderDetailInit = (data) => ({
    type: Types.GET_ORDER_DETAIL_INIT,
    isLoading: data
});

const getOrderDetailSuccess = (data) => ({
    type: Types.GET_ORDER_DETAIL_SUCCESS,
    data
});

const getOrderDetailFailure = (error) => ({
    type: Types.GET_ORDER_DETAIL_FAILURE,
    error
});

const getOrderDetail = (appointmentId, withLoader = true) => (dispatch, getState) => {
    dispatch(getOrderDetailInit(withLoader));
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getOrderSummary(appointmentId, secureToken)
            .then(response => {
                dispatch(getOrderDetailSuccess(response.data));
                if (response.data && response.data.priceBreakDown) {
                    dispatch(fetchEgcPriceSuccess([response.data.priceBreakDown]));
                }
                if (response.data.userPurpose && response.data.userPurpose.purpose) {
                    dispatch(setUserPersonalDetails({["userPurpose"]: {["purpose"]: response.data.userPurpose.purpose } }));
                }
                resolve(response.data);
            }).catch(error => {
                dispatch(getOrderDetailFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};

const createOrderRequest = () => ({
    type: Types.CREATE_ORDER_REQUEST
});

const createOrderSuccess = (data) => ({
    type: Types.CREATE_ORDER_SUCCESS,
    data
});

const createOrderFailure = (error) => ({
    type: Types.CREATE_ORDER_FAILURE,
    error
});

const createOrder = (params) => (dispatch, getState) => {
    dispatch(createOrderRequest());
    const { user: { secureToken }, userGeoData: {postalCode = ""}  } = getState();
    const customHeader = {
        pincode: postalCode
    };
    return new Promise((resolve, reject) => {
        CheckoutService.createOrder(params, secureToken, API_SOURCE.M_SITE, customHeader)
            .then(response => {
                dispatch(createOrderSuccess(response.data));
                resolve(response.data);
                // resolve(ORDER_MOCK_1);
                // dispatch(createOrderSuccess(ORDER_MOCK_1));
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
        // setTimeout(() => {
        //     resolve(ORDER_MOCK);
        // });
    });
};
const setFinanceUpdatedData = ({
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
}) => ({
    type: Types.SET_FINANCE_UPDATED_DATA,
    creditScore,
    downPayment,
    emi,
    tenure,
    chargeList
});

const fetchFinance = (vehiclePrice) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.fetchFinanceDetail(vehiclePrice)
            .then(response => {
                const { data } = response;
                dispatch(setFinanceData(data));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const updateFinancePersonalDetail = (params, orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinancePersonalDetail(params, orderId, token)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateFinanceDetailRequest = () => ({
    type: Types.UPDATE_FINANCE_DETAILS
});
const updateFinanceDetailSuccess = (data) => ({
    type: Types.UPDATE_FINANCE_DETAILS_SUCCESS,
    data
});
const updateFinanceDetailFailure = (error) => ({
    type: Types.UPDATE_FINANCE_DETAILS_FAILURE,
    error
});

const updateFinanceDetail = (params = {}) => (dispatch, getState) => {
    dispatch(updateFinanceDetailRequest());
    const {
        user: { secureToken },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateFinanceDetail({ ...order, ...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateFinanceDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateFinanceDetailFailure(error));
                reject(error);
            });
    });
};

const updateDeliveryModeSuccess = (data) => ({
    type: Types.UPDATE_DELIVERY_MODE_SUCCESS,
    data
});

const updateDeliveryModeFailure = (error) => ({
    type: Types.UPDATE_DELIVERY_MODE_FAILURE,
    error
});

const updateDeliveryMode = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryMode(order, orderId, token).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};

const updateDeliveryContractData = (order, orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateDeliveryContract(order, orderId, token).then((response) => {
            dispatch(updateDeliveryModeSuccess(response.data));
            resolve();
        }).catch(error => {
            dispatch(updateDeliveryModeFailure(error));
            reject(error);
        });
    });
};
const initiateOrderPaymentRequest = () => ({
    type: Types.INITIATE_ORDER_PAYMENT_REQUEST
});

const initiateOrderPayment = (orderId, payload) => (dispatch, getState) => {
    dispatch(initiateOrderPaymentRequest());
    const {
        user: {
            secureToken
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.initiateOrderPayment(orderId, payload, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getPaymentStatusRequest = () => ({
    type: Types.GET_PAYMENT_STATUS
});

const getPaymentStatus = (orderId) => (dispatch, getState) => {
    dispatch(getPaymentStatusRequest());
    const { user: { secureToken } } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentStatus(orderId, secureToken).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const updateOrderBooking = (orderId, token) => () => {
    return new Promise((resolve, reject) => {
        CheckoutService.updateOrderBooking(orderId, token).then((data) => {
            resolve(data);
        });
    });
};

const setDeliveryMode = ({ deliveryMode }) => ({
    type: Types.SET_DELIVERY_MODE,
    deliveryMode
});

const setFinanceChargeList = ({ financeChargeList }) => ({
    type: Types.SET_FINANCE_CHARGE_LIST,
    financeChargeList
});

const setFinancingTab = ({ financingTab }) => ({
    type: Types.SET_FINANCING_TAB,
    financingTab
});

const setFinancingScreen = ({ activeScreen }) => ({
    type: Types.SET_FINANCE_SCREEN,
    activeScreen
});

const setFinancePersonalDetails = (data) => ({
    type: Types.SET_FINANCE_PERSONAL_DETAILS,
    data
});

const cancelOrder = (orderId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.cancelOrder(orderId, token)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch(createOrderFailure(error));
                reject(error);
            });
    });
};

const softResetCheckoutData = () => dispatch => {
    dispatch({ type: Types.SOFT_RESET_CHECKOUT });
};

const resetCheckoutData = () => dispatch => {
    dispatch({ type: resetReducerKeys.RESET_CHECKOUT });
};

// const updateVisitedSteps = (step) => ({
//     type: Types.UPDATE_VISITED_STEPS,
//     step
// });

const setMaxStep = (maxStep) => ({type: Types.SET_MAX_STEP, maxStep});

const sendMobileOTP = () => (dispatch, getState) => {
    const {
        checkout: {
            personalDetails: { phone }
        }
    } = getState();

    return new Promise((resolve, reject) => {
        UserAuthService.sendOTP(phone, AUTH_ADDRESS.PHONE)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
};

// BYOF Actions start
const getByoLoanInit = () => ({
    type: Types.GET_BYO_LOAN_INIT
});

const getByoLoanSuccess = (data) => ({
    type: Types.GET_BYO_LOAN_SUCCESS,
    data
});

const getByoLoanFailure = (error) => ({
    type: Types.GET_BYO_LOAN_FAILURE,
    error
});

const getByoLoan = (params = {}) => (dispatch, getState) => {
    dispatch(getByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId} = order || {};
    return new Promise((resolve, reject) => {
        B2cConsumerFinanceService.getBYODetails(secureToken, orderId).then(response => {
            dispatch(getByoLoanSuccess(response.data));
            resolve(response.data);
        }).catch(error => {
            dispatch(getByoLoanFailure(error));
            reject(error);
        });
    });
};

const updateByoLoanInit = () => ({
    type: Types.UPDATE_BYO_LOAN_INIT
});

const updateByoLoanSuccess = (data) => ({
    type: Types.UPDATE_BYO_LOAN_SUCCESS,
    data
});

const updateByoLoanFailure = (error) => ({
    type: Types.UPDATE_BYO_LOAN_FAILURE,
    error
});

const updateByoLoan = (params = {}) => async (dispatch, getState) => {
    dispatch(updateByoLoanInit());
    const {
        user: { secureToken },
        checkout: { order }
    } = getState();
    const {orderId, appointmentId} = order || {};
    try {
        const response = await B2cConsumerFinanceService.updateBYODetails(secureToken, {...params, orderId, appointmentId});
        dispatch(updateByoLoanSuccess(response.data));
        Promise.resolve(response.data);
    } catch (error) {
        // eslint-disable-next-line no-magic-numbers
        if (error.errorCode === NUMBER.THOUSAND_THREE) {
            const response =  await B2cConsumerFinanceService.addBYODetails(secureToken, {...params, orderId, appointmentId});
            dispatch(updateByoLoanSuccess(response.data));
            Promise.resolve(response.data);
        } else {
            dispatch(updateByoLoanFailure(error));
            Promise.reject(error);
        }

    }
};

// const addByoLoan = (params = {}) => (dispatch, getState) => {
//     dispatch(updateByoLoanInit());
//     const data = getPreApprovedPayload(params, getState());
//     return new Promise((resolve, reject) => {
//         B2cConsumerFinanceService.addBYODetails(data.payload).then(response => {
//             dispatch(updateByoLoanSuccess(response.data));
//             resolve(response.data);
//         }).catch(error => {
//             dispatch(updateByoLoanFailure(error));
//             reject(error);
//         });
//     });
// };

// BYOF Actions ends

const getLoanOfferDetailsRequest = () => ({
    type: Types.GET_LOAN_OFFER_REQUEST
});

const getLoanOfferDetailsSuccess = (data) => ({
    type: Types.GET_LOAN_OFFER_SUCCESS,
    data
});

const getLoanOfferDetailsFailure = (error) => ({
    type: Types.GET_LOAN_OFFER_FAILURE,
    error
});

const getLoanOfferDetails = () =>
    async (dispatch, getState) => {
        dispatch(getLoanOfferDetailsRequest());
        const {
            user: { secureToken },
            checkout: {
                order: { orderId, status}
            }
        } = getState();
        try {
            let response;
            if (status === ORDER_STATUS.CREATED) {
                response = await CheckoutDrivaService.getLoanOfferDetails(orderId, secureToken);
            } else {
                response =  await CheckoutDrivaService.getCurrentOrderLoan(secureToken, orderId);
            }
            dispatch(getLoanOfferDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getLoanOfferDetailsFailure(error));
            return  Promise.reject(error);
        }
    };

const createLoanOfferRequest = () => ({
    type: Types.CREATE_LOAN_OFFER_REQUEST
});

const createLoanOfferSuccess = (data) => ({
    type: Types.CREATE_LOAN_OFFER_SUCCESS,
    data
});

const createLoanOfferFailure = (error) => ({
    type: Types.CREATE_LOAN_OFFER_FAILURE,
    error
});

const createLoanOffer = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createLoanOfferRequest());
        const {
            user: {
                secureToken,
                firstName,
                middleName,
                lastName,
                mobile,
                email = ""
            },
            checkout: {
                financeData,
                egcData,
                tradeinOpted,
                tradeInData: {
                    offeredPrice,
                    expired,
                    accepted
                },
                order: {
                    orderId,
                    deliveryAddress
                },
                deliveryMode
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price
                }
            },
            deliveryInfo: {
                shippingPrice,
                pickupCharges
            },
            myBookings: {
                dapSummary: {
                    aggregatedItem: {
                        amount = 0
                    } = {}
                } = {},
                totalDap: totalDapWithoutTradein = 0
            }
        } = getState();
        // const address = formatDeliveryAddressToDrivaAddress(deliveryAddress);
        const { totalDap, tradeInPrice } = getTradePriceLabel({dapPrice: amount, totalDapWithoutTradein, egcData,  tradeinOpted, deliveryMode});
        const financeDataUpdated = {
            ...financeData,
            submitted: true,
            driveAwayPrice: (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price),
            user: {
                ...financeData.user,
                email,
                firstName,
                middleName,
                lastName,
                mobile,
                address: {
                    ...financeData.user.address
                    // ...address
                }
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: "used",
                vehicleYear: year
            }
        };
        const payload = { ...financeDataUpdated, ...params, appointmentId };
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.createLoanOffer(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(createLoanOfferSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(createLoanOfferFailure(error));
                    reject(error);
                });
        });
    };

const updateLoanOfferRequest = () => ({
    type: Types.UPDATE_LOAN_OFFER_REQUEST
});

const updateLoanOfferSuccess = (data) => {
    return {
        type: Types.UPDATE_LOAN_OFFER_SUCCESS,
        data
    };
};

const updateLoanOfferFailure = (error) => ({
    type: Types.UPDATE_LOAN_OFFER_FAILURE,
    error
});

const updateLoanOffer = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateLoanOfferRequest());
        const {
            user: { secureToken, email,
                firstName,
                middleName,
                lastName,
                mobile },
            checkout: {
                financeData,
                egcData,
                tradeinOpted,
                tradeInData: {
                    offeredPrice,
                    expired,
                    accepted
                },
                order: { orderId },
                deliveryMode
            },
            carDetails: {
                content: {
                    appointmentId,
                    make,
                    model,
                    year,
                    price
                }
            },
            deliveryInfo: {
                shippingPrice,
                pickupCharges
            },
            myBookings: {
                paymentSummaryData: {
                    dapSummary: {
                        aggregatedItem: {
                            amount: dapAmount = 0
                        } = {}
                    } = {},
                    totalDap: totalDapWithoutTradein = 0
                }
            }
        } = getState();
        const { totalDap, tradeInPrice } = getTradePriceLabel({dapPrice: dapAmount, totalDapWithoutTradein, egcData,  tradeinOpted, deliveryMode});
        const financeDataUpdated = {
            ...financeData,
            driveAwayPrice: (tradeinOpted && !expired && accepted) ? Math.round(tradeInPrice.value) : Math.round(totalDap.value || price),
            user: {
                ...financeData.user,
                email,
                mobile
            },
            extra: {
                ...financeData.extra,
                vehicleMake: make,
                vehicleModel: model,
                vehicleState: VEHICLE_STATE.USED,
                vehicleYear: year
            }
        };
        const payload = { ...financeDataUpdated, appointmentId, ...params };
        return new Promise((resolve, reject) => {
            CheckoutDrivaService.updateLoanOffer(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(updateLoanOfferSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateLoanOfferFailure(error));
                    reject(error);
                });
        });
    };

const setRecalculateTriggered = (recalculateTriggered) => ({
    type: Types.SET_RECALCULATE_TRIGGERED,
    recalculateTriggered
});

const requestCallback = () => (dispatch, getState) => {
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.requestCallBack({}, orderId,  secureToken)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchEgcPrice = (carId, state) => (dispatch) => {
    dispatch(fetchEgcPriceInit());
    const postalCode = state ? `state=${state}` : "";
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchCarPricing(carId, postalCode)
            .then(response => {
                dispatch(fetchEgcPriceSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(fetchEgcPriceFailure([]));
                reject([]);
            });
    });
};

//Trade-in
const getTradeInDetailsRequest = () => ({
    type: Types.GET_TRADE_IN_REQUEST
});

const getTradeInDetailsSuccess = (data) => ({
    type: Types.GET_TRADE_IN_SUCCESS,
    data
});

const getTradeInDetailsFailure = (error) => ({
    type: Types.GET_TRADE_IN_FAILURE,
    error
});

const getTradeInDetails = () =>
    async (dispatch, getState) => {
        dispatch(getTradeInDetailsRequest());
        const {
            user: { secureToken },
            checkout: {
                order: { orderId, status}
            }} = getState();
        try {
            let response;
            if (status === ORDER_STATUS.CREATED || status === ORDER_STATUS.RESERVED) {
                response = await CheckoutTradeInService.getTradeInDetails(secureToken);
            } else {
                response =  await CheckoutTradeInService.getPostOrderTradeDetail(secureToken, orderId);
            }
            dispatch(getTradeInDetailsSuccess(response.data));
            return Promise.resolve(response.data);
        } catch (error) {
            dispatch(getTradeInDetailsFailure(error));
            return Promise.reject(error);
        }
    };

const createTradeInRequest = () => ({
    type: Types.CREATE_TRADE_IN_REQUEST
});

const createTradeInSuccess = (data) => ({
    type: Types.CREATE_TRADE_IN_SUCCESS,
    data
});

const createTradeInFailure = (error) => ({
    type: Types.CREATE_TRADE_IN_FAILURE,
    error
});

const createTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(createTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            },
            carDetails: {
                content: {
                    appointmentId
                }
            }
        } = getState();
        const payload = {
            userEmail: email,
            appointmentId,
            currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            CheckoutTradeInService.createTradeIn(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(createTradeInSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(createTradeInFailure(error));
                    reject(error);
                });
        });
    };

const updateTradeInRequest = () => ({
    type: Types.UPDATE_TRADE_IN_REQUEST
});

const updateTradeInSuccess = (data) => {
    return {
        type: Types.UPDATE_TRADE_IN_SUCCESS,
        data
    };
};

const updateTradeInFailure = (error) => ({
    type: Types.UPDATE_TRADE_IN_FAILURE,
    error
});

const updateTradeIn = (params = { submitted: false }) =>
    (dispatch, getState) => {
        dispatch(updateTradeInRequest());
        const {
            user: {
                secureToken,
                email
            },
            checkout: {
                order: {
                    orderId,
                    currency
                }
            }
        } = getState();
        const payload = {
            userEmail: email,
            currency,
            ...params,
            vehicleType: X_VEHICLE_TYPE.CAR
        };
        return new Promise((resolve, reject) => {
            CheckoutTradeInService.updateTradeIn(payload, orderId, secureToken)
                .then((response) => {
                    dispatch(updateTradeInSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(updateTradeInFailure(error));
                    reject(error);
                });
        });
    };

const customerOptedTrade = (tradeinOpted) => ({
    type: Types.SET_TRADE_OPTION,
    tradeinOpted
});

const fetchTradeInStateRequest = () => ({
    type: Types.GET_TRADE_IN_STATE_REQUEST
});

const fetchTradeInStateSuccess = (tradeInStates) => ({
    type: Types.GET_TRADE_IN_STATE_SUCCESS,
    tradeInStates
});

const fetchTradeInStateFailure = (error) => ({
    type: Types.GET_TRADE_IN_STATE_FAILURE,
    error
});

const getTradeInState = () => async (dispatch, getState) => {
    dispatch(fetchTradeInStateRequest());
    const { user: { secureToken }} = getState();
    try {
        const response = await CheckoutTradeInService.getTradeInStates(secureToken);
        const stateValue = response.data.reduce((acc, data) => {
            acc.push({ ...data, label: data.stateName, value: data.stateName});
            return acc;
        }, []);
        dispatch(fetchTradeInStateSuccess(stateValue));
        return response;
    } catch (error) {
        dispatch(fetchTradeInStateFailure(error));
        return error;
    }
};

const saveCheckoutSourcePathname = (data) => ({
    type: Types.SAVE_CHECKOUT_SOURCE_PATHNAME,
    data
});

const setSplitPayment = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT,
    data
});

const updateSplitPaymentRequest = () => ({
    type: Types.UPDATE_SPLIT_PAYMENT
});

const updateSplitPaymentSuccess = (data) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_SUCCESS,
    data
});

const updateSplitPaymentFailure = (error) => ({
    type: Types.UPDATE_SPLIT_PAYMENT_FAILURE,
    error
});

const updateSplitPayment = (params = {}) => (dispatch, getState) => {
    dispatch(updateSplitPaymentRequest());
    const {
        user: { secureToken },
        checkout: {
            order,
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.updateSplitPayment({...params}, orderId, secureToken)
            .then(response => {
                dispatch(updateSplitPaymentSuccess(response.data));
                dispatch(getOrderDetailSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(updateSplitPaymentFailure(error));
                reject(error);
            });
    });
};

const setSplitPaymentUserState = (data = {}) => ({
    type: Types.SET_SPLIT_PAYMENT_USER_STATE,
    data
});

const getPaymentHistoryInit  = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_INIT,
    data
});

const getPaymentHistorySuccess = (data) => ({
    type: Types.GET_PAYMENT_HISTORY_SUCCESS,
    data
});

const getPaymentHistoryFailure = (error) => ({
    type: Types.GET_PAYMENT_HISTORY_FAILURE,
    error
});

const getPaymentHistory = () => (dispatch, getState) => {
    dispatch(getPaymentHistoryInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        CheckoutService.getPaymentHistory(orderId, secureToken)
            .then(response => {
                dispatch(getPaymentHistorySuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(getPaymentHistoryFailure(error));
                reject(error);
            });
    });
};

const downloadContractInit  = (data) => ({
    type: Types.DONWLOAD_CONTRACT_INIT,
    data
});

const downloadContractFailure = (error) => ({
    type: Types.DONWLOAD_CONTRACT_FAILURE,
    error
});

const dowloadContract = () => (dispatch, getState) => {
    dispatch(downloadContractInit());
    const {
        user: { secureToken },
        checkout: {
            order: { orderId }
        }
    } = getState();
    return new Promise((resolve, reject) => {
        SignContractService.downloadContact(secureToken, orderId)
            .then(response => {
                // dispatch(downloadContractSuccess(response.data));
                resolve(response.data);
            })
            .catch(error => {
                dispatch(downloadContractFailure(error));
                reject(error);
            });
    });
};

export {
    populateCheckoutPersonalData,
    setUserPersonalDetails,
    setPersonalDetailSuccessMessage,
    fetchFinance,
    setFinanceData,
    setLoanDetails,
    setLoanType,
    setLoanTenureValue,
    setCreditScoreValue,
    setDownPaymentValue,
    setMonthlyEmiValue,
    getOrderDetail,
    updateFinancePersonalDetail,
    setDeliveryMode,
    setFinancingTab,
    setMinMaxDownPaymentValue,
    setMinMaxEmiValue,
    setFinanceSelectedValue,
    setFinancePersonalDetails,
    createOrder,
    updateFinanceDetail,
    updateDeliveryMode,
    updateDeliveryContractData,
    updateOrderBooking,
    initiateOrderPayment,
    setFinanceUpdatedData,
    setFinancingScreen,
    cancelOrder,
    setFinanceChargeList,
    softResetCheckoutData,
    resetCheckoutData,
    getPaymentStatus,
    setMaxStep,
    sendMobileOTP,
    updateByoLoan,
    // updatePreApprovedLoan,
    getLoanOfferDetails,
    createLoanOffer,
    updateLoanOffer,
    // updateVisitedSteps
    setRecalculateTriggered,
    requestCallback,
    fetchEgcPrice,
    getTradeInDetails,
    createTradeIn,
    updateTradeIn,
    customerOptedTrade,
    getTradeInState,
    saveCheckoutSourcePathname,
    getByoLoan,
    setSplitPayment,
    updateSplitPayment,
    setSplitPaymentUserState,
    getPaymentHistory,
    getPaymentHistoryInit,
    dowloadContract
};
