import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ComplimentaryBenefits from "./component";

const mapStateToProps = ({
    checkout: {order: {
        splitPaymentOpted,
        status
    }}
}) => ({
    splitPaymentOpted,
    status
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ComplimentaryBenefits);
