import statsigEnv from "./statsig-host";

export default (str = "") => {
    const envPrefix = statsigEnv();

    if (str.includes(`${envPrefix}_`)) {
        str = str.replace(`${envPrefix}_`, "");
    }

    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};
