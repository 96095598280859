/* eslint-disable complexity */
/* eslint-disable max-params */
import { DRIVA_MIN_AMOUNT, ZERO_DP_MINIMUM_DEPOSIT } from "../../config/driva-config";
import { NUMBER } from "../../constants/app-constants";
import { makePriceLabelRound } from "./make-price-label";

// @object egcTotal = {egc, motorVehicleDuty, transferFee}
// @number egcPrice, dapPrice
// @string mode
// dap = drive away price

const getCarPrice = ({amountDue = 0, egcPrice = 0, dapPrice = 0, egcData = [], tradeInOfferPrice = false, totalDapWithoutTradein = 0, isTradeinHigh, currentPayableAmount = 0}, formatter = makePriceLabelRound)     => {

    const {egc = 0, motorVehicleDuty = 0, transferFee = 0, ctp = 0, registrationCost = 0, registrationTenure, amountPaid = 0} = egcData[0] || {};
    const totalDap = dapPrice;
    const tradeInPrice = isTradeinHigh ? 0 : totalDap;

    return {
        egc: {value: egcPrice || egc, label: formatter(egcPrice || egc) },
        totalDap: {value: totalDap - amountPaid, label: formatter(totalDap - amountPaid)},
        dap: {value: dapPrice, label: formatter(dapPrice)},
        tradeInPrice: {value: tradeInPrice, label: formatter(tradeInPrice)},
        motorVehicleDuty: {value: motorVehicleDuty, label: formatter(motorVehicleDuty)},
        transferFee: {value: transferFee, label: formatter(transferFee)},
        ctp: {value: ctp, label: formatter(ctp)},
        registrationCost: {value: registrationCost, label: formatter(registrationCost)},
        registrationTenure: {value: registrationTenure, label: `${registrationTenure} months registration`},
        amountPaid: {value: amountPaid, label: formatter(amountPaid)},
        // headerTotalDap: {value: tradeInPrice, label: formatter(tradeInPrice)},
        headerTotalDap: {value: totalDap, label: formatter(totalDap)}, // added during revamp, since payment-summary will return trade in subtracted values, we will return totalDap as per usePricing() hook, headerTotalDap and totalDap can be used interchangeably
        tradeInOfferPrice: {value: tradeInOfferPrice, label: formatter(tradeInOfferPrice)},
        totalDapWithoutTradein: {value: totalDapWithoutTradein, label: formatter(totalDapWithoutTradein)},
        isTradeinHigh,
        currentPayableAmount: {value: currentPayableAmount, label: formatter(currentPayableAmount)},
        amountDue: {value: amountDue, label: formatter(amountDue)}
    };
};

const getFinanceConfig = ({totalAmount, egcData, isUserZeroDpVariant}) => {
    const {amountPaid = 0} = egcData[0] || {};
    const drivaConfig = {
        min: Math.abs((amountPaid || 0) - (isUserZeroDpVariant ? ZERO_DP_MINIMUM_DEPOSIT : DRIVA_MIN_AMOUNT)),
        max: Math.round(totalAmount) - NUMBER.FIVE_THOUSAND,
        isDrivaEnabled() {return totalAmount >= NUMBER.FIVE_THOUSAND + this.min;}
    };

    const byofConfig = {
        min: Math.abs((amountPaid || 0) - NUMBER.FIVE_HUNDRED),
        max: Math.round(totalAmount - NUMBER.THOUSAND),
        isByofEnabled() {return totalAmount >= NUMBER.THOUSAND + this.min;},
        getMinError() {return `Deposit should be >= $${this.min}`;},
        getMaxError() {return `Maximum loan amount is $${this.max}`;}

    };

    return {drivaConfig, byofConfig};

};

// eslint-disable-next-line max-statements
const getTradePriceLabel = ({
    egcData = [],
    tradeInData = {},
    tradeinOpted = false,
    deliveryInfo = {},
    dapPrice, discountPrice = 0,
    chargeDetails = [],
    discount = [],
    isUserZeroDpVariant,
    deliveryMode = "",
    totalDapWithoutTradein,
    tradeinSummary,
    currentPayableAmount,
    amountDue,
    egcPrice,
    isPayableToUser}) => {
    const { deliveryInfoError, deliveryDate, shippingPrice, pickupCharges } = deliveryInfo;
    const {offeredPrice = 0} = tradeinOpted ? tradeInData : {};
    const  carPrice = getCarPrice({egcData, dapPrice, offeredPrice, shippingPrice, discountPrice, chargeDetails, discount, deliveryMode, pickupCharges, totalDapWithoutTradein, tradeinSummary, isTradeinHigh: isPayableToUser, currentPayableAmount, amountDue, egcPrice });
    const {tradeInPrice, totalDap, egc, motorVehicleDuty, transferFee} = carPrice;
    const isCarAvailable = motorVehicleDuty.value + transferFee.value !== 0;
    const isTradeHigh = isPayableToUser;
    const isDeliveryAvailable = (deliveryDate || deliveryInfoError);
    const tradeLabel = isTradeHigh ? "Balance amount (Payable to you)" : "Final Price";
    const driveAwayLabel = (isDeliveryAvailable || isCarAvailable) ?  "Drive Away price" : "Excluding government charges(EGC)";
    const priceSubLabel = isTradeHigh  ? "Total payable to you" : "Total payable by you";
    const price = (isDeliveryAvailable || isCarAvailable) ?  totalDap.value  :  egc.value - discountPrice;
    const warrantyLabel =  (chargeDetails || []).find(item => item.key === "extendedWarranty") || {};
    const windowTinting =  (chargeDetails || []).find(item => item.key === "tintingPlan") || {};
    const vasItems = (chargeDetails || []).filter(item => ["BUNDLE", "PRODUCT"].includes(item.type)) || [];
    //let vasItems = omsVasItems;
    //if ((vasCartItems || []).length) vasItems = vasCartItems;
    let priceData = {
        priceValue: tradeinOpted && isDeliveryAvailable ? Math.abs(tradeInPrice.value) : price,
        priceLabel: tradeinOpted &&  isDeliveryAvailable ? tradeLabel : driveAwayLabel,
        priceSubLabel: isDeliveryAvailable ? priceSubLabel : "",
        dapOrTradePrice: tradeinOpted ? tradeInPrice.value : totalDap.value,
        isDeliveryAvailable,
        tradeinOpted,
        offeredPrice,
        isCarAvailable,
        warrantyLabel,
        windowTinting,
        shippingPrice,
        vasItems,
        ...carPrice
    };

    const financeAmountConfig = getFinanceConfig({totalAmount: priceData.dapOrTradePrice, discountPrice, egcData, isUserZeroDpVariant});
    priceData = {...priceData, ...financeAmountConfig};
    return priceData;
    // const state =  store.getState();
    // const {checkout: {tradeInData, egcData, tradeinOpted}, deliveryInfo} = state;

};

export {getCarPrice, getTradePriceLabel};
