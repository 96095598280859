import loadable from "@loadable/component";
import AdyenPay from "../../components/au.desktop/adyen-payment";
import ContractSaleNote from "../../components/au.desktop/contract-sale-note";
import PostBcAddons from "../../components/au.desktop/post-payment-journey/add-ons";

// const TaskOverView = loadable(() => import("../../components/au.desktop/order-summary-tasks"));
const PostOrderStatusWrapper =  loadable(() => import("../../components/au.desktop/post-order-status-wrapper"));
const OrderPaymentOverview = loadable(() => import("../../components/au.desktop/order-payment-overview"));
const OrderPaymentSummary = loadable(() => import("../../components/au.desktop/order-payment-summary"));
const OrderConfirmedInfo = loadable(() => import("../../components/au.desktop/order-confirmed-info"));
const FinanceLoanApplication =  loadable(() => import("../../components/au.desktop/finance-post-payment-loan-application"));
const FinanceUploadDocuments =  loadable(() => import("../../components/au.desktop/finance-upload-documents"));
const BSBBankTransfer  =  loadable(() => import("../../components/au.desktop/bank-transfer"));
const TrackStApplication = loadable(() => import("../../components/au.desktop/track-st-application"));

export const tasksRoutes = {
    addOns: {
        key: "addOns",
        name: "Add Ons",
        route: "add-ons",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: PostBcAddons,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks-steps",
        approx: 1,
        sidebarNavigation: {
            heading: "Add Ons",
            subHeading: "Customise your ride",
            navKey: "addOns"
        },
        stepNo: 1
    },
    viewTask: {
        key: "viewTask",
        name: "Your tasks",
        route: "tasks",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: PostOrderStatusWrapper,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks-steps",
        subType: "trade-in",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    orderPaymentOverview: {
        key: "orderPaymentOverview",
        name: "task",
        route: "select-payment",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: OrderPaymentOverview,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks",
        subType: "trade-in",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    orderPaymentSummary: {
        key: "orderPaymentSummary",
        name: "task",
        route: "payment-summary",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: OrderPaymentSummary,
        hideCurrentPill: false,
        useRoute: null,
        type: "tasks",
        subType: "task",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    payment: {
        key: "payment",
        name: "task",
        route: "payment",
        gtmPageName: "Paymenbt",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: AdyenPay,
        type: "tasks",
        subType: "task",
        approx: 0,
        useRoute: null,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    bankTransferDetails: {
        key: "bankTransferDetails",
        name: "Bank Transfer",
        route: "bank-transfer-details",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: BSBBankTransfer,
        hideCurrentPill: true,
        useRoute: null,
        type: "tasks",
        subType: "task",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    orderConfirmed: {
        key: "orderConfirmed",
        name: "Booking confirmation",
        route: "order-confirmation",
        gtmPageName: "confirmation",
        gtmPillClickLabel: "",
        defaultBack: "",
        defaultNext: "",
        component: OrderConfirmedInfo,
        type: "tasks-steps",
        approx: 0,
        sidebarNavigation: {
            heading: "Order confirmation",
            subHeading: "Order summary",
            navKey: "orderConfirmed"
        }
    },
    signContract: {
        key: "signContract",
        name: "sign contract",
        route: "sign-contract",
        gtmPageName: "sign-contract",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: ContractSaleNote,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    }, loanApplication: {
        key: "loanApplication",
        name: "Loan Application",
        route: "loan-application",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: FinanceLoanApplication,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    uploadDocuments: {
        key: "uploadDocuments",
        name: "Upload Documents",
        route: "upload-documents",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultBack: "tasks",
        defaultNext: "",
        component: FinanceUploadDocuments,
        type: "hidden",
        approx: 0,
        sidebarNavigation: {
            heading: "Your Tasks",
            subHeading: "Last Steps",
            navKey: "viewTask"
        }
    },
    trackApplication: {
        key: "trackApplication",
        name: "Track Application",
        route: "track-application",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: TrackStApplication,
        hideCurrentPill: false,
        useRoute: null,
        // type: "tasks",
        // subType: "trade-in",
        approx: 1,
        sidebarNavigation: {
            heading: "Your Application ",
            subHeading: "Application tracker",
            navKey: "trackApplication"
        }
    }

};

export const tasksRoutesArray = Object.values(tasksRoutes);

export const routesToRedirecttasks = tasksRoutesArray
    .filter((routeData) => routeData.type === "tasks")
    .slice(1)
    .map((routeData) => routeData.route);

export const routesToRedirectFinance = tasksRoutesArray
    .filter((routeData) => routeData.type === "financing")
    //.slice(1)
    .map((routeData) => routeData.route);

export const tradeInRoutes = tasksRoutesArray.filter((routeData) => routeData.subType === "trade-in")
    .map((routeData) => routeData.route);
