const PLATINUM_COVER_GA_EVENTS = {
    LANDING_PAGE_ACTION: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "Landing_page_action_and_type",
        eventLabel: (action, type) => `${action}_${type}` // e.g., "Clicked_Next_finance"
    },
    ADD_ONS_HOME_PAGE: {
        LAND: {
            eventName: "custom_event",
            eventCategory: "Post_BC_VAS",
            eventAction: "Add_ons_home_page",
            eventLabel: "Landed"
        },
        PRODUCT_VIEWED: {
            eventName: "custom_event",
            eventCategory: "Post_BC_VAS",
            eventAction: "Add_ons_home_page",
            eventLabel: (productName) => `Product_viewed_${productName}` // e.g., "Product_viewed_Extended_warranty"
        },
        OTHER_PLANS_EXPLORED: {
            eventName: "custom_event",
            eventCategory: "Post_BC_VAS",
            eventAction: "Add_ons_other_plans_explored",
            eventLabel: (productName) => `Other_plans_viewed_${productName}` // e.g., "Other_plans_viewed_Extended_warranty"

        }
    },
    ADD_TO_LOAN_OR_CART: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "Add_ons_Add_to_loan_or_cart",
        eventLabel: (productName, planName) => `Product_add_to_loan_or_cart_${productName}_${planName}` // e.g., "Product_add_to_loan_or_cart_Extended_warranty_12_months"
    },
    I_DONT_WANT_CLICKED: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "Add_ons_I_dont_want_clicked",
        eventLabel: (planName) => `Product_Skipped_${planName}` // e.g., "Product_skipped_Extended_warranty_12_months"
    },
    PRICE_DETAILS_AND_CART_LINK: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "Price_details & cart_link_viewed",
        eventLabel: (popupName) => popupName // e.g., "Price breakdown"
    },
    KNOW_MORE_DETAILS: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "Know_more_details_clicked",
        eventLabel: (productName) => productName // e.g., "Extended warranty"
    },
    MY_TASKS_SUMMARY_SCREEN: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "My_tasks_summary_screen",
        eventLabel: "Landed"
    },
    MY_TASKS_BACK_TO_VAS: {
        eventName: "custom_event",
        eventCategory: "Post_BC_VAS",
        eventAction: "My_tasks_summary_screen_back_to_VAS",
        eventLabel: "Pills_Clicked" // e.g., "Banner_Clicked" or "Pills_Clicked"
    }
};

export default PLATINUM_COVER_GA_EVENTS;
