import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import InventoryCarCardRevamp from "./component";
import { fetchCarDetails } from "../car-details/actions";
import { saveCheckoutSourcePathname } from "../checkout-info/actions";
import { setLoginType } from "../../au.configuration/actions";
import withLogin from "../with-login";

const mapStateToProps = ({
    user: {email, isGuestLogin },
    filters: {
        allFilters,
        appliedFilters,
        selectedSort,
        listingType
    },
    config: {
        saleConfig
    },
    tradeIn: {
        userTradeInData,
        tradeInToggleSelected
    },
    user: {
        isUserZeroDpVariant
    },
    abExperiment: {
        showTestDriveV2,
        webPriceDropDesktop,
        webLocationHighlighting
    }

}) => ({
    allFilters,
    appliedFilters,
    selectedSort,
    saleConfig,
    userTradeInData,
    tradeInToggleSelected,
    email,
    listingType,
    isUserZeroDpVariant,
    showTestDriveV2,
    isGuestLogin,
    webPriceDropDesktop,
    webLocationHighlighting
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarDetailsConnect: fetchCarDetails,
    saveCheckoutSourcePathnameConnect: saveCheckoutSourcePathname,
    setLoginTypeConnect: setLoginType
}, dispatch);

export default withLogin(withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryCarCardRevamp)));
