import React, { useRef, useCallback } from "react";
import { NUMBER } from "../constants/app-constants";

const useStyleInjection = () => {
    const elementRefs = useRef({});

    const getOrCreateRef = useCallback((key) => {
        if (!elementRefs.current[key]) {
            elementRefs.current[key] = React.createRef();
        }
        return elementRefs.current[key];
    }, []);

    const injectStyles = useCallback((key) => {
        const element = elementRefs.current[key]?.current;
        if (!element) return;

        element.style.transition = "all 0.3s ease";
        element.style.opacity = "0.5";
        element.style.pointerEvents = "none";

        void element.offsetHeight;

        // eslint-disable-next-line consistent-return
        return () => {
            window.setTimeout(() => {
                if (element) {
                    element.style.transition = "";
                    element.style.opacity = "";
                    element.style.pointerEvents = "";
                }
            }, NUMBER.TEN);
        };
    }, []);

    return {
        getOrCreateRef,
        injectStyles
    };
};

export default useStyleInjection;
