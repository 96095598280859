import { createTypes } from "reduxsauce";

export default createTypes(
    `
        GET_VAS_BUNDLES_REQUEST
        GET_VAS_BUNDLES_SUCCESS
        GET_VAS_BUNDLES_FAILURE

        GET_VAS_ITEMS_REQUEST
        GET_VAS_ITEMS_SUCCESS
        GET_VAS_ITEMS_FAILURE

        ADD_VAS_ITEM_REQUEST
        ADD_VAS_ITEM_SUCCESS
        ADD_VAS_ITEM_FAILURE

        REMOVE_VAS_ITEM_REQUEST
        REMOVE_VAS_ITEM_SUCCESS
        REMOVE_VAS_ITEM_FAILURE

        GET_VAS_CART_REQUEST
        GET_VAS_CART_SUCCESS
        GET_VAS_CART_FAILURE

        CLEAR_VAS_CART_REQUEST
        CLEAR_VAS_CART_SUCCESS
        CLEAR_VAS_CART_FAILURE

        GET_VAS_PRODUCT_DETAIL_REQUEST
        GET_VAS_PRODUCT_DETAIL_SUCCESS
        GET_VAS_PRODUCT_DETAIL_FAILURE

        SET_ADDONS_SCREEN_TYPE
        SET_COUNTER_ANIMATION

        GET_SERVICING_PRICE_CONFIG_REQUEST
        GET_SERVICING_PRICE_CONFIG_SUCCESS
        GET_SERVICING_PRICE_CONFIG_FAILURE

        GET_CAR_COVER_BUNDLES_REQUEST
        GET_CAR_COVER_BUNDLES_SUCCESS
        GET_CAR_COVER_BUNDLES_FAILURE

        GET_POST_BC_LANDING_PLANS_REQUEST
        GET_POST_BC_LANDING_PLANS_SUCCESS
        GET_POST_BC_LANDING_PLANS_FAILURE

        COMPLETE_POST_BC_UPDATE_STEP

        GET_POST_BC_VAS_CART_SUCCESS

        GET_POST_BC_STEP
    `,
    {
        prefix: "add-ons/"
    }
);

