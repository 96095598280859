import React from "react";
import styles from "./style.css";
import SuccessIcon from "../images/Success.svg";
import PropTypes from "prop-types";

const ToastSuccess = ({ isFinanceUser }) => {
    return (
        <div styleName={"styles.outer"}>
            <img src={SuccessIcon} alt="Loan amount updated icon" />
            <p styleName={"styles.message"}>{isFinanceUser ? "Loan amount updated." : "Amount updated."}</p>
        </div>
    );
};

ToastSuccess.propTypes = {
    isFinanceUser: PropTypes.bool
};

export default ToastSuccess;
