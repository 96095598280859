import Types from "./types";
import { ListingServiceAU as ListingService, ListingServiceAUV2 } from "../../../service";
// import categoryJson from "../../../utils/category.json";
import { getSanitizedFilterData } from "../../../utils/helpers/category-helper";
import { LISTING_TYPE } from "../../../constants/app-constants";
// import staticJson from "../../../config/au.mobile/car-list.json";
import { API_VERSION_V2 } from "../../../service/api-version";
import { CASED_EXPERMIMENT_TYPE } from "../../../constants/optimize-constants";

const fetchFiltersInit = () => ({
    type: Types.FETCH_FILTERS
});

const fetchFilterSuccess = (data) => ({
    type: Types.FETCH_FILTERS_SUCCESS,
    data
});

const fetchFiltersFailure = () => ({
    type: Types.FETCH_FILTERS_FAILURE
});

const fetchFilters = (apiVersion) => (dispatch, getState) => {
    dispatch(fetchFiltersInit());
    const { user: { secureToken, userGeoData = {}}, abExperiment: {webPriceDropDesktop, webLocationHighlighting} } = getState();

    const {data: webPriceDropDesktopVariant} = webPriceDropDesktop || {};
    const {data: webLocationHighlightingVariant} = webLocationHighlighting || {};

    let queryParams = webPriceDropDesktopVariant ? `experimentVariant=${CASED_EXPERMIMENT_TYPE[webPriceDropDesktopVariant]}` : "";
    queryParams = webLocationHighlightingVariant ? `${queryParams}&highlightLocationVariant=highlighting__${CASED_EXPERMIMENT_TYPE[webLocationHighlightingVariant]}` : queryParams;

    let listingFiltersApi =  ListingService.fetchFilters;
    switch (apiVersion) {
    case API_VERSION_V2:
        listingFiltersApi =  ListingServiceAUV2.fetchFiltersV2;
        break;
    default:
        listingFiltersApi = listingFiltersApi;
    }
    return listingFiltersApi(secureToken, userGeoData, queryParams)
        .then((response) => {
            dispatch(fetchFilterSuccess(response.data));
            return Promise.resolve(response.data);
        })
        .catch(() => {
            dispatch(fetchFiltersFailure());
            return Promise.reject();
        });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const updateFilterTimestamp = (lastUpdated) => ({
    type: Types.UPDATE_FILTER_TIMESTAMP,
    lastUpdated
});

const updateSelectedOnScreenFilter = (selectedOnScreenFilter) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    selectedOnScreenFilter
});

const updatePriceFilterType = (priceFilterType) => ({
    type: Types.UPDATE_SELECTED_ON_SCREEN_FILTER,
    priceFilterType
});

const updateFilterOptionData = (data) => ({
    type: Types.UPDATE_FILTER_OPTION_DATA,
    data
});

const updateAllFiltersVisibility = (showAllFilters) => ({
    type: Types.UPDATE_ALL_FILTERS_VISIBILITY,
    showAllFilters
});

const fetchListingCountInit = () => ({
    type: Types.FETCH_LISTING_COUNT
});

const fetchListingCountSuccess = (data) => ({
    type: Types.FETCH_LISTING_COUNT_SUCCESS,
    data
});

const fetchListingCountFailure = () => ({
    type: Types.FETCH_LISTING_COUNT_FAILURE
});

const fetchListingCount = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchListingCountInit());
    const { user: { secureToken } } = getState();
    ListingService.fetchListing(params, queryString, secureToken)
        .then((response) => {
            dispatch(fetchListingCountSuccess(response.data));
        })
        .catch(() => {
            dispatch(fetchListingCountFailure());
        });
};

const updateFilterTabType = (activeFilterTab) => ({
    type: Types.UPDATE_FILTER_TAB_TYPE,
    activeFilterTab
});

const updateSelectedSort = (selectedSort) => ({
    type: Types.UPDATE_SELECTED_SORT,
    selectedSort
});

const clearAllFilters = () => ({
    type: Types.CLEAR_ALL_FILTERS
});

const updateAppliedFilters = (appliedFilters) => ({
    type: Types.UPDATE_APPLIED_FILTERS,
    appliedFilters
});

const updateAppliedSuggestions = () => ({
    type: Types.UPDATE_APPLIED_SUGGESTIONS
});

const updateSelectedSuggestions = (data) => ({
    type: Types.UPDATE_SELECTED_SUGGESTIONS,
    data
});

const updateUrlOptions = (urlOptions) => ({
    type: Types.UPDATE_URL_OPTIONS,
    urlOptions
});

const clearSuggestions = (filterKey) => ({
    type: Types.CLEAR_SUGGESTIONS,
    filterKey
});

const updateAllSubFilterOptions = ({filterKey, optionKey, isSelected, isAllModelVariant = false}) => ({
    type: Types.UPDATE_ALL_SUB_FILTER_OPTIONS,
    filterKey,
    optionKey,
    isSelected,
    isAllModelVariant
});

const updateSortModalVisibility = (isVisible) => ({
    type: Types.UPDATE_SORT_MODAL_VISIBILITY,
    isVisible
});

const fetchCategoryFilterInit = () => ({
    type: Types.FETCH_CATEGORY_FILTER_INIT,
    categoryLoading: true
});

const fetchCategoryFiltersSuccess = (data) => ({
    type: Types.FETCH_CATEGORY_FILTER_SUCCESS,
    data
});

const fetchCategoryFiltersFailure = () => ({
    type: Types.FETCH_CATEGORY_FILTER_FAILURE,
    data: true
});

const updateAllSelectedFilterOptions = (data) => ({
    type: Types.UPDATE_ALL_SELECTED_FILTER_OPTIONS,
    data
});

const updateSelectedCategories = (data) => ({
    type: Types.UPDATE_SELECTED_CATEGORIES,
    data
});

const fetchCategoryFilters = () => (dispatch) => {
    dispatch(fetchCategoryFilterInit());
    ListingService.fetchCategories()
        .then((response) => {
            const { categoryFilterDetails } = response.data;
            const sanitizedCategory = getSanitizedFilterData(categoryFilterDetails);
            dispatch(fetchCategoryFiltersSuccess(sanitizedCategory));
        })
        .catch(() => {
            dispatch(fetchCategoryFiltersFailure());
        });
};

const setMarketingCategory = (data) => ({
    type: Types.SET_MARKETING_CATEGORY,
    data
});

const updateListingType = (listingType = LISTING_TYPE.ALL_CARS) => ({
    type: Types.UPDATE_LISTING_TYPE,
    listingType
});

const setListingFBEvents = (fbEventType) => ({
    type: Types.SET_LISTING_FB_EVENTS,
    fbEventType
});

const clearSelectedFilter = (data) => ({
    type: Types.CLEAR_SELECTED_FILTER,
    data
});

const replaceSelectedFilter = (data) => ({
    type: Types.REPLACE_SELECTED_FILTERS,
    data
});

export {
    fetchFilters,
    updateSSRStatus,
    updateFilterTimestamp,
    updateSelectedOnScreenFilter,
    updatePriceFilterType,
    updateFilterOptionData,
    updateAllFiltersVisibility,
    fetchListingCount,
    updateFilterTabType,
    updateSelectedSort,
    clearAllFilters,
    updateAppliedFilters,
    updateUrlOptions,
    updateAppliedSuggestions,
    updateSelectedSuggestions,
    clearSuggestions,
    updateAllSubFilterOptions,
    updateSortModalVisibility,
    fetchCategoryFilters,
    updateAllSelectedFilterOptions,
    updateSelectedCategories,
    setMarketingCategory,
    updateListingType,
    setListingFBEvents,
    clearSelectedFilter,
    replaceSelectedFilter
};
