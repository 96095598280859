/* eslint-disable max-statements */
/* eslint-disable complexity */
import { fastlyUrl, imageTypes } from "./cdn-image-url";
import { makeOdometerLabelAU } from "./make-odometer-label";
import { makePriceLabelRound } from "./make-price-label";
import { CAR_STATUS, FALSY_VALUES, INVENTORY_CAR_CARD_LABELS, NUMBER } from "../../constants/app-constants";
import { carDetailUrlAu } from "./get-detail-page-url";
import { makeOdometerLabelAUShort } from "./make-odometer-label";
import { modelYearNameGenerator } from "./model-year-name-generator";

export default (content, config = {}, isViewed = false) => {
    const { year, make, model, modelYear, odometerReading, egc: egcPrice, emiDetails = {}, transmissionType, fuelType, variant, shareUrl, mainImage, appointmentId, estimatedPrice, tradeInAccepted, isNewCar = false, booked, listingActive, reserved, price, overviewFeatures = [], odometerShorthand = "", listingImages = [], priceMeta = {}, isPriceDrop = false, cityCode, promotion = "" } = content || {};
    const modelYearName = modelYearNameGenerator(modelYear);
    const carFullName = `${year || ""} ${make || ""} ${model || ""} ${modelYearName}`;
    const kilometerLabel = (odometerShorthand && odometerShorthand !== FALSY_VALUES.INVALID) ? odometerShorthand : `${makeOdometerLabelAUShort(odometerReading / NUMBER.THOUSAND)}`;
    const carName = `${make || ""} ${model || ""}`;
    const carAltTag = `${year} ${make} ${model} ${variant} ${transmissionType}, ${kilometerLabel} ${fuelType} Car`;
    const hasImage = mainImage && mainImage.path && config.imageHost;
    const displayImageType = imageTypes.gsCarCard;
    const imageUrl = hasImage && fastlyUrl(config.imageHost, mainImage.path, displayImageType);
    const bgRemovedImageUrl = (mainImage?.bgRemovedPath && fastlyUrl(config.imageHost, mainImage.bgRemovedPath, displayImageType)) || "";
    const { relativeURL, absoluteURL } = carDetailUrlAu({ year, carName, carId: appointmentId });
    const priceLabel = makePriceLabelRound(egcPrice);
    const defaultSale =  promotion === "On sale";
    const defaultDiscount = makePriceLabelRound(price - egcPrice);
    const [uspFeature = {}] = overviewFeatures || [];
    const sold = booked || !listingActive;
    const { referencePrice = 0 } = priceMeta || {};

    // const inventoryCarCardLabel =
    const carLabel = () => {
        if (booked || !listingActive) {
            return INVENTORY_CAR_CARD_LABELS.SOLD;
        }
        if (isViewed) {
            return INVENTORY_CAR_CARD_LABELS.VIEWED;
        }
        if (reserved) {
            return INVENTORY_CAR_CARD_LABELS.RESERVED;
        }
        if (defaultSale) {
            return INVENTORY_CAR_CARD_LABELS.DISCOUNT;
        }
        if (isPriceDrop) {
            return INVENTORY_CAR_CARD_LABELS.PRICE_DROP;
        }
        if (isNewCar) {
            return INVENTORY_CAR_CARD_LABELS.NEW;
        }
        return null;
    };
    const carStatus = () => {
        if (booked) {
            return CAR_STATUS.SOLD;
        }
        if (reserved) {
            return CAR_STATUS.RESERVED;
        }
        if (!listingActive) {
            return CAR_STATUS.UNPUBLISHED;
        } else {
            return CAR_STATUS.PUBLISHED;
        }
    };
    const imagesArrayPath = listingImages?.map((image) => fastlyUrl(config.imageHost, image?.path, displayImageType));
    return ({
        carFullName,
        egcPrice,
        egcPriceLabel: makePriceLabelRound(egcPrice),
        odometer: makeOdometerLabelAU(odometerReading),
        emiPerWeek: (emiDetails || {}).emiPerWeek,
        transmissionType,
        fuelType,
        variant,
        shareUrl,
        carName,
        carImage: imageUrl,
        kilometerLabel,
        carAbsoluteURL: absoluteURL,
        carRelativeURL: relativeURL,
        priceLabel,
        emiDetails,
        estimatedPrice: makePriceLabelRound(estimatedPrice),
        tradeInAccepted,
        carAltTag,
        carLabel: carLabel(),
        defaultDiscount,
        uspFeature,
        bgRemovedImageUrl,
        defaultSale,
        sold,
        imagesArray: imagesArrayPath,
        cityCode,
        price: makePriceLabelRound(price),
        initialListingPrice: makePriceLabelRound(referencePrice),
        carStatus: carStatus()
    });
};

export const getCarImage = (config, path, imageConfig) => {
    if (!path) {
        return null;
    }
    const imageUrl = fastlyUrl(config.imageHost, path, imageConfig || imageTypes.auCarDetailPreview);
    return imageUrl;
};

