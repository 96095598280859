import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const ComprehensiveCoverage = ({ showKnowMore, onKnowMoreClick, bannerText, bannerImage }) => {
    return (
        <div styleName={"styles.outer"}>
            <img src={bannerImage} />
            <div styleName={"styles.textContainer"}>
                <p styleName={"styles.heading"}>{bannerText}</p>
                {showKnowMore && (
                    <p styleName={"styles.knowMore"} onClick={onKnowMoreClick}>
                        Know more
                    </p>
                )}
            </div>
        </div>
    );
};

ComprehensiveCoverage.propTypes = {
    onKnowMoreClick: PropTypes.func,
    bannerText: PropTypes.string,
    bannerImage: PropTypes.string,
    showKnowMore: PropTypes.bool
};

export default ComprehensiveCoverage;
