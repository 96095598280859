import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmBankTransfer, uploadBankTransferProof, getBankDetails, getPaymentHistory, verifyReservedStatus } from "../checkout-info/actions";
import { getPaymentSummary } from "../my-bookings/actions";
import BankTransfer from "./components";

const mapStateToProps = ({
    carDetails: { content },
    checkout: {paymentPayload, order, paymentHistoryData, bankDetails, tradeInData, egcData, deliveryMode},
    user: {isGuestLogin, firstName, secureToken, isUserZeroDpVariant},
    myBookings: {paymentSummaryData},
    deliveryInfo
}) => ({
    paymentPayload,
    order,
    content,
    isGuestLogin,
    firstName,
    paymentHistoryData,
    secureToken,
    bankDetails,
    egcData,
    paymentSummaryData,
    deliveryInfo,
    isUserZeroDpVariant,
    tradeInData,
    deliveryMode
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    uploadDocumentsConnect: uploadBankTransferProof,
    confirmBankTransferConnect: confirmBankTransfer,
    getBankDetailsConnect: getBankDetails,
    getPaymentHistoryConnect: getPaymentHistory,
    getPaymentSummaryConnect: getPaymentSummary,
    verifyReservedStatusConnect: verifyReservedStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankTransfer);
