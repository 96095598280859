import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_MAKE_MODEL_LIST_REQUEST
    FETCH_MAKE_MODEL_LIST_SUCCESS
    FETCH_MAKE_MODEL_LIST_FAILURE
    UPDATE_MMVY_FLOW_DATA_REQUEST
    UPDATE_REGO_FLOW_DATA_REQUEST
    CREATE_PUBLIC_TRADE_IN_ORDER_REQUEST
    CREATE_PUBLIC_TRADE_IN_ORDER_SUCCESS
    CREATE_PUBLIC_TRADE_IN_ORDER_FAILURE
    FETCH_VARIANTS_REQUEST
    FETCH_VARIANTS_SUCCESS
    FETCH_VARIANTS_FAILURE
    OPEN_REGO_FLOW_MODAL
    OPEN_MMVY_FLOW_MODAL
`,
    {
        prefix: "carValuationCalculator/"
    }
);
