/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useEffect} from "react";
import styles from "./styles.css";
import ArrowIcon from "../../../shared/arrow";
import PropTypes from "prop-types";
import { makeOdometerLabelAU } from "../../../../utils/helpers/make-odometer-label";
import { getCarDetailsPageURL } from "../../../../utils/helpers/get-detail-page-url";
import { NUMBER } from "../../../../constants/app-constants";
import { DELIVERY_TYPES } from "../../../../constants/checkout-constants";
import DriveAwayPriceModal from "../../drive-away-price-modal/component";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";
import usePricing from "../../../../hooks/use-pricing";

const BookingHeaderStrip = ({
    content,
    history,
    egcData = [],
    showEgcPrice,
    deliveryInfo,
    order,
    isAffiliate,
    customPriceHeaderLabel = null
}) => {
    const {
        year,
        make, model,
        variant,
        fuelType,
        odometerReading,
        transmissionType,
        bodyType
    } = content || {};
    const { deliveryInfoError, deliveryDate, pickUpDate } = deliveryInfo || {};
    const carName = `${make} ${model}`;
    const { deliveryMode } = order || {};
    const kilometersDriven = odometerReading && makeOdometerLabelAU(odometerReading);
    const { motorVehicleDuty = 0, transferFee = 0} = egcData[0] || {};
    const isCarAvailable = motorVehicleDuty + transferFee === 0;
    const [showToolTip, setShowToolTip] = useState(false);
    const [showDapModal, setShowDapModal] = useState(false);

    const {totalDap, egc} = usePricing();
    const deliveryOrPickupAvailable = deliveryMode !== DELIVERY_TYPES.PickUp ? !deliveryDate : !pickUpDate;
    const showEgcLabel = (((deliveryOrPickupAvailable || deliveryInfoError) || showEgcPrice) && isCarAvailable);
    let priceObj = showEgcLabel
        ? {value: egc.label, label: "Excl. Govt. Charges" } : {value: totalDap.label, label: "Drive-away price"};
    priceObj = customPriceHeaderLabel || priceObj;

    useEffect(() => {
        if (!showEgcPrice) {
            setShowToolTip(true);
            window.setTimeout(() => {
                setShowToolTip(false);
            }, NUMBER.FOUR_THOUSAND);
        }
    }, [showEgcPrice]);

    const handleModal = () => {
        if (!showEgcPrice) {
            setShowDapModal(!showDapModal);
            trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.DRIVE_AWAY_PRICE_CTA, {eventLabel: !showDapModal ? "click" : "close"});

        }
    };

    const onBackClick = () => {
        const carDetailsURL = getCarDetailsPageURL(content);
        history.push(carDetailsURL);
        // history.goBack();
    };

    return (
        <div styleName={"styles.emiStrip"}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-lg-8"} styleName="styles.emiWrap">
                        <div styleName={"styles.modalNameWrapp"}>
                            {!isAffiliate && <span styleName={"styles.emiBreakup"}  onClick={onBackClick} ><ArrowIcon rotateBy={180}/></span> }
                            <div styleName={"styles.modalName"}>
                                <h2><strong>{year} {carName}</strong> {variant} {transmissionType}</h2>
                                <ul styleName={"styles.modalType"}>
                                    <li>{kilometersDriven}</li>
                                    <li styleName={"styles.listDot"} />
                                    <li>{fuelType}</li>
                                    <li styleName={"styles.listDot"} />
                                    <li>{bodyType}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-4"} styleName={"styles.emiWrap"}>
                        <div styleName={"styles.emiPrice"}>
                            <strong>{priceObj.value}</strong>
                            <p onClick={handleModal} styleName={showEgcPrice ? "" : "styles.underScore"}>{priceObj.label}
                                <div styleName={showToolTip ? "styles.infoTooltip" : "styles.hideInfoTooltip"}>
                                    <p styleName={"styles.informationText"}>Price now includes mandatory State government charges</p>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {showDapModal && <DriveAwayPriceModal onClose={handleModal} priceBreakDown={(egcData[0] || {})}/>}
        </div>
    );
};

BookingHeaderStrip.propTypes = {
    content: PropTypes.object,
    history: PropTypes.object,
    currentDeliveryStep: PropTypes.object,
    egcData: PropTypes.array,
    showEgcPrice: PropTypes.bool,
    currentStep: PropTypes.bool,
    deliveryInfo: PropTypes.object,
    order: PropTypes.object,
    isAffiliate: PropTypes.bool,
    customPriceHeaderLabel: PropTypes.object
};

export default BookingHeaderStrip;
