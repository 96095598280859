import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EnterGuestEmailModal from "./component";
import { getGuestToken, setProfileDetails, setUserEmailViaGuestLogin, setUserMobileViaGuestLogin, updateProfile } from  "../../../../components/au.configuration/actions";
import { setGuestLoginVisible, setLoginSuccessResponse } from  "../../../../components/au.mobile/guest-login/actions";

const mapStateToProps = ({
    user: {secureToken, email, gaId, isLoggedIn, mobile}
}) => ({
    secureToken,
    email,
    gaId,
    isLoggedIn,
    mobile
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setGuestLoginVisibleConnect: setGuestLoginVisible,
    setLoginSuccessResponseConnect: setLoginSuccessResponse,
    getGuestTokenConnect: getGuestToken,
    setProfileDetailsConnect: setProfileDetails,
    updateProfileDetailsConnect: updateProfile,
    setUserEmailViaGuestLoginConnect: setUserEmailViaGuestLogin,
    setUserMobileViaGuestLoginConnect: setUserMobileViaGuestLogin
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EnterGuestEmailModal);
