import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import GRRENICON from "./images/greenicon.svg";
import ArrowIcon from "../../../shared/arrow";
import Button from "../../../shared/button";
// import CHECKBOX from "./images/checkbox.svg";

// import TestDriveLayout from "../layout";
import UIPaymentMode from "../../payment-mode-v2";
import { PAYMENT_STATUS, PAYMENT_TYPE, TEST_DRIVE_BOOKING_ROUTE } from "../../../../constants/checkout-constants";
import { NUMBER } from "../../../../constants/app-constants";
import TransparentButton from "../../../shared/button/transparent-button";
import { getAmountWithSurchargeNew } from "../../../../utils/helpers/split-payment";
import { makePriceLabelRound } from "../../../../utils/helpers/make-price-label";
import { useHistory } from "react-router";
import { getMyBookingCarDetailsURL, getTestDriveCheckoutURL } from "../../../../utils/helpers/get-detail-page-url";
import { testDriveRoutes } from "../../../../constants/au.desktop/test-drive-routes";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS, DESKTOP_EVENT_KEY } from "../../../../tracking/au.desktop-events";
import CustomerCareTeam from "../../../shared/customer-care-team";
import debounce from "../../../../utils/helpers/debounce";
import PaymentAgreeTerms from "../../payment-agree-terms";
import usePricing from "../../../../hooks/use-pricing";

const BREAKUP = {
    egc: "Excluding Government Charges price",
    motorVehicleDuty: "Motor vehicle duty",
    registrationCost: "months registration",
    ctp: "CTP insurance",
    transferFee: "Transfer fee"
};

// eslint-disable-next-line max-statements
const PayDeposit = ({
    content,
    egcData = [],
    // routeData,
    canMakePayment,
    handlePayment,
    mobile,
    orderId
}) => {

    const history = useHistory();
    const [showBreakUp, setShowBreakUp] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [paymentModeDetails, setPaymentModeDetails] = useState({});
    const [isWallet, setWallet] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const {totalDap} = usePricing();

    const priceBreakup = (egcData && egcData[NUMBER.ZERO]) || {};

    const handlePriceBreakup = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, {eventLabel: "payment_mode_selected", eventAction: "pay_later"});
        setShowBreakUp(!showBreakUp);
    };

    // useEffect(() => {
    //     setShowBreakUp(!showBreakUp);
    // }, [showBreakUp]);

    const handleAgreeTerms = (e) => {
        setAgreedToTerms(e.target.checked);
    };

    const handlePaymentMode = (payModeDetails) => {
        const {paymentMethodType} = payModeDetails || {};
        if (payModeDetails.paymentMethodType === PAYMENT_TYPE.GOOGLE_PAY.value || payModeDetails.paymentMethodType === PAYMENT_TYPE.APPLE_PAY.value) {
            setWallet(true);
        } else {
            setWallet(false);
        }
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, {eventLabel: `payment_mode_selected_${paymentMethodType}`, eventAction: "pay_deposit"});
        setPaymentModeDetails({...paymentModeDetails, ...payModeDetails});
    };

    const handleCheckout = () => {
        setisLoading(true);
        const payMethod = paymentModeDetails.paymentMethodType === "" ? "TRADEIN_VALUE_MORE_THAN_VEHICLE_PRICE" : paymentModeDetails.paymentMethodType;

        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.CHECKOUT_PAGE, {eventLabel: `pay_amount`, eventAction: "pay_deposit"});

        const {absoluteURL: SUCCESS_URL} =  getMyBookingCarDetailsURL(content.appointmentId, TEST_DRIVE_BOOKING_ROUTE, {status: PAYMENT_STATUS.SUCCESS});
        const {absoluteURL: CANCEL_URL} =  getTestDriveCheckoutURL(content, testDriveRoutes.paymentSummary.route, {status: PAYMENT_STATUS.CANCEL});
        const {absoluteURL: FAILURE_URL} =  getTestDriveCheckoutURL(content, testDriveRoutes.paymentSummary.route, {status: PAYMENT_STATUS.RETRY, type: payMethod, gateway: paymentModeDetails.paymentGateway });
        handlePayment(
            {
                paymentProps: {
                    paymentMethodType: (payMethod === PAYMENT_TYPE.GOOGLE_PAY.value || payMethod === PAYMENT_TYPE.APPLE_PAY.value)
                        ? PAYMENT_TYPE.CARD.value : payMethod,
                    paymentGateway: paymentModeDetails.paymentGateway,
                    type: "checkoutSummary",
                    cardType: paymentModeDetails.cardType,
                    isWallet,
                    walletName: payMethod
                },
                callbacks: {
                    prePayment: () => setisLoading(true),
                    paymentInitiated: () => {
                        // trackMobileCustomEventsAU(checkoutEventAction, {eventLabel: "option selected checkout"});
                        // trackCheckoutStep(paymentModeDetails.paymentMethodType);
                        setisLoading(false);
                    },
                    paymentFailed: () => setisLoading(false)
                },
                redirectionUrls: {
                    SUCCESS_URL,
                    CANCEL_URL,
                    FAILURE_URL
                }
            }
        );
    };

    const handleBack = () => {
        trackDesktopCustomEventsAU(DESKTOP_EVENT_KEY.CHECKOUT_PAGE_BACK, {eventLabel: "order_summary"});
        history.goBack();
    };

    const {amountWithSurchargeLabel} = getAmountWithSurchargeNew(NUMBER.FIVE_HUNDRED, paymentModeDetails.paymentMethodType, paymentModeDetails.cardType) || {};

    return (
        // <TestDriveLayout routeData={routeData}>
        <div className="row">
            <div className="col-lg-7">
                <div styleName={"styles.outer"}>
                    <div styleName={"styles.first-section"}>
                        <h2 styleName={"styles.heading"}>Pay deposit</h2>
                        <h4 styleName={"styles.subheading"}>The $500 test-drive deposit;</h4>
                        <div styleName={"styles.icon"}>
                            <img src={GRRENICON} alt="" />
                            <p styleName={"styles.text"}>Reserves the car for a test drive</p>
                        </div>
                        <div styleName={"styles.icon"}>
                            <img src={GRRENICON} alt="" />
                            <p styleName={"styles.text"}>Is 100% refundable</p>
                        </div>
                        <div styleName={"styles.icon"}>
                            <img src={GRRENICON} alt="" />
                            <p styleName={"styles.text"}>Offsets car price if you purchase </p>
                        </div>
                        <CustomerCareTeam mobile={mobile}/>
                        {/* <p styleName={"styles.terms"}>Test drive terms and conditions</p> */}
                    </div>
                    <div styleName={"styles.paymentsection"}>
                        <UIPaymentMode
                            handlePaymentMode={handlePaymentMode}
                            agreedToTerms={agreedToTerms}
                            {...paymentModeDetails}
                            canMakePayment={canMakePayment}
                            content={content}
                            orderId={orderId}
                            showHeader={false}
                            isReservationVariant={true}
                            amountBeingPaid={NUMBER.FIVE_HUNDRED}
                        />
                    </div>

                    <div styleName={"styles.termContainer"}>
                        <PaymentAgreeTerms onClickCheckBox={handleAgreeTerms} />
                    </div>

                    <div styleName={"styles.button-section"}>
                        <div styleName={"styles.transparentButton"}>
                            <TransparentButton text="Back" onClick={handleBack} />
                        </div>
                        <div styleName={"styles.buttonWrapper"}>
                            <Button
                                text={`PAY ${amountWithSurchargeLabel}`}
                                disabled={!paymentModeDetails.isValid || isLoading}
                                isLoading={isLoading}
                                onClick={debounce(handleCheckout, NUMBER.FIVE_HUNDRED)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5">
                <div styleName={"styles.sec-section"}>
                    <h4 styleName={"styles.today"}>Payable today <span>$500</span></h4>
                    <hr styleName={"styles.border"} />
                </div>
                <div styleName={"styles.third-section"}>
                    <h4 styleName={"styles.later"}>Payable later
                        <span>
                            <ArrowIcon black="BlackArrow"
                                onClick={handlePriceBreakup}
                                rotateBy={showBreakUp ? NUMBER.ZERO : NUMBER.ONE_HUNDRED_EIGHTY }/>
                        </span>
                    </h4>
                    <p styleName={"styles.para"}>If you decide to buy the car</p>
                    {!showBreakUp &&
                              <React.Fragment>
                                  <ul>
                                      {Object.keys(BREAKUP).map(item => priceBreakup[item] &&
                                            <li key={item}>
                                                {item === "registrationCost" ? `${priceBreakup.registrationTenure} ${BREAKUP[item]}` :  BREAKUP[item]}
                                                <span>{makePriceLabelRound(priceBreakup[item])}</span>
                                            </li>
                                      )}
                                      <li>Test drive deposit <span>-$500</span></li>
                                  </ul>
                                  <div styleName={"styles.amount-remaing"}>
                                      <h4 styleName={"styles.later"}>Amount remaining <span>{makePriceLabelRound(totalDap.value - NUMBER.FIVE_HUNDRED)}</span></h4>
                                  </div>
                              </React.Fragment>
                    }
                </div>
            </div>
        </div>
        // </TestDriveLayout>
    );
};

PayDeposit.propTypes = {
    egcData: PropTypes.array,
    routeData: PropTypes.object,
    canMakePayment: PropTypes.object,
    handlePayment: PropTypes.func,
    content: PropTypes.object,
    mobile: PropTypes.number,
    orderId: PropTypes.string
};

export default PayDeposit;
