/* eslint-disable max-statements */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import CopyIcon from "./images/content-copy.svg";
import InfoIcon from "./images/info-outline.svg";
import UploadIcon from "./images/upload.svg";
import { BANK_TRANSFER, HELPLINE_NUMBER_AU, LOAN_PROVIDER, NUMBER, ORDER_TYPE } from "../../../constants/app-constants";
import copyToClipboard from "../../../utils/helpers/copy-to-clipboard";
import TransferSuccessPhotoModal from "../transfer-success-photo-modal/component";
import UploadedDocumentCard from "../uploaded-document-card/component";
import { useHistory } from "react-router";
import TransparentButton from "../../shared/button/transparent-button";
import Button from "../../shared/button";
import FileUpload from "../../shared/file-upload/component";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { getGuestLoginPostPayURL, getMyBookingCarDetailsURL } from "../../../utils/helpers/get-detail-page-url";
import { tasksRoutes } from "../../../constants/au.desktop/tasks-routes";
import { ORDER_STATUS, PAYMENT_MODES, PAYMENT_STATUS, TEST_DRIVE_BOOKING_ROUTE } from "../../../constants/checkout-constants";
import { postPaymentRoutes } from "../../../constants/au.desktop/post-payment-routes";
import TransparentTooltip from "../../shared/transparent-tooltip";
// import UploadedDocumentCard from "../uploaded-document-card/component";
import Executive from "./images/executive.png";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";

const getSuccessText = ({financeProvider, financeOpted, zeroDp}) => {
    if (zeroDp) {
        return "ZERO_DP";
    } else if (financeProvider === LOAN_PROVIDER.CARS24 && financeOpted) {
        return "FINANCE";
    }
    return "BT";
};

const BSBBankTransfer = ({
    order,
    isGuestLogin,
    secureToken,
    bankDetails,
    getBankDetailsConnect,
    uploadDocumentsConnect,
    confirmBankTransferConnect,
    verifyReservedStatusConnect
}) => {
    const history = useHistory();
    const { bookingAmount, financeProvider, bookingConfirmDate, appointmentId, orderId, financeOpted, zeroDp, orderType, status } = order || {};
    const [showUploadModal, setShowUploadModal] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(null);
    const [openInfoModal, setOpenInfoModal] = useState();
    const [showTooltip, setShowTooltip] = useState(false);
    // const {payableAmount = 0} = paymentPayload || {};
    const query = { status: PAYMENT_STATUS.SUCCESS, jtype: getSuccessText({financeProvider, financeOpted, zeroDp}) };
    const tooltipTimeoutRef = useRef();
    const [fileUploaderStatus, setFileUploaderStatus] = useState({});

    const amount = makePriceLabelRound(bookingAmount);

    const bankDetailsData = { ...bankDetails, bookingAmount: amount };

    useEffect(() => {
        if (showTooltip) {
            tooltipTimeoutRef.current = window.setTimeout(() => {
                setShowTooltip(false);
            // eslint-disable-next-line no-magic-numbers
            }, 3000);
        }
        return () => tooltipTimeoutRef.current && window.clearTimeout(tooltipTimeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTooltip]);

    useEffect(() => {
        if (secureToken && orderId) {
            getBankDetailsConnect(secureToken, orderId).then(() => {
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureToken, orderId]);

    const trackEvent = (eventLabel) => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.DIRECT_BANK_TRANSFER, {eventLabel});
    };

    const handleModal = () => {
        setShowUploadModal(!showUploadModal);
    };

    const handleSelectedImage = () => {
        trackEvent("cross");
        setSelectedImage(null);
    };

    const onFileSelectCallback = ({ hasError, error }) => {
        setFileUploaderStatus({
            hasError,
            error
        });
    };

    const handleUpload = async (data, file, id) => {
        setSelectedImage({ data, fileName: file.name, size: file.size });
        if (file) {
            setIsLoading(true);
            try {
                const response = await uploadDocumentsConnect(data);
                setSelectedImage((current => ({...current, imageSource: response.filePath })));
                trackEvent(`Photo uploaded ${id}`);
            } catch (error) {
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }
        handleModal();
    };

    const handleInfoModal = () => {
        if (!openInfoModal) {
            trackEvent("info_icon");
        }
        setOpenInfoModal(!openInfoModal);
    };

    // eslint-disable-next-line max-params
    const handleCopyClipboard = (key, eventLabel) => {
        copyToClipboard(bankDetailsData[key]);
        setShowTooltip(true);
        trackEvent(eventLabel);
    };

    const handleGoBack = () => {
        trackEvent("back");
        history.goBack();
    };

    const getSuccessUrl = () => {
        if (ORDER_TYPE.TEST_DRIVE === orderType) {
            return  getMyBookingCarDetailsURL(appointmentId, TEST_DRIVE_BOOKING_ROUTE, {status: PAYMENT_STATUS.SUCCESS});
        }
        if (isGuestLogin) {
            return getGuestLoginPostPayURL(appointmentId, null, query);
        } else  if (financeProvider === LOAN_PROVIDER.BYOF || financeProvider === LOAN_PROVIDER.NONE && !bookingConfirmDate) {
            return getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.addOns.route, query, EXPERIMENT_TYPE.VARIANT_B);
        } else {
            return getMyBookingCarDetailsURL(appointmentId, tasksRoutes.addOns.route, query);
        }
    };

    // const getSuccessUrl = () => {
    //     if (isGuestLogin) {
    //         return getGuestLoginPostPayURL(appointmentId, null, query);
    //     } else  if (financeProvider === LOAN_PROVIDER.BYOF || financeProvider === LOAN_PROVIDER.NONE && !bookingConfirmDate) {
    //         return getMyBookingCarDetailsURL(appointmentId, postPaymentRoutes.driverLicence.route,
    //             query,
    //             EXPERIMENT_TYPE.VARIANT_B);
    //     } else {
    //         return getMyBookingCarDetailsURL(appointmentId, tasksRoutes.viewTask.route, query);
    //     }
    // };

    const handleSubmit = async () => {
        setIsConfirmed(true);
        const {relativeURL} = getSuccessUrl();
        try {
            const reservedStatusResponse = await verifyReservedStatusConnect({ prePayment: false, paymentMethod: PAYMENT_MODES.BANK_TRANSFER });
            if (reservedStatusResponse?.data?.code === NUMBER.TWO_HUNDRED) {
                await confirmBankTransferConnect({paymentProof: selectedImage.imageSource });
                trackEvent("Submit");
                window.location.href = relativeURL;
            }
        } catch (error) {
            await confirmBankTransferConnect({paymentProof: selectedImage.imageSource });
            window.location.href = relativeURL;
            setIsConfirmed(false);
        }
    };

    const handleUploadClick = () => {
        trackEvent("Upload photo");
        // handleModal();
    };

    return (
        <div className="row">
            <div className="col-7">
                <div styleName={"styles.padding"}>
                    {/* <div styleName={"styles.alertmsgWrap"}>
                        <p styleName={"styles.alertMsg"}>If your amount was debited, click on I’ve transferred the funds so that we can check again</p>
                    </div> */}
                    <p styleName={"styles.heading"}>Transfer <span>{amount}</span> to below account to reserve car</p>
                    <div styleName={"styles.whiteBoxOuter"}>
                        <div styleName={"styles.accountHeader"}>
                            <p styleName={"styles.header"}>Account details</p>
                            <p styleName={"styles.tapCopy"}>Tap <img src={CopyIcon} /> to Copy</p>
                        </div>
                        {BANK_TRANSFER.map(item =>
                            (<div styleName={"styles.rowWrap"} key={item.value}>
                                <p styleName={"styles.text"}>{item.label}: <span>{bankDetailsData[item.key]}</span></p>
                                <img src={CopyIcon}  onClick={() => handleCopyClipboard(item.key, item.eventLabel, item.amplitudeKey, item.amplitudeEvtProperty)} />
                            </div>
                            )
                        )}
                    </div>
                    <div styleName={`styles.whiteBoxOuter ${fileUploaderStatus.hasError ? "styles.hasError" : ""}`}>
                        <p styleName={"styles.header"}>Upload transfer success photo<img src={InfoIcon} onClick={handleInfoModal}/></p>
                        {selectedImage && selectedImage.fileName ?
                            <UploadedDocumentCard
                                onRemoveDocument={handleSelectedImage}
                                {...selectedImage}
                                isLoading={isLoading}
                            /> :
                            <div styleName={"styles.rowWrapBottom"}>
                                <div styleName={"styles.uploadDetail"}>
                                    <p styleName={"styles.upload"}>Upload photo </p>
                                    <p styleName={"styles.fileType"}>PDF, JPEG, JPG, PNG <span>Max size: 5MB</span></p>
                                </div>
                                <FileUpload onUpload={handleUpload} onFileSelectCallback={onFileSelectCallback} onClick={handleUploadClick}  fileType="application/pdf,image/*" id="upload">
                                    <img src={UploadIcon} />
                                </FileUpload>
                            </div>
                        }
                    </div>
                    {fileUploaderStatus.hasError && <div styleName={"styles.error"}><p>{fileUploaderStatus.error}</p></div> }
                </div>
                <div styleName={"styles.pr"}>
                    {/* <p styleName={"styles.alertCarReserved"}>We’ll verify the payment on next screen </p> */}
                    <TransparentTooltip text={"Text copied"} isVisible={showTooltip} centerX centerY />
                </div>

                <div styleName={"styles.buttonWrapper"}>
                    <div styleName={"styles.transparentButton"}>
                        <TransparentButton text={"BACK"}  onClick={handleGoBack}/>
                    </div>
                    <div styleName={"styles.button"}>
                        <Button text={`SUBMIT PHOTO${status === ORDER_STATUS.CREATED ? " & RESERVE" : ""}`}  onClick={handleSubmit} isLoading={isConfirmed} disabled={!(selectedImage && selectedImage.imageSource)} />
                    </div>
                </div>
            </div>
            <div className="col-5">
                <div className="media" styleName={"styles.payPartialWrapper"}>
                    <div styleName={"styles.infoWrapper"}>
                        <img src={Executive} />
                    </div>
                    <div className="media-body">
                        <p styleName={"styles.payPartialTxt"}>If you need additional assistance while transferring the funds, we are always here for help <span styleName={"styles.contactUs"}>{HELPLINE_NUMBER_AU.label}</span></p>
                    </div>
                </div>
            </div>

            {openInfoModal &&  <TransferSuccessPhotoModal onClose={handleInfoModal}/>}
        </div>
    );
};

BSBBankTransfer.propTypes = {
    paymentPayload: PropTypes.object,
    order: PropTypes.object,
    uploadDocumentsConnect: PropTypes.func,
    confirmBankTransferConnect: PropTypes.func,
    getOrderDetailConnect: PropTypes.func,
    content: PropTypes.object,
    isGuestLogin: PropTypes.bool,
    secureToken: PropTypes.string,
    getBankDetailsConnect: PropTypes.func,
    bankDetails: PropTypes.object,
    verifyReservedStatusConnect: PropTypes.func
};

export default BSBBankTransfer;
