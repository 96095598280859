/* eslint-disable complexity */
import React, { useState } from "react";
import styles from "./styles.css";
import ArrowIcon from "../../shared/arrow";
import { NUMBER } from "../../../constants/app-constants";
// import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import PropTypes from "prop-types";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
// import { ORDER_STATUS } from "../../../constants/checkout-constants";
import { postOrderBookingConfirmed } from "../../../utils/helpers/post-order-placed-confirmed";

const ComplimentaryBenefits = ({
    splitPaymentOpted,
    status,
    financeOpted
}) => {
    const [ isOpen, setIsOpen ] = useState(true);
    // const totalComplentaryBenifts = (Array.isArray(complemetaryBenefits) && complemetaryBenefits || []).reduce((acc, value) => acc + value.amount || 0, 0);
    const trackEvent = () => {
        if (splitPaymentOpted) {
            let action = "SPLIT_ORDER_SUMMARY";
            const eventLabel = isOpen ? "complimentary_benefits_open" : "complimentary_benefits_close";
            if (postOrderBookingConfirmed(status)) {
                action = "SPLIT_ORDER_SUMMARY_POST_PAYMENT";
            }
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS[action], {eventLabel });
        } else {
            trackMobileCustomEventsAU(
                financeOpted ? AU_MOBILE_EVENTS.DRIVA_ORDER_SUMMARY : AU_MOBILE_EVENTS.CHECKOUT_ORDER_SUMMARY,
                { eventLabel: `options ${isOpen ? "complimentary_benefits_close" : "complimentary_benefits_open"}` }
            );
        }
    };

    const toggleOpen = () => {
        trackEvent();
        setIsOpen(!isOpen);
    };
    return (
        <React.Fragment>
            <div styleName={"styles.complementryBenefits"} onClick={toggleOpen}>
                <p styleName={"styles.priceBreakup styles.noBorder"}>
                    <strong styleName={"styles.complimentaryText"}>Complimentary CARS24 Benefits
                        <span styleName={"styles.arrowIcon"}>
                            <ArrowIcon green={"GreenArrow"} rotateBy={!isOpen ? NUMBER.ONE_HUNDRED_EIGHTY : 0}/>
                        </span>
                    </strong>
                    {/* <strong styleName={"styles.complimentaryPrice"}> {totalComplentaryBenifts === 0 ? "FREE" : makePriceLabelRound(totalComplentaryBenifts)}</strong> */}
                    {/* <strong styleName={"styles.complimentaryPrice"}>$2,485</strong> */}
                </p>
                <small styleName={"styles.message"}>Included with your car</small>
            </div>
            {isOpen && <div>
                <React.Fragment>
                    <p styleName={"styles.priceBreakup"}>
                        <span styleName={"styles.priceLabel"}>3 months warranty</span>
                        <strong styleName={"styles.price"}>FREE</strong>
                    </p>
                    <p styleName={"styles.priceBreakup"}>
                        <span styleName={"styles.priceLabel"}>3 months roadside assistance</span>
                        <strong styleName={"styles.price"}>FREE</strong>
                    </p>
                </React.Fragment>

            </div>}
        </React.Fragment>
    );
};

ComplimentaryBenefits.propTypes = {
    complemetaryBenefits: PropTypes.array,
    shippingCharge: PropTypes.array,
    financeOpted: PropTypes.bool,
    splitPaymentOpted: PropTypes.bool,
    showPickUpExperiment: PropTypes.object,
    status: PropTypes.string,
    deliveryData: PropTypes.object,
    complimentaryBenefits: PropTypes.array,
    deliveryMode: PropTypes.string,
    checkoutText: PropTypes.object
};
export default ComplimentaryBenefits;
