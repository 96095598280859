// import loadable from "@loadable/component";

// const VerifySignContract =  loadable(() => import("../../components/au.desktop/post-payment-journey/verify-sign-contract"));
// const VeriyUserLicence = loadable(() => import("../../components/au.desktop/post-payment-journey/verify-user-licence"));

import PostBcAddons from "../../components/au.desktop/post-payment-journey/add-ons";
import VerifySignContract from  "../../components/au.desktop/post-payment-journey/verify-sign-contract";
import VeriyUserLicence from "../../components/au.desktop/post-payment-journey/verify-user-licence";

export const postPaymentRoutes = {
    addOns: {
        key: "addOns",
        name: "Add Ons",
        route: "add-ons",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: PostBcAddons,
        hideCurrentPill: false,
        useRoute: null,
        type: "postPayment",
        approx: 1,
        sidebarNavigation: {
            heading: "Add Ons",
            subHeading: "",
            navKey: "addOns"
        },
        stepNo: 1
    },
    driverLicence: {
        key: "driverLicence",
        name: "driverLicence",
        route: "user-id",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: VeriyUserLicence,
        hideCurrentPill: false,
        useRoute: null,
        type: "postPayment",
        approx: 1,
        sidebarNavigation: {
            heading: "Verify your ID",
            subHeading: "",
            navKey: "driverLicence"
        },
        stepNo: 2
    },
    signContract: {
        key: "signContract",
        name: "signContract",
        route: "sign-contract",
        gtmPageName: "",
        gtmPillClickLabel: "",
        defaultNext: "",
        defaultBack: "",
        component: VerifySignContract,
        hideCurrentPill: false,
        useRoute: null,
        type: "postPayment",
        approx: 1,
        sidebarNavigation: {
            heading: "Sign contract",
            subHeading: "",
            navKey: "signContract"
        },
        stepNo: 3
    }

};

export const postPaymentRoutesArray = Object.values(postPaymentRoutes);

export const routesToRedirecttasks = postPaymentRoutesArray
    .filter((routeData) => routeData.type === "tasks")
    .slice(1)
    .map((routeData) => routeData.route);
