import React, { useState } from "react";
import styles from "./styles.css";
import PlatinumCover from "./images/platinum-cover.svg";
import dropDown from "./images/drop-down.svg";
import PropTypes from "prop-types";
import Selected from "./images/selected.svg";
import checkIcon from "./images/check.svg";
import PlatinumCoverExpandedSection from "../platinum-cover-expanded-section";

const PlatinumCoverBundleSection = ({ handleWhatsCoveredModal, isExpanded, setExpandedSection, platinumCover, navigateToNextScreen, carDescription }) => {
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const collapsedSelection = selectedProductIndex !== null && !isExpanded;

    const handleExpandedSection = () => {
        if (isExpanded) {
            setExpandedSection(null);
        } else {
            setExpandedSection("bundle");
        }
    };

    const handleAddBundle = () => {
        navigateToNextScreen();
    };

    return (
        <div styleName={`styles.outer ${isExpanded ? "styles.sectionActive" : ""}`}>
            <div styleName={"styles.topSection"}>
                <div className="media" styleName={"styles.mediaContainer"}>
                    <img src={!collapsedSelection ? PlatinumCover : Selected} alt="CARS24 Platinum cover" />
                    <div className="media-body">
                        <p styleName={"styles.heading"}>CARS24 Platinum cover</p>
                        <p styleName={"styles.listWrapper"}><img src={checkIcon} alt="Check list" />Extended warranty</p>
                        <p styleName={"styles.listWrapper"}><img src={checkIcon} alt="Check list" />Car servicing by expert mechanics</p>
                        <p styleName={"styles.listWrapper"}><img src={checkIcon} alt="Check list" />Free Roadside Assistance</p>
                        <p styleName={"styles.seeWhat"} onClick={handleWhatsCoveredModal}>See what’s covered</p>
                        {/* <p styleName={"styles.subHeading"}>Warranty extension starts from Jun'24. CARS24 servicing keeps warranty valid.</p> */}
                    </div>
                </div>
                {isExpanded && <p styleName={"styles.warrantyText"}>{carDescription}</p>}
                <img src={dropDown} alt="Accordion image" style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }} onClick={handleExpandedSection} />
            </div>
            <PlatinumCoverExpandedSection
                handleAddBundle={handleAddBundle}
                isExpanded={isExpanded}
                productList={platinumCover}
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={setSelectedProductIndex}
                type="bundle"
            />
        </div>
    );
};

PlatinumCoverBundleSection.propTypes = {
    handleWhatsCoveredModal: PropTypes.func,
    platinumCover: PropTypes.object,
    isExpanded: PropTypes.bool,
    setExpandedSection: PropTypes.func,
    navigateToNextScreen: PropTypes.func,
    carDescription: PropTypes.string
};

export default PlatinumCoverBundleSection;
