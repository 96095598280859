import { createTypes } from "reduxsauce";

export default createTypes(
    `
    SET_NAME
    SET_PROFILE_NAME
    SET_MOBILE
    SET_PROFILE_MOBILE
    SET_EMAIL
    SET_AFFILIATE_SOURCE
    GET_PROFILE_DETAILS_SUCCESS
    GET_PROFILE_DETAILS_FAILURE
    MARK_UNAUTHORIZED
    CHECK_AUTH
    SET_FIRST_LOAD
    UPDATE_LEAD_TYPE
    SET_LOGIN_TYPE

    SET_SOCKET_ID
    TRUECALLER_PROMPT_COUNT
    UPDATE_WEBVIEW_STATUS

    SET_GA_ID
    SET_SHOW_ALLOW_TRACKING
    UPDATE_CURRENT_PAGE_TYPE

    UPDATE_PROFILE
    UPDATE_PROFILE_SUCCESS
    UPDATE_PROFILE_FAILURE

    SET_LAST_VERIFIED_MOBILE

    FETCH_PINCODE_CONFIG_SUCCESS
    FETCH_PINCODE_CONFIG_FAILURE

    LOGOUT_SUCCESS
    LOGOUT_FAILURE

    VERIFY_GOOGLE_SIGNIN_REQUEST
    VERIFY_GOOGLE_SIGNIN_SUCCESS
    VERIFY_GOOGLE_SIGNIN_FAILURE

    SET_SECURE_TOKEN
    OPEN_LOGIN_POPUP
    CLOSE_LOGIN_POPUP
    
    SET_SHOW_SPLIT_PAYMENT_MODAL

    SEO_HEADER_MENU
    SEO_HEADER_MENU_SUCCESS
    SEO_HEADER_MENU_FAILURE

    SET_CURRENT_LOGO_URL

    GET_ZERO_DP_VARIANT_INIT
    GET_ZERO_DP_VARIANT_SUCCESS
    GET_ZERO_DP_VARIANT_FAILURE
    SET_INITIAL_ZERO_DP_OPTIMIIZE_VARIANT
    SET_PROFILE_DETAILS
    HIDE_SITE_HEADER

    GET_LOCATION_INIT
    GET_LOCATION_SUCCESS
    GET_LOCATION_FAILURE

    SET_IS_CT_SUB
    CLOSE_CLP_SALE_NUDGE
    CLP_SALE_ANIMATION_SEEN

    SET_CURRENT_BROWSER
    SET_IS_SSR

    UPDATE_RECENTLY_VIEWED_CARS

    SET_TRADE_IN_INTEREST
    UPDATE_IS_FIRST_USER_SESSION
    SET_SETTING_GUEST_LOGIN_DATA
    SET_IS_VALUECHECK_LOGIN_DONE
    SET_USER_EMAIL_VIA_GUEST_LOGIN
    SET_USER_MOBILE_VIA_GUEST_LOGIN
`,
    {
        prefix: "configuration/"
    }
);
