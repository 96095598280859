import React, { useState } from "react";
import PropTypes from "prop-types";
const getActiveState = (index, collapseAccordion, defaultActiveIndex) => {
    if (Array.isArray(defaultActiveIndex)) {
        return !(defaultActiveIndex.indexOf(index) > -1);
    }
    if (defaultActiveIndex === index) {
        return false;
    }
    return collapseAccordion;

};

const Accordion = ({ children, totalItems = 1, collapseAccordion = false, toggleAll = false, defaultActiveIndex = -1 }) => {
    const itemsArray = Array(...new Array(totalItems)).map((_, index) => getActiveState(index, collapseAccordion, defaultActiveIndex));
    const [currentVisibleStates, setCurrentVisibleStates] = useState(itemsArray);

    const onAccordionClickHandler = ({ index }) => {
        const currentState = !toggleAll ? [...itemsArray] : [...currentVisibleStates];
        currentState[index] = !currentVisibleStates[index];
        setCurrentVisibleStates(currentState);
    };

    return (
        <ul>
            {children({
                onClick: onAccordionClickHandler,
                currentVisibleStates
            })}
        </ul>
    );
};

Accordion.propTypes = {
    children: PropTypes.func,
    totalItems: PropTypes.number,
    toggleAll: PropTypes.bool,
    collapseAccordion: PropTypes.bool,
    defaultActiveIndex: PropTypes.number
};

const Heading = ({ children, headingRef }) => {
    return <React.Fragment>{React.cloneElement(children, { ref: headingRef })}</React.Fragment>;
};
Heading.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    headingRef: PropTypes.object
};

const Body = ({ children }) => {
    return <React.Fragment>{children}</React.Fragment>;
};
Body.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
const Content = ({ children }) => {
    return <li>{children}</li>;
};
Content.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

Accordion.Content = Content;
Accordion.Heading = Heading;
Accordion.Body = Body;

export default Accordion;
